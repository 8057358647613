<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医疗服务</el-breadcrumb-item>
      <el-breadcrumb-item>医生管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin-bottom:10px">
      <!-- :xs="8" :sm="6" :md="4" :lg="3" :xl="1" -->
      <div style="margin-bottom:20px;font-weight:bold">查询条件</div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div style="display:flex;align-items: center;">
            <div style='width:120px;font-weight:bold'>医院名称:</div>
            <el-input placeholder="请输入" v-model="hospital_name" style="width: 260px"></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="display:flex;align-items: center;">
            <div style='width:120px;font-weight:bold'>医生名称:</div>
            <el-input placeholder="请输入" v-model="docter_name" style="width: 260px"></el-input>
          </div>
        </el-col>
        <el-col :span="12">
          <el-button class='reset_btn' @click="reset">重置</el-button>
          <el-button class='search_btn' @click="look_out">查询</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns">
        <el-button type="primary" icon="el-icon-plus" class="btn_1" @click="add">添加</el-button>
        <el-button type="primary" icon="el-icon-delete" class="btn_2" @click='delete_info'>删除</el-button>
      </div>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- table表格区域 -->
      <el-table v-loading="loading" ref="multipleTable" :data="Info" tooltip-effect="dark" style="width: 100%"
        @selection-change="chooseType">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="医生名称" prop="name">
          <!-- <template #default="scope">{{ scope.row.date }}</template> -->
        </el-table-column>
        <el-table-column label="医生头像" prop="headPortrait">
          <template slot-scope="scope">
            <!-- <el-image :src="scope.row.avatarUrl" style="height:70px;width:70px;border-radius:50%"></el-image> -->
            <img :src="scope.row.headPicture" style="height:80px;width:80px;border-radius:50%" />
          </template>
        </el-table-column>
        <el-table-column label="医生职称" prop="jobTitle">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="watchDetail(scope.row.id)" size="mini">查看详情</el-button>
            <!-- <el-button type="warning" @click="yuyue(scope.row.id)" size="mini">预约</el-button> -->
            <!-- <el-button v-if="!scope.row.phone" type="info" icon="el-icon-edit"  @click="acount(scope.row)">添加账号</el-button> -->
            <!-- <el-button v-else type="info" icon="el-icon-edit"  @click="acount(scope.row)">添加账号</el-button> -->
            <!-- <el-button :type="scope.row.phone ? 'success' : 'info'"   @click="acount(scope.row)">{{scope.row.phone ? '查看账号' : '添加账号'}}</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <!-- 弹出层 -->
    <el-dialog :visible.sync="isYuyue" width="70%">
      <span>这是预约内容区域</span>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hospital_name: '',
        docter_name: '',
        multipleSelection: [],
        dialogVisible: false,
        watchAccount: false,
        currentPage: 1, //当前页
        addUser: false,
        introduce: '',
        detailAddress: '',
        doctor_introduce: '',
        beGoodat: '',
        value: '',
        // form表单
        Info_1: {
          hospital_name: ''
        },
        hospital_type: [
          {
            value: 'lierg',
            label: '主任医师',
          },
          {
            value: 'ads',
            label: '副主任医师',
          },
          {
            value: 'csdas',
            label: '院长',
          }
        ],
        Info: [

        ],
        page: 1,
        limit: 10,
        total: 0,
        centerDialogVisible: false,
        acount_Info: {},
        phone: '',
        nickName: '',
        doctorId: '',
        loading: false,
        isYuyue: false
      }
    },
    created() {
      this.doctorList()
    },
    methods: {
      reset() {
        // this.hospital_name = '';
        // this.docter_name = ''
        // this.doctorList()
        this.$router.push({
          path: '/Chargemanagement',

        })
      },
      watchDetail(id) {
        console.log(id)
        this.$router.push({
          path: '/doctorManage/detail',
          query: {
            id: id
          }
        })
      },
      chooseType(val) {
        // console.log(val)
        this.multipleSelection = val
        console.log(this.multipleSelection)
      },
      // 删除选中的当前项目
      delete_info() {
        if (this.multipleSelection.length > 0) {
          let arr = this.multipleSelection.map(item => {
            return item.id
          })
          console.log(arr)
          this.delList(arr)
        } else {
          this.$message.error('当前无选中选项')
        }
      },
      // 删除当前所选项
      delList(id) {
        let ids = id
        this.$http.get(`/doctor/deleteById/?ids=` + ids).then(res => {
          console.log(res)
          if (res.data.code === 200) {
            this.$message.success('列表已删除')
            this.doctorList()
          } else {
            this.$message.error('删除失败，请重试')
          }
        })
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize
        console.log(this.limit)
        this.doctorList()
      },
      handleCurrentChange(newPage) {
        this.page = newPage
        console.log(this.page)
        this.doctorList()
      },
      add() {
        // this.addUser = true
        this.$router.push('/doctorManage/addUser')
      },
      chooseAddress(e) {
        console.log(e, this.detailAddress)
      },
      chooseHospital_type(value) {
        console.log(value)
      },
      doctorList() {
        this.loading = true
        this.$http.post('/doctor/selectDoctor', {
          page: this.page,
          limit: this.limit
        }).then(res => {
          console.log(res)
          // for(let i=0;i<res.data.data.length;i++) {
          //     switch (res.data.data[i].positions) {
          //         case "0":
          //             res.data.data[i].positions = "主任医师"
          //             break;
          //         case "1":
          //             res.data.data[i].positions = "副主任医师"
          //         default:
          //             res.data.data[i].positions = "院长"
          //             break;
          //     }
          // }
          if (res.data.code === 200) {
            this.Info = res.data.data
            this.total = res.data.count
            this.loading = false
          }
        })
      },
      look_out() {
        if (this.hospital_name || this.docter_name != "") {
          this.$http.post('/doctor/selectDoctor', {
            page: this.page,
            limit: this.limit,
            companyName: this.hospital_name ? this.hospital_name : '',
            doctorName: this.docter_name ? this.docter_name : ''
          }).then(res => {
            if (res.data.code === 200) {
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].types === "0") {
                  res.data.data[i].types = "公立医院"
                } else if (res.data.data[i].types === "1") {
                  res.data.data[i].types = "私立医院"
                } else {
                  res.data.data[i].types = "综合医院"
                }
              }
              // for(let i=0;i<res.data.data.length;i++) {
              //     switch (res.data.data[i].positions) {
              //         case "0":
              //             res.data.data[i].positions = "主任医师"
              //             break;
              //         case "1":
              //             res.data.data[i].positions = "副主任医师"
              //         default:
              //             res.data.data[i].positions = "院长"
              //             break;
              //     }
              // }
              if (res.data.data.length == 0) {
                this.$message.warning("暂无匹配结果,请查看搜索条件是否正确")
                this.Info = res.data.data
                this.total = res.data.count
              } else {
                this.Info = res.data.data
                this.total = res.data.count
              }

            }
          })
        } else {
          this.$message.warning("查询条件不完整")
        }
      },
      acount(e) {
        console.log(e)
        if (e.phone != null) {
          // this.acountInfo(e.phone)
          // this.centerDialogVisible = true
          this.watchAccount = true

        } else {
          this.centerDialogVisible = true
          this.doctorId = e.id
        }
      },
      acountInfo(phone) {
        this.$http.get(`/doctor/selectByPhone/${phone}`).then(res => {
          console.log(res)
          this.acount_Info = res.data.data
        })
      },
      confirm() {
        if (this.phone && this.nickName != "") {
          this.$http.post(`/doctor/addAccount`, {
            phone: this.phone,
            nickName: this.nickName,
            id: this.doctorId
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.phone = ""
              this.nickName = ""
              this.centerDialogVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.warning("信息不能为空")
        }
      },
      cancel() {
        this.phone = ""
        this.nickName = ""
        this.centerDialogVisible = false
      },
      confirm1() {
        this.watchAccount = false
      },
      //预约按钮
      yuyue(id) {
        console.log(id)
        this.isYuyue = true
      }
    }
  }
</script>

<style scoped lang="less">
  .reset_btn {
    border: 1px solid #666EE8;
    color: #666EE8;
    background-color: #FFFFFF;
    margin-left: 50px;
  }

  .search_btn {
    background-color: #666EE8;
    color: #FFFFFF;
    border: none;
  }

  .btns {
    display: flex;

    .btn_1 {
      background-color: #666de8;
      border: none;
    }

    .btn_2 {
      background-color: #f14866;
      border: none;
    }
  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 100px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin: 0 20px;
    }

    >div button {
      margin-bottom: 7px;
    }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;
    margin-left: 100px;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 100px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 100px;
  }

  .btns_1 {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 50px;
    }
  }
</style>
<template>
    <div>
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>医院管理</el-breadcrumb-item>
            <el-breadcrumb-item>添加</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <div style="padding: 20px">
                <div class="hospital_name">
                    <div style="margin-right:20px">医院名称:</div>
                    <el-input style="width:220px" v-model="hospital_name" placeholder="输入医院名称"></el-input>
                </div>
                <!-- 医院头像 -->
                <div class="uploadImg">
                    <div style="margin-right: 30px">医院图片:</div>
                    <!-- <img src="../../../assets/woods.jpg" alt="暂无加载"> -->
                    <div>
                        <el-upload
                            action="https://www.idemer.com/web/upload/file"
                            list-type="picture-card"
                            :on-preview="uploadImg"
                            :on-success="uploadSuccess"
                            :on-remove="handleRemove1"
                            :on-error="handErr1"
                            >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                            <!-- <el-button type="primary" style="background:none;color:#CACACA;border:1px solid #CACACA" icon="el-icon-upload" @click="edit">上传</el-button> -->
                        <div style="color:#CACACA">支持格式jpg,png,jpeg</div>
                    </div>
                </div>
                <!-- 医院头像 -->
                <div class="uploadImg">
                    <div style="margin-right: 30px">医院头像:</div>
                    <!-- <img src="../../../assets/woods.jpg" alt="暂无加载"> -->
                    <div>
                        <el-upload
                            action="https://www.idemer.com/web/upload/file"
                            list-type="picture-card"
                            :on-preview="upImg2"
                            :on-success="uploadSuccess2"
                            :on-remove="handleRemove2"
                            :on-error="handErr2">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                        <!-- <el-button type="primary" style="background:none;color:#CACACA;border:1px solid #CACACA" icon="el-icon-upload" @click="edit">上传</el-button> -->
                        <div style="color:#CACACA">支持格式jpg,png,jpeg</div>
                    </div>
                </div>
                <!-- 医院地址 -->
                <div class="edit_address">
                    <div class="edit_address_title">医院地址:</div>
                    <el-col :span="8">
                        <el-input
                            placeholder="请输入内容"
                            v-model="palce_name">
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-input  class="inp_address" v-model="detailAddress" placeholder="请输入详细地址"></el-input>
                    </el-col>
                </div>
                <!-- 医院类型 -->
                <div class="hospital_type_part">
                    <div style='margin-right:20px'>医院类型:</div>
                    <el-select v-model="keywords" placeholder="请选择" @change="chooseHospital_type(keywords)">
                        <el-option
                            v-for="item in hospital_type"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- 医院简介 -->
                <div class="edit_hospital_introduce">
                    <div style='margin-right:20px'>医院简介:</div>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4}"
                            placeholder="请输入内容"
                            v-model="introduce">
                        </el-input>
                    </el-col>
                </div>
                <!-- 营业时间 -->
                <div class="edit_hospital_introduce">
                    <div style='margin-right:20px'>营业时间:</div>
                    <el-col :span='8'>
                        <el-input
                            placeholder="请输入营业时间"
                            v-model="startTime">
                        </el-input>
                    </el-col>    
                </div>
                <!-- 手机号码 -->
                <div class="edit_hospital_introduce">
                    <div style='margin-right:20px'>医院电话:</div>
                    <el-col :span='8'>
                        <el-input
                            placeholder="请输入医院电话"
                            v-model="phoneNum">
                        </el-input>
                    </el-col>    
                </div>
                <div class="btns">
                    <el-button  @click="confirm" style="background-color:#686ce8;color:#FFFFFF">确定</el-button>
                    <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detailAddress: '',
            introduce: '',
            hospital_name: '',
            palce_name : '',
            hospital_avatar: '',
            hospital_image: '',
            startTime: '',
            phoneNum: '',
            hospital_type: [
                {
                    value: '0',
                    label: '公立医院'
                },
                {
                    value: '1',
                    label: '私立医院'
                },
                {
                    value: '2',
                    label: '综合医院'
                }
            ],
            keywords: "",
            // options_1: [
            //     {
            //         value: '安徽省',
            //         label: '安徽省',
            //         children: [
            //             {
            //                 value: '合肥市',
            //                 label: '合肥市',
            //                 children: [
            //                     {
            //                         value: '花岗区',
            //                         label: '花岗区'
            //                     }
            //                 ]
            //             },
            //             {
            //                 value: '六安市',
            //                 label: '六安市',
            //                 children: [
            //                     {
            //                         value: '舒城县',
            //                         label: '舒城县'
            //                     }
            //                 ]
            //             }
            //         ]
            //     }
            // ],
            dialogImageUrl: '',
            dialogVisible: false,
            // value1: [new Date(2021, 9, 10, 8, 40), new Date(2021, 9, 10, 9, 40)],
            // value2: [new Date(2021, 9, 10, 8, 40), new Date(2021, 9, 10, 9, 40)]
        }
    },
    methods: {
        chooseHospital_type(k) {
            // console.log(k)
            console.log(this.keywords)
            // console.log(this.keywords)
        },
        // 选择医院类型
        edit() {
            console.log("上传")
        },
        confirm() {
            if ((this.hospital_name && this.hospital_avatar &&  this.hospital_image && this.palce_name && this.keywords && this.phoneNum && this.startTime && this.introduce) !== "") {
                this.$http.post('/company/addCompany', {
                    companyName: this.hospital_name,
                    headPicture: this.hospital_avatar,
                    medicalInstitution: this.hospital_image, 
                    address: this.palce_name, 
                    types: this.keywords,
                    phone: this.phoneNum, 
                    openTime: this.startTime,
                    companyIntroduction: this.introduce
                }).then(res => {
                    console.log(res)
                    if(res.data.code == 200) {
                        this.$message.success(res.data.msg)
                        this.$router.back(-1)
                    }else {
                        this.$message.error("添加失败")
                    }
                })
            } else {
                this.$message.warning("请确认填写信息是否完整")
            }
        },
        getback() {
            this.$router.go(-1)
        },
        uploadImg(file, fileList) {
            console.log(file);
            console.log(fileList)
        },
        error11(file) {
            console.log(file)
        },
        uploadSuccess(file) {
            this.hospital_avatar = file
            console.log(this.hospital_avatar)
        },
        handleRemove1(file) {
            console.log(file)
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        upImg2(file, fileList) {
            console.log(file, fileList);
        },
        uploadSuccess2(file) {
            this.hospital_image = file
            console.log(this.hospital_image)
        },
        handleRemove2(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handErr1(err, file, fileList) {
            this.$message.error('上传失败请重试')
        },
        handErr2(err, file, fileList) {
            this.$message.error('上传失败请重试')
        }
        
    }
}
</script>

<style lang="less" scoped>
.hospital_name {
    display: flex;
    align-items: center;
}
.uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;
    img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin: 0 20px;
    }
    >div button {
        margin-bottom: 7px;
    }
}
.edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;
    .edit_address_title {
        margin-right: 20px;
    }
    .inp_address {
        margin-left: 30px;
    }
}
.hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.btns {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;
    >button {
        margin: 0 50px;
    }
}
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding: 20px">
        <div>
          <div class="hospital_name">
            <div style="width:100px"><i style="color:red;">*</i>医生:</div>
            <el-select v-model="currentDoctor" placeholder="请选择">
              <el-option v-for="item in doctorList" :key="item.id" :label="item.name" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="hospital_name">
          <div style="width:100px"><i style="color:red;">*</i>电话号码:</div>
          <el-input style="width:220px" v-model="phoneNum" placeholder="输入医院电话"></el-input>
        </div>
        <!-- <div class="hospital_name">
          <div style="width:100px">昵称:</div>
          <el-input style="width:220px" v-model="nickName" placeholder="输入昵称"></el-input>
        </div> -->
        <div class="btns">
          <el-button @click="confirm" style="background-color:#686ce8;color:#FFFFFF">确定</el-button>
          <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        name: '',
        phoneNum: '',
        password: '',
        nickName: '',
        doctorList: '',// 医生列表
        currentDoctor: '',//选中医生
      }
    },
    created() {
      this.fetch()
    },
    methods: {
      // 获取医生列表
      fetch() {
        this.$http.post(`/doctor/selectDoctor`, {
          limit: 10,
          page: 1
        }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.doctorList = res.data.data;
          }
        });
      },
      confirm() {
        if (this.currentDoctor && this.phoneNum) {
          this.$http.post('/doctor/addcount', {
            id: this.currentDoctor.id,// 医生ID
            phone: this.phoneNum,// 手机号,
          }).then((res) => {
            console.log(res)
            if (res.data.code === 200) {
              this.$message.success(res.data.msg)
              setTimeout(() => {
                this.$router.go(-1)
              }, 1000)
            }
          })
        } else {
          this.$message.error('必填项不能为空！');
        }
      },
      getback() {
        this.$router.go(-1)
      },


    }
  }
</script>

<style lang="less" scoped>
  .hospital_name {
    display: flex;
    align-items: center;
    margin: 50px 0;
  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin: 0 20px;
    }

    >div button {
      margin-bottom: 7px;
    }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;

    >button {
      margin: 0 50px;
    }
  }
</style>
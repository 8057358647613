<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医疗服务</el-breadcrumb-item>
      <el-breadcrumb-item>预约设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns">
        <el-button type="primary" icon="el-icon-plus" class="btn_1" @click="dialogVisible = true">添加</el-button>
        <el-button type="primary" icon="el-icon-s-check" class="btn_1" @click="getpreviewTable">预览</el-button>
      </div>
      <!-- 预览医生排班 -->
      <el-dialog title="预览医生排班" @close="handleClose3" :visible.sync="dialogVisible3" width="60%" center>
        <div style="width: 100%">
          <!-- 表格 -->
          <div id="projec" class="name_content" style="width: 100%">
            <div class="name_content_tips" style="width: 100%">
              <el-table :header-cell-style="{'padding':'0px'}" :key="czKey" size='medium' :data="tableData3" border
                style="width: 100%">
                <el-table-column prop=" " label=" ">
                  <template slot-scope="scope">
                    <div>
                      {{scope.row.name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column v-for="(item,index) in previewTable" :key="index" :prop="item.data"
                  :label="item.title">
                  <template #header>
                    <div class="currentTimeStyle" style="display: flex;flex-direction:column"
                      :class="item.current?'currentTime':'nocurrentTime'">
                      <div>{{item.time}}</div>
                      <div>({{item.title}})</div>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      {{scope.row[item.data].join(',')}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- 表格区域 -->
      <el-table ref="multipleTable" @row-click.self="bss" :data="tableData" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="doctorName" label="医生">
        </el-table-column>
        <el-table-column label="日期">
          <template slot-scope="scope">{{ scope.row.timeDate }}</template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center;color:#46a1fe">
              <el-button size="small" type="warning" @click.stop="edit(scope.row)">编辑</el-button>
              <el-button size="small" type="danger" @click.stop="del(scope,'2')"">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style=" margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="page" :page-sizes="[10]" :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
                <!-- 添加医生 -->
                <el-dialog :title="currentType?'添加医生排班':'编辑医生排班'" :visible.sync="dialogVisible" width="60%"
                  @close="handleClose3" center>
                  <div style="overflow-y: scroll;height: 65vh;">
                    <div v-loading="loading">
                      <!-- 当前编辑医生 -->
                      <div v-if="!currentType" id="projec" class="name_content">
                        <div class="name_content_tips">当前编辑医生:</div>
                        <div>{{currentDoctorItem.doctorName}}</div>
                      </div>
                      <!-- 医生 -->
                      <div v-if="currentType" id="projec" class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>选择医生:</div>
                        <el-checkbox-group size="medium" v-model="currentDoctor">
                          <el-checkbox v-for="(item,index) in doctorList"
                            :disabled="tableData.findIndex(x=>x.doctorId==item.id)==-1?false:true" :label="item"
                            :key="index">
                            {{item.name}}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                      <!-- 日期模板 -->
                      <div id="projec" class="name_content">
                        <div class="name_content_tips">选择模板:</div>
                        <template>
                          <el-select size="small" v-model="tmpVal" placeholder="请选择日期模板">
                            <el-option-group v-for="group in tmpOptions" :key="group.label" :label="group.label">
                              <el-option v-for="item in group.options" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </template>
                        <div style="color:red;font-size: 13px;margin-left: 20px;">*请选择日期模板，如不选择则创建一个新的日期模板</div>
                      </div>
                      <!-- 星期 -->
                      <div v-if="currentType" id="projec" class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>选择日期:</div>
                        <el-checkbox-group v-model="currentDate" size="mini">
                          <el-checkbox-button @change="changeTime($event,item,'1')" v-for="(item,index) in weekList"
                            :label="item.label" :key="index">{{item.data}}
                          </el-checkbox-button>
                        </el-checkbox-group>
                      </div>
                      <!-- 已选中星期 -->
                      <div v-if="!currentType" id="projec" class="name_content">
                        <div class="name_content_tips">当前选中日期:</div>
                        <div>{{currentUpDataItem?currentUpDataItem.column.label:''}}</div>
                      </div>
                      <!-- 上午时间段 -->
                      <div id="projec" class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>上午班次:</div>
                        <el-time-select :editable="false" @change="change1" size="mini" placeholder="上午开始时间"
                          v-model="startTime" :picker-options="{
    start: '00:00',
    step: intervalDate.value,
    end: '24:00'
  }">
                        </el-time-select>
                        <el-time-select :editable="false" @change="change2" style="margin-left:10px" size="mini"
                          placeholder="上午结束时间" v-model="endTime" :picker-options="{
      start: '00:00',
      step: intervalDate.value,
      end: '24:00',
      minTime: startTime
    }">
                        </el-time-select>
                        <div style="margin-left:20px">间隔时间：</div>
                        <el-select @change="intervalDateChange" size="mini" v-model="intervalDate"
                          placeholder="请选择间隔时间">
                          <el-option v-for="item in intervalList" :key="item.value" :label="item.label" :value="item">
                          </el-option>
                        </el-select>
                      </div>
                      <!-- 午休时间段 -->
                      <div id="projec" class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>午休时间:</div>
                        <el-time-select :editable="false" @change="change3" size="mini" placeholder="午休开始时间"
                          v-model="noonBreakStartTime" :picker-options="{
              start: endTime || '00:00',
              step: '00:30',
              end: '24:00'
            }">
                        </el-time-select>
                        <el-time-select :editable="false" @change="change4" style="margin-left:10px" size="mini"
                          placeholder="午休结束时间" v-model="noonBreakEndTime" :picker-options="{
                start: noonBreakStartTime || '00:00',
                step: '00:30',
                end: '24:00',
                minTime: noonBreakStartTime
              }">
                        </el-time-select>
                      </div>
                      <!-- 下午时间段 -->
                      <div id="projec" class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>下午班次:</div>
                        <el-time-select :editable="false" @change="change5" size="mini" placeholder="下午开始时间"
                          v-model="afternoonStartTime" :picker-options="{
              start: noonBreakEndTime || endTime || '00:00',
              step: intervalDate2.value,
              end: '24:00'
            }">
                        </el-time-select>
                        <el-time-select :editable="false" @change="change6" style="margin-left:10px" size="mini"
                          placeholder="下午结束时间" v-model="afternoonEndTime" :picker-options="{
                start: afternoonStartTime || '00:00',
                step: intervalDate2.value,
                end: '24:00',
                minTime: afternoonStartTime
              }">
                        </el-time-select>
                        <div style="margin-left:20px">间隔时间：</div>
                        <el-select @change="intervalDateChange2" size="mini" v-model="intervalDate2"
                          placeholder="请选择间隔时间">
                          <el-option v-for="item in intervalList" :key="item.value" :label="item.label" :value="item">
                          </el-option>
                        </el-select>
                      </div>
                      <!-- 挂号数量 -->
                      <div class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>选择挂号数量:</div>
                        <el-select size="mini" @change="changeRegistration" v-model="registrationValue"
                          placeholder="请选择挂号数量">
                          <el-option v-for="item in registrationList" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                      <!-- 排班重复 -->
                      <div class="name_content">
                        <div class="name_content_tips">设置排班重复:</div>
                        <el-select size="mini" v-model="repetitionValue" placeholder="请选择排班重复模式">
                          <el-option v-for="item in repetitionList" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                      <!-- 设置挂号数量 -->
                      <div id="projec" class="name_content">
                        <div class="name_content_tips"><i style="color:red;">*</i>设置挂号数量:</div>
                        <div>
                          <!-- 上午 -->
                          <div style="display:flex;align-items: center;height: 100px;">
                            <div>上午：</div>
                            <div class="container">
                              <div v-for="(item,index) in forenoonLisst" :key="index" class="items">
                                <div
                                  style="border-bottom: 1px solid #ccc;height: 30px;display: flex;align-items: center;justify-content: center;width: 100%;">
                                  {{item.startTime+'-'+item.endTime}}
                                </div>
                                <div class="item">
                                  <input placeholder="请输入数量" v-model="item.data" min="0" max="10" step="1"
                                    oninput="if(value>10){value=10}else if(value<0){value=0}" class="inputStyle"
                                    type="number">
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- 午休 -->
                          <div style="display:flex;align-items: center;height: 50px;">
                            <div>午休：</div>
                            <div class="container">
                              {{noonBreakStartTime && noonBreakEndTime?noonBreakStartTime+' - '+noonBreakEndTime:''}}
                            </div>
                          </div>
                          <!-- 下午 -->
                          <div style="display:flex;align-items: center;height: 100px;">
                            <div>下午：</div>
                            <div class="container">
                              <div v-for="(item,index) in afternoonLisst" :key="index" class="items">
                                <div
                                  style="border-bottom: 1px solid #ccc;height: 30px;display: flex;align-items: center;justify-content: center;width: 100%;">
                                  {{item.startTime+'-'+item.endTime}}
                                </div>
                                <div class="item">
                                  <input placeholder="请输入数量" v-model="item.data" min="0" max="10" step="1"
                                    oninput="if(value>10){value=10}else if(value<0){value=0}" class="inputStyle"
                                    type="number">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span style="display: flex;width: 100%;justify-content: space-around;" slot="footer"
                      class="dialog-footer">
                      <el-button @click="dialogVisible = false">取 消</el-button>
                      <el-button type="primary" @click="clickSubmit">确 定</el-button>
                    </span>
                  </div>
                </el-dialog>
                <!-- 编辑医生 -->
                <el-dialog title="编辑医生排班" :visible.sync="dialogVisible2" width="60%" @close="handleClose2" center>
                  <div v-loading="loading" style="width: 100%">
                    <!-- 当前编辑医生 -->
                    <div id="projec" class="name_content">
                      <div class="name_content_tips">当前编辑医生:{{currentDoctorItem.doctorName}}</div>
                    </div>
                    <!-- 日期模板 -->
                    <div id="projec" class="name_content">
                      <div class="name_content_tips">选择模板:</div>
                      <template>
                        <el-select size="small" v-model="tmpVal" placeholder="请选择日期模板">
                          <el-option-group v-for="group in tmpOptions" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </template>
                      <div style="color:red;font-size: 13px;margin-left: 20px;">*请选择日期模板，如不选择则创建一个新的日期模板</div>
                    </div>
                    <!-- 表格 -->
                    <div id="projec" class="name_content" style="width: 100%">
                      <div class="name_content_tips" style="width: 100%">
                        <el-table size='mini' :data="tableData2" border style="width: 100%">
                          <el-table-column prop=" " label=" ">
                            <template slot-scope="scope">
                              <div>
                                {{scope.row.name}}
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x1" label="星期一">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x1" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x1" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x1"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x2" label="星期二">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x2" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x2" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x2"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x3" label="星期三">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x3" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x3" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x3"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x4" label="星期四">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x4" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x4" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x4"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x5" label="星期五">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x5" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x5" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x5"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x6" label="星期六">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x6" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x6" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x6"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="x7" label="星期日">
                            <template slot-scope="scope">
                              <div>
                                <el-tooltip v-if="scope.row.x7" placement="top">
                                  <div slot="content">
                                    <div style="display:flex;justify-content:content;align-items:center;">
                                      <el-button @click="upData(scope)" size="mini" type="primary">修改</el-button>
                                      <el-button @click="del2(scope)" size="mini" type="danger">删除</el-button>
                                    </div>
                                  </div>
                                  <el-button size="mini" v-if="scope.row.x7" type="success">修改</el-button>
                                </el-tooltip>
                                <div @click="addDoctoeData(scope)" style="font-size: 24px;" v-if="!scope.row.x7"
                                  class="el-icon-circle-plus-outline"></div>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
                  </span>
                </el-dialog>
    </el-card>
  </div>
</template>


<script>
  import { parseTime } from '../../../utils/index.js'
  export default {
    data() {
      return {
        // 日期模板
        tmpOptions: [{
          label: '每周重复',
          options: [{
            value: 'Shanghai',
            label: '模板1'
          }, {
            value: 'Beijing',
            label: '模板2'
          }]
        }, {
          label: '间隔一周重复',
          options: [{
            value: 'Chengdu',
            label: '模板3'
          }, {
            value: 'Shenzhen',
            label: '模板4'
          }, {
            value: 'Guangzhou',
            label: '模板5'
          }, {
            value: 'Dalian',
            label: '模板6'
          }]
        }, {
          label: '间隔一个月重复',
          options: [{
            value: 'Chengdu',
            label: '模板7'
          }, {
            value: 'Shenzhen',
            label: '模板8'
          }, {
            value: 'Guangzhou',
            label: '模板9'
          }, {
            value: 'Dalian',
            label: '模板10'
          }]
        }],
        tmpVal: '',// 选中日期模板
        czKey: '111111',// 更改key重置表格
        // 预览医生表格
        tableData3: [{
          data: '',
          name: '上午',
          x1: [],
          x2: [],
          x3: [],
          x4: [],
          x5: [],
          x6: [],
          x7: [],
        }, {
          data: '',
          name: '下午',
          x1: [],
          x2: [],
          x3: [],
          x4: [],
          x5: [],
          x6: [],
          x7: [],
        }],
        previewTable: [{
          title: '星期一',
          time: '',
          current: false,
          data: 'x1',
        }, {
          title: '星期二',
          time: '',
          current: false,
          data: 'x2',
        }, {
          title: '星期三',
          time: '',
          current: false,
          data: 'x3',
        }, {
          title: '星期四',
          time: '',
          current: false,
          data: 'x4',
        }, {
          title: '星期五',
          time: '',
          current: false,
          data: 'x5',
        }, {
          title: '星期六',
          time: '',
          current: false,
          data: 'x6',
        }, {
          title: '星期日',
          time: '',
          current: false,
          data: 'x7',
        }],// 预览表格标题
        currentUpDataItem: '',//当前编辑项
        // 编辑医生表格
        tableData2: [{
          data: '',
          name: '上午',
          x1: false,
          x2: false,
          x3: false,
          x4: false,
          x5: false,
          x6: false,
          x7: false,
        }, {
          data: '',
          name: '下午',
          x1: false,
          x2: false,
          x3: false,
          x4: false,
          x5: false,
          x6: false,
          x7: false,
        }],
        currentDoctorData: [],// 当前医生详细数据
        loading: false,//弹框加载状态
        registrationList: [{
          value: '1',
          label: '1'
        }, {
          value: '2',
          label: '2'
        }, {
          value: '3',
          label: '3'
        }, {
          value: '4',
          label: '4'
        }, {
          value: '5',
          label: '5'
        }, {
          value: '6',
          label: '6'
        }, {
          value: '7',
          label: '7'
        }, {
          value: '8',
          label: '8'
        }, {
          value: '9',
          label: '9'
        }, {
          value: '10',
          label: '10'
        }],
        registrationValue: '1',// 挂号选择数量
        repetitionList: [{
          value: '1',
          label: '每周重复'
        }, {
          value: '2',
          label: '间隔一周重复'
        }, {
          value: '3',
          label: '间隔一个月重复'
        }],
        repetitionValue: '1',// 排班重复模式
        closeTimeList: [],// 取消已选择列表
        selectTimeList: [],// 编辑已选中星期列表
        currentDoctorItem: '',// 当前编辑选中医生
        forenoonLisst: [],// 上午时间段
        afternoonLisst: [],// 下午时间段
        currentType: true,// 当前弹框类型
        //上午时间段选中间隔
        intervalDate: {
          value: '00:30',
          data: 30,
          label: '30分钟'
        },
        //下午时间段选中间隔
        intervalDate2: {
          value: '00:30',
          data: 30,
          label: '30分钟'
        },
        startTime: '',// 上午开始时间段
        endTime: '',// 上午结束时间段
        noonBreakStartTime: '',// 午休开始时间
        noonBreakEndTime: '',// 午休结束时间
        afternoonStartTime: '',// 下午开始时间
        afternoonEndTime: '',// 下午结束时间
        intervalList: [{
          value: '00:30',
          data: 30,
          label: '30分钟'
        }, {
          value: '01:00',
          data: 60,
          label: '60分钟'
        }],// 时间段间隔
        // 星期列表
        weekList: [{
          label: 1,
          data: '星期一'
        }, {
          label: 2,
          data: '星期二'
        }, {
          label: 3,
          data: '星期三'
        }, {
          label: 4,
          data: '星期四'
        }, {
          label: 5,
          data: '星期五'
        }, {
          label: 6,
          data: '星期六'
        }, {
          label: 7,
          data: '星期日'
        }],
        currentDoctor: [],// 选中医生
        currentDate: [],// 选中日期
        doctorList: '',// 医生列表
        dialogVisible: false,// 添加医生弹框
        dialogVisible2: false,// 编辑医生弹框
        dialogVisible3: false,// 医生排版预览弹框
        tableData: [],// 表格列表
        multipleSelection: [],
        page: 1,
        limit: 10,
        total: 0
      }
    },
    created() {
      this.getList()
    },
    mounted() {
      this.fetch()
      this.getTime()
    },
    methods: {
      // 获取预览表格
      getpreviewTable() {
        this.dialogVisible3 = true
        this.$http.post('arrange/selectAllDoctor').then(res => {
          console.log(res)
          if (res.data.code == 200) {
            let Time = new Date()
            let day = Number(Time.getDay())
            let currentTime = parseTime(Time, '{m}-{d}')
            let oldTime = Number(Time) - ((day ? day - 1 : day) * 24 * 60 * 60 * 1000)
            // 获取当前星期日期
            for (let i = 0; i < this.previewTable.length; i++) {
              let item = this.previewTable[i]
              let date = oldTime + ((i) * 24 * 60 * 60 * 1000)
              if (String(item.data[1] == '7') && String(day) === '0') {
                item.current = true
              } else if (String(day) === String(item.data[1])) {
                item.current = true
              }
              item.time = parseTime(date, '{m}-{d}')
            }
            // 获取表格数据
            for (let i = 0; i < res.data.data.length; i++) {
              let item = res.data.data[i]
              let x = 'x' + item.timeDate
              if (item.morEndTime && item.morStartTime) {
                this.tableData3[0][x].push(item.doctorName)
              }
              if (item.afterEndTime && item.afterStartTime) {
                this.tableData3[1][x].push(item.doctorName)
              }
            }
            this.czKey = new Date().getTime()
          }
        })
      },
      // 预览医生弹框关闭
      handleClose3() {
        this.tableData3 = [{
          data: '',
          name: '上午',
          x1: [],
          x2: [],
          x3: [],
          x4: [],
          x5: [],
          x6: [],
          x7: [],
        }, {
          data: '',
          name: '下午',
          x1: [],
          x2: [],
          x3: [],
          x4: [],
          x5: [],
          x6: [],
          x7: [],
        }]
        this.previewTable = [{
          title: '星期一',
          time: '',
          current: false,
          data: 'x1',
        }, {
          title: '星期二',
          time: '',
          current: false,
          data: 'x2',
        }, {
          title: '星期三',
          time: '',
          current: false,
          data: 'x3',
        }, {
          title: '星期四',
          time: '',
          current: false,
          data: 'x4',
        }, {
          title: '星期五',
          time: '',
          current: false,
          data: 'x5',
        }, {
          title: '星期六',
          time: '',
          current: false,
          data: 'x6',
        }, {
          title: '星期日',
          time: '',
          current: false,
          data: 'x7',
        }]
      },
      // 修改医生排班
      upData(item) {
        console.log(item)
        let timeDate = item.column.property[1]
        let im = ''
        for (let i = 0; i < item.row.data.length; i++) {
          if (item.row.data[i].timeDate == timeDate) {
            im = item.row.data[i]
          }
        }
        this.currentUpDataItem = item
        this.startTime = im.morStartTime
        this.endTime = im.morEndTime
        this.afternoonStartTime = im.afterStartTime
        this.afternoonEndTime = im.afterEndTime
        this.forenoonLisst = JSON.parse(im.morTimeQuantum)
        this.afternoonLisst = JSON.parse(im.afterTimeQuantum)
        this.currentType = false
        this.dialogVisible = true
      },
      // 添加医生排班
      addDoctoeData(item) {
        console.log(item)
        let timeDate = item.column.property[1]
        let im = ''
        for (let i = 0; i < item.row.data.length; i++) {
          if (item.row.data[i].timeDate == timeDate) {
            im = item.row.data[i]
          }
        }
        this.currentUpDataItem = item
        this.startTime = im ? im.morStartTime : ''
        this.endTime = im ? im.morEndTime : ''
        this.afternoonStartTime = im ? im.afterStartTime : ''
        this.afternoonEndTime = im ? im.afterEndTime : ''
        this.forenoonLisst = im ? JSON.parse(im.morTimeQuantum) : []
        this.afternoonLisst = im ? JSON.parse(im.afterTimeQuantum) : []
        this.currentType = false
        this.dialogVisible = true
      },
      // 选择挂号默认值
      changeRegistration() {
        this.getTime('上午')
        this.getTime('下午')
      },
      // 去除已选中日期
      changeTime(e, item, type) {
        console.log(e, item)
        // if (type == '2') {
        //   if (e) {
        //     this.currentDate.splice(this.currentDate.indexOf(item.label), 1)
        //   } else {
        //     this.currentDate.push(item.label)
        //   }
        // } else if (type == '1') {
        //   if (e) {
        //     this.closeTimeList.splice(this.closeTimeList.indexOf(item.label), 1)
        //   } else {
        //     this.closeTimeList.push(item.label)
        //   }
        // }
        if (type == '2') {
          if (e) {
            this.closeTimeList.push(item.label)
          } else {
            this.closeTimeList.splice(this.closeTimeList.indexOf(item.label), 1)
          }
        }
      },
      // 医生排班详情页
      bss(e) {
        // this.$router.push({
        //   path: "/yuyueadd",
        //   query: {
        //     item: e,
        //   },
        // });
      },
      // 添加弹框确认
      clickSubmit() {
        if (this.currentType) {
          this.submit()
        } else {
          this.compileDoctor()
        }
      },
      // 编辑医生提交
      compileDoctor() {
        if (((this.startTime && this.endTime) || (this.afternoonStartTime && this.afternoonEndTime))) {
          let itemObj = {
            yuyueParmList: [],
            cancelDay: this.closeTimeList,// 取消排班日期
            doctorId: this.currentDoctorItem.doctorId,// 医生ID
          }
          let obj = {
            doctorId: this.currentDoctorItem.doctorId,// 医生ID
            doctorName: this.currentDoctorItem.doctorName,// 医生姓名 
            morStartTime: this.startTime,// 上午开始时间
            timeDate: this.currentUpDataItem.column.property[1],// 星期几
            morEndTime: this.endTime,// 上午结束时间
            id: '',//
            afterStartTime: this.afternoonStartTime,// 下午开始时间
            afterEndTime: this.afternoonEndTime,// 下午结束时间
            morTimeQuantum: JSON.stringify(this.forenoonLisst),// 上午时间段
            afterTimeQuantum: JSON.stringify(this.afternoonLisst),// 下午时间段
            createTime: parseTime(new Date().getTime(), "{y}-{m}-{d} {h}:{i}:{s}"),// 当前时间
          }
          if (this.startTime && this.endTime) {
            obj.morStartTime = this.startTime
            obj.morEndTime = this.endTime
          }
          if (this.afternoonStartTime && this.afternoonEndTime) {
            obj.afterStartTime = this.afternoonStartTime// 下午开始时间
            obj.afterEndTime = this.afternoonEndTime// 下午结束时间
          }
          itemObj.yuyueParmList.push(obj)
          for (let i = 0; i < itemObj.yuyueParmList.length; i++) {
            let item = itemObj.yuyueParmList[i]
            for (let j = 0; j < this.currentDoctorData.length; j++) {
              let im = this.currentDoctorData[j]
              if (item.timeDate == im.timeDate) {
                item.id = im.id
              }
            }
          }
          console.log(itemObj)
          this.loading = true
          this.$http.post('arrange/update', itemObj).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.getDoctorData()
              this.getList()
              this.dialogVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
            this.loading = false
          })
        } else {
          this.$message.error('信息填写不完整')
        }
      },
      // 确认添加医生
      submit() {
        if (this.currentDoctor.length > 0 && this.currentDate.length > 0 && ((this.startTime && this.endTime) || (this.afternoonStartTime && this.afternoonEndTime))) {
          for (let i = 0; i < this.forenoonLisst.length; i++) {
            let item = this.forenoonLisst[i]
            item.quantity = item.data
          }
          for (let i = 0; i < this.afternoonLisst.length; i++) {
            let item = this.afternoonLisst[i]
            item.quantity = item.data
          }
          let arr = []
          for (let i = 0; i < this.currentDoctor.length; i++) {
            let item = this.currentDoctor[i]
            let itemObj = {
              yuyueParmList: [],
              doctorId: item.id,// 医生ID
              repetitionValue: this.repetitionValue,// 重复排班模式
            }
            for (let j = 0; j < this.currentDate.length; j++) {
              let obj = {
                doctorId: item.id,// 医生ID
                doctorName: item.name,// 医生姓名 
                repetitionValue: this.repetitionValue,// 重复排班模式
                timeDate: this.currentDate[j],// 星期几
                morTimeQuantum: JSON.stringify(this.forenoonLisst),// 上午时间段
                afterTimeQuantum: JSON.stringify(this.afternoonLisst),// 下午时间段
                createTime: parseTime(new Date().getTime(), "{y}-{m}-{d} {h}:{i}:{s}"),// 当前时间
              }
              if (this.startTime && this.endTime) {
                obj.morStartTime = this.startTime
                obj.morEndTime = this.endTime
              }
              if (this.afternoonStartTime && this.afternoonEndTime) {
                obj.afterStartTime = this.afternoonStartTime// 下午开始时间
                obj.afterEndTime = this.afternoonEndTime// 下午结束时间
              }
              itemObj.yuyueParmList.push(obj)
            }
            arr.push(itemObj)
          }
          this.$http.post('/arrange/add', arr).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.dialogVisible = false
              this.currentDoctor = []
              this.currentDate = []
              this.startTime = ''
              this.endTime = ''
              this.afternoonStartTime = ''
              this.afternoonEndTime = ''
              this.registrationValue = '1'
              this.repetitionValue = '1'
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('信息填写不完整')
        }
      },
      change1(e) {
        this.noonBreakStartTime = ''// 午休开始时间
        this.noonBreakEndTime = ''// 午休结束时间
        this.afternoonStartTime = ''// 下午开始时间
        this.afternoonEndTime = ''// 下午结束时间
        this.afternoonLisst = []
        this.endTime = ''
        this.getTime('上午')
      },
      change2(e) {
        this.noonBreakStartTime = ''// 午休开始时间
        this.noonBreakEndTime = ''// 午休结束时间
        this.afternoonStartTime = ''// 下午开始时间
        this.afternoonEndTime = ''// 下午开始时间
        this.afternoonLisst = []
        this.getTime('上午')
      },
      change3(e) {
        this.afternoonStartTime = ''// 下午开始时间
        this.afternoonEndTime = ''// 下午开始时间
        this.noonBreakEndTime = ''
        this.afternoonLisst = []
        this.getTime('上午')
        this.getTime('下午')
      },
      change4(e) {
        this.afternoonStartTime = ''// 下午开始时间
        this.afternoonEndTime = ''// 下午开始时间
        this.afternoonLisst = []
        this.getTime('上午')
        this.getTime('下午')
      },
      change5(e) {
        this.afternoonEndTime = ''
        this.getTime('下午')
      },
      change6(e) {
        this.getTime('下午')
      },
      getTime(type = '上午') {
        let startTime = ''
        let endTime = ''
        let intervalDate = 0
        if (type == '上午') {
          if (this.startTime && this.endTime) {
            startTime = this.startTime || '00:00'
            endTime = this.endTime || '24:00'
            intervalDate = (this.intervalDate.data) * 60 * 1000
          } else {
            this.forenoonLisst = []
            return
          }
        } else if (type == '下午') {
          if (this.afternoonStartTime && this.afternoonEndTime) {
            startTime = this.afternoonStartTime || '00:00'
            endTime = this.afternoonEndTime || '24:00'
            intervalDate = (this.intervalDate2.data) * 60 * 1000
          } else {
            this.afternoonLisst = []
            return
          }
        } else {
          startTime = '00:00'
          endTime = '24:00'
        }
        let curTime = new Date()
        let date = parseTime(new Date().getTime(), '{y}-{m}-{d}')
        let startTime2 = date + ' ' + startTime
        let endTime2 = date + ' ' + endTime
        let num = Number(new Date(endTime2)) - Number(new Date(startTime2))
        let count = num / intervalDate
        let currentDate = startTime
        let endDate = ''
        let arr = []
        for (let i = 0; i < count; i++) {
          currentDate = parseTime(Number(new Date(startTime2)) + (intervalDate * i), '{h}:{i}')
          endDate = parseTime(Number(new Date(date + ' ' + currentDate)) + intervalDate, '{h}:{i}')
          let obj = {
            startTime: currentDate,
            endTime: endDate,
            data: this.registrationValue,
            type: type == '上午' ? '0' : '1',
            quantity: this.registrationValue,// 后端需要
          }
          arr.push(obj)
        }
        if (type == '上午') {
          this.forenoonLisst = arr
        } else if (type == '下午') {
          this.afternoonLisst = arr
        }
      },
      // 上午时间段间隔改变
      intervalDateChange() {
        this.startTime = ''
        this.endTime = ''
        this.$nextTick(() => {
          this.getTime('上午')
        })
      },
      // 下午时间段间隔改变
      intervalDateChange2() {
        this.afternoonStartTime = '',// 下午开始时间
          this.afternoonEndTime = '',// 下午开始时间
          this.$nextTick(() => {
            this.getTime('下午')
          })
      },
      // 获取医生列表
      fetch() {
        this.$http.post(`/doctor/selectDoctor`, {
          limit: 10,
          page: 1
        }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.doctorList = res.data.data;
          }
        });
      },
      // 添加医生弹框关闭
      handleClose() {
        this.currentDoctor = []
        this.currentDate = []
        this.startTime = ''
        this.endTime = ''
        this.intervalDate = {
          value: '00:30',
          data: 30,
          label: '30分钟'
        }
        this.noonBreakStartTime = ''
        this.noonBreakEndTime = ''
        this.afternoonStartTime = ''
        this.afternoonEndTime = ''
        this.intervalDate2 = {
          value: '00:30',
          data: 30,
          label: '30分钟'
        }
        this.forenoonLisst = []
        this.afternoonLisst = []
        if (this.currentType) {
          this.currentDoctorItem = ''
        }
        this.registrationValue = '1'
        this.closeTimeList = []
        this.selectTimeList = []
      },
      // 添加医生弹框关闭
      handleClose2() {
        this.currentUpDataItem = ''
        this.currentType = true
        this.tableData2 = [{
          data: '',
          name: '上午',
          x1: false,
          x2: false,
          x3: false,
          x4: false,
          x5: false,
          x6: false,
          x7: false,
        }, {
          data: '',
          name: '下午',
          x1: false,
          x2: false,
          x3: false,
          x4: false,
          x5: false,
          x6: false,
          x7: false,
        }]
      },
      // 查询列表
      getList() {
        this.$http.post(`/medical/selectYuyueParm`, {
          page: this.page
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.tableData = res.data.data
            this.total = res.data.count
            console.log(this.tableData)
          }
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
        console.log(this.multipleSelection)
      },
      handleSizeChange(newSize) {
        this.limit = newSize
        this.getList()
      },
      handleCurrentChange(newPage) {
        this.page = newPage
        this.getList()
      },
      // 删除医生全部排班
      del(item, type) {
        this.$confirm('是否删除这个医生的全部排班？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$http.post('/arrange/delete', {
            doctorId: item.row.doctorId,
            delKind: '1'
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }).catch(() => {
          // this.$message.error('取消删除')
        });
      },
      // 删除医生某一天排班
      del2(item) {
        console.log(item)
        let timeDate = item.column.property[1]
        let im = ''
        for (let i = 0; i < item.row.data.length; i++) {
          if (item.row.data[i].timeDate == timeDate) {
            im = item.row.data[i]
          }
        }
        this.currentUpDataItem = item
        this.startTime = im.morStartTime
        this.endTime = im.morEndTime
        this.afternoonStartTime = im.afterStartTime
        this.afternoonEndTime = im.afterEndTime
        this.forenoonLisst = JSON.parse(im.morTimeQuantum)
        this.afternoonLisst = JSON.parse(im.afterTimeQuantum)
        if (item.row.name == '上午') {
          this.startTime = ''
          this.endTime = ''
          this.forenoonLisst = []
        } else {
          this.afternoonStartTime = ''
          this.afternoonEndTime = ''
          this.afternoonLisst = []
        }
        let itemObj = {
          cancelDay: this.closeTimeList,// 取消排班日期
          doctorId: this.currentDoctorItem.doctorId,// 医生ID
          doctorName: this.currentDoctorItem.doctorName,// 医生姓名 
          morStartTime: this.startTime,// 上午开始时间
          timeDate: item.column.property[1],// 星期几
          morEndTime: this.endTime,// 上午结束时间
          id: '',//
          delKind: '0',
          afterStartTime: this.afternoonStartTime,// 下午开始时间
          afterEndTime: this.afternoonEndTime,// 下午结束时间
          morTimeQuantum: JSON.stringify(this.forenoonLisst),// 上午时间段
          afterTimeQuantum: JSON.stringify(this.afternoonLisst),// 下午时间段
          createTime: parseTime(new Date().getTime(), "{y}-{m}-{d} {h}:{i}:{s}"),// 当前时间
        }
        if (this.startTime && this.endTime) {
          itemObj.morStartTime = this.startTime
          itemObj.morEndTime = this.endTime
        }
        if (this.afternoonStartTime && this.afternoonEndTime) {
          itemObj.afterStartTime = this.afternoonStartTime// 下午开始时间
          itemObj.afterEndTime = this.afternoonEndTime// 下午结束时间
        }
        for (let j = 0; j < this.currentDoctorData.length; j++) {
          let im = this.currentDoctorData[j]
          if (itemObj.timeDate == im.timeDate) {
            itemObj.id = im.id
          }
        }
        this.$http.post('/arrange/delete', itemObj).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.getDoctorData()
            this.getList()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 获取医生排班详情
      getDoctorData() {
        this.$http.post('arrange/select', null, {
          params: {
            doctorId: this.currentDoctorItem.doctorId
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            let arr = []
            this.$nextTick(() => {
              for (let i = 0; i < res.data.data.length; i++) {
                let item = res.data.data[i]
                this.tableData2[0].data = res.data.data
                this.tableData2[1].data = res.data.data
                let x = 'x' + item.timeDate
                let morTimeQuantum = JSON.parse(item.morTimeQuantum)
                if (morTimeQuantum.length > 0) {
                  this.tableData2[0][x] = true
                } else {
                  this.tableData2[0][x] = false
                }
                let afterTimeQuantum = JSON.parse(item.afterTimeQuantum)
                if (afterTimeQuantum.length > 0) {
                  this.tableData2[1][x] = true
                } else {
                  this.tableData2[1][x] = false
                }
              }
              this.dialogVisible2 = true
            })
            this.selectTimeList = arr
            this.currentDoctorData = res.data.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 编辑
      edit(item) {
        console.log(item)
        this.currentDoctorItem = item
        this.currentType = false
        this.getDoctorData()
      }
    }
  }
</script>
<style scoped>
  /deep/.el-picker-panel .time-select .el-popper {
    width: 10rem !important;
  }

  /deep/.el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 10rem !important;
  }

  .el-table td,
  .el-table th.is-leaf,
  .el-table--border,
  .el-table--group {
    border-color: #ccc;
  }

  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #ccc;
  }


  .currentTime {
    color: #409EFF;
  }

  .nocurrentTime {
    color: #333;
  }

  .inputStyle {
    border: none;
    width: 90%;
    height: 80%;
    background-color: #fff;
    outline: none;
    text-align: center
  }

  .container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 600px;
    overflow-x: auto;
  }

  .items {
    border: 1px solid #ccc;
    width: 100px;
    height: 50%;
    font-size: 14px;
    color: #333;
  }

  .item {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }

  .inputStyle::-webkit-outer-spin-button,
  .inputStyle::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle[type='number'] {
    -moz-appearance: textfield;
  }

  .c {
    display: flex;
    justify-content: center;
  }

  .name_content {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }

  .name_content_tips {
    margin-right: 20px;
    /* width: 90%; */
  }
</style>
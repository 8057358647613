<template>
    <div>
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据统计</el-breadcrumb-item>
            <el-breadcrumb-item>患者统计</el-breadcrumb-item>
            <el-breadcrumb-item>编辑</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
           <div style="padding:0 20px">
               <div style="margin: 50px 0 50px 0;color:#233333;font-size:17px;font-weight:600">
                    编辑
                </div>
                <div style="padding:0 30px">
                    <div style="margin:50px 0;display:flex;justify-content: space-between;">
                        <div style="display:flex;align-items: center;">
                            <div style="margin-right:20px;width:70px">患者姓名:</div>
                            <el-input style="width:300px" v-model="name" placeholder="输入患者姓名"></el-input>
                        </div>
                        <div style="display:flex;align-items: center;">
                            <div style="margin-right:20px;width:70px">ID:</div>
                            <el-input style="width:300px" v-model="ID" placeholder="输入患者编号"></el-input>
                        </div>
                    </div>
                    <div style="margin:50px 0;display:flex;justify-content: space-between;">
                        <div style="display:flex;align-items: center;">
                            <div style="margin-right:20px;width:70px">性别:</div>
                            <el-input style="width:300px" v-model="gender"  placeholder="性别"></el-input>
                        </div>
                        <div style="display:flex;align-items: center;">
                            <div style="margin-right:20px;width:70px">年龄:</div>
                            <el-input style="width:300px" v-model="age" placeholder="输入年龄"></el-input>
                        </div>
                    </div>
                    <div style="margin:50px 0;display:flex;justify-content: space-between;">
                        <div style="display:flex;align-items: center;">
                            <div style="margin-right:20px;width:70px">电话:</div>
                            <el-input style="width:300px" v-model="telPhone" placeholder="号码"></el-input>
                        </div>
                    </div>
                    <div class="btns">
                        <el-button @click="confirm" style="background-color:#686ce8;color:#FFFFFF">确认</el-button>
                        <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
                    </div>
                </div>
           </div>
                   
            
            

        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return{
            activeName: 'first',
            name: '',
            ID: '',
            gender: '',
            age: '',
            telPhone: '',
            type: [
                {
                    tips: '初诊'
                },
                {
                    tips: '复诊'
                },
                {
                    tips: '快速挂号'
                }
            ],
            dianziRecord: [
                {
                    timestamp: '2020-01-02',
                    docterName: '阿卜杜拉',
                    type: '拔牙',
                    Info: '注意事项，注意什么事项，注意事项',
                    size: 'large',
                    icon: 'el-icon-more',
                    color: '#636dec'

                },
                {
                    timestamp: '2020-01-02',
                    docterName: '阿卜杜拉',
                    type: '拔牙',
                    Info: '注意事项，注意什么事项，注意事项' 
                }
            ],
        }
    },
    methods: {
        changeTabs(tab, event) {
            console.log(tab, event);
        },
        confirm() {
            console.log("确认信息")
        },
        getback() {
            this.$router.go(-1)
        },
        outFiles() {
            console.log("导出文件")
        }
    }
}
</script>

<style scoped lang="less">
.el-card__body {
    padding: 35px;
}
.btns {
    display: flex;
    justify-content: center;
    >button {
        margin: 0 30px;
    }
}
</style>
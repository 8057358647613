<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医生管理</el-breadcrumb-item>
      <el-breadcrumb-item>查看详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding:20px">
        <div style="display:flex;margin-bottom:90px;align-items: center;font-size:17px;color:#233333">
          <div>医生姓名:</div>
          <div style="margin-left:20px">{{doctorInfo.name}}</div>
        </div>
        <div style="display:flex;margin-bottom:90px;align-items: center;font-size:17px;color:#233333">
          <div>医生电话:</div>
          <div style="margin-left:20px">{{doctorInfo.phone}}</div>
        </div>
        <div style="display:flex;margin-bottom:90px;align-items: center;font-size:17px;color:#233333">
          <div>医生头像:</div>
          <!-- <el-image :src="doctorInfo.headPortrait" style="height:50px;width:50px;border-radius:50%;margin-left:30px"></el-image> -->
          <img :src="doctorInfo.headPicture" style="height:50px;width:50px;border-radius:50%;margin-left:30px" alt="">
        </div>
        <div style="display:flex;margin-bottom:90px;align-items: center;font-size:17px;color:#233333">
          <div>医生职称:</div>
          <div style="margin-left:20px">{{doctorInfo.jobTitle}}</div>
        </div>
        <div style="display:flex;margin-bottom:90px;font-size:17px;color:#233333">
          <div>擅长领域:</div>
          <div style="margin-left:20px;flex:0.7;">
            {{doctorInfo.strongpoint}}
          </div>
        </div>
        <!-- <div style="display:flex;margin-bottom:90px;font-size:17px;color:#233333">
                    <div>医生简介:</div>
                    <div style="margin-left:20px;flex:0.7;">
                        {{doctorInfo.shortintro}}
                    </div>
                </div> -->
        <div class="btns">
          <el-button @click="editInfo" type="primary" icon="el-icon-edit">编辑</el-button>
          <el-button @click="getback" type="primary">返回</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id: 0,
        doctorInfo: {}
      }
    },
    methods: {
      getback() {
        this.$router.go(-1)
      },
      getInfo() {
        this.$http.get(`/doctor/selectById/${this.id}`).then(res => {
          if (res.data.code == 200) {
            console.log(res)
            this.doctorInfo = res.data.data
            // switch (this.doctorInfo.positions) {
            //     case "0":
            //         this.doctorInfo.positions = "主任医师"
            //         break;
            //     case "1":
            //         this.doctorInfo.positions = "副主任医师"
            //     default:
            //         this.doctorInfo.positions = "院长"
            //         break;
            // }
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      editInfo() {
        this.$router.push({
          path: '/doctorManage/detail/edit',
          query: this.doctorInfo
        })
      }
    },
    created() {
      this.id = this.$route.query.id
      this.getInfo()
      // console.log(this.$route.query.id)
    }
  }
</script>

<style lang="less" scoped>
  .btns {
    display: flex;
    align-content: center;
    justify-content: center;
  }
</style>
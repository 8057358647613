<template>
    <div>
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>操作日志</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!-- table表格区域 -->
            <el-table
                v-loading="loading"
                ref="multipleTable"
                :data="userInfo"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                label="日志ID"
                prop="id"
               
                >
                <!-- <template #default="scope">{{ scope.row.date }}</template> -->
                </el-table-column>
                <el-table-column
                prop="loginIp"
                label="IP地址"
                >
                </el-table-column>
                <el-table-column
                prop="createTime"
                label="创建时间"
                >
                </el-table-column>
                <el-table-column
                label="操作"
                prop="title"
                >
                </el-table-column>
                <el-table-column
                label="删除"
               
                >
                <template  slot-scope="scope">
                     <el-button type="danger" @click="delLotList(scope.row.id)" size="mini">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->     
            <el-pagination style="margin-top:20px"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: [],
            multipleSelection: [], //当前选中的项
            page:1,
            limit: 10,
            total: 0,
            loading: false
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.loading = true
            this.$http.post('/log', {
                page:this.page,
                limit: this.limit
            }).then(res => {
                console.log(res)
                if(res.data.code == 200) {
                    this.userInfo = res.data.data
                    this.total = res.data.count
                    this.loading = false
                }else {
                    this.$message.error("数据加载失败")
                }
                
            })
        },
        chooseType(val) {
            // console.log(val)
            this.multipleSelection = val
            // console.log(this.multipleSelection)
        },
        // 删除选中的当前项目
        delete_info() {
             if(this.multipleSelection) {
                if(this.multipleSelection.length<2) {
                    console.log(this.multipleSelection)
                    let arr = this.multipleSelection.map((v) => {
                        console.log(v.id)
                        let arr = []
                        arr.push(v.id)
                        return arr
                    })
                    // console.log(arr)
                    for(let i = 0; i<arr.length;i++) {
                        console.log(arr[i])
                        // 将数组转为字符串
                        arr[i]=arr[i].join('.')
                    }
                    arr=arr.map(Number)
                    arr=arr.toString()
                    console.log(arr)
                    this.delLotList(arr)
                }else {
                    this.$message.warning("不支持多选")
                }
            }else {
                this.$message.error('当前无选中选项')
            }
            
        },
        // 批量删除列表数据
        delLotList(arr) {
            console.log(arr)
            this.$http.delete(`/deleteLog/${arr}`).then((res) => {
                console.log(res)
                if(res.data.code === 200) {
                    this.$message.success(res.data.msg)
                    this.getList()
                    
                }else {
                    this.$message.error("删除失败")
                }
            })
        },
        //分页
        handleSizeChange(newSize) {
            this.limit = newSize
            this.getList()
        },
        handleCurrentChange(newPage) {
            console.log(newPage)
            this.page = newPage
            this.getList()
        }
    }
}
</script>


<style lang='less' scoped>
.btns {
    .btn_2 {
        background-color: #f14866;
        border: none;
    }
}
</style>
<template>
  <div class="login_container">
    <div class="login_container_box" v-show="isShow=='login'">
      <div class="box_image">
        <img src="../assets/login_logo.png">
      </div>
      <div class="box_login_part">
        <div class="box_login_part_title">
          登录诊所管理系统
        </div>
        <!-- 登录表单区域 -->
        <el-form v-if="isPhone" :model="loginForm" ref="loginFormRef" :rules="loginFormRules" label-width="0"
          class="form_part">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" prefix-icon="iconfont icon-denglu" placeholder="请输入手机号码">
            </el-input>
          </el-form-item>
          <!-- 密码区域 -->
          <el-form-item prop="password">
            <el-input @keyup.native.13="Login" show-password type="password" v-model="loginForm.password"
              prefix-icon="iconfont icon-mimabeifen" placeholder="请输入密码"></el-input>
          </el-form-item>
          <div class="changeCheckBox">
            <el-checkbox v-model="checked" @change="changeLogin">一个月内免登录</el-checkbox>
            <div @click="forgetPassword">忘记密码</div>
            <div @click="clickZc">立即注册</div>
          </div>
          <!-- 按钮区域 -->
          <div class="btns">
            <div style="width: 100%;">
              <el-button class="login_btn" type="primary" @click="Login">登录</el-button>
            </div>
          </div>
        </el-form>
        <el-form v-else ref="loginFormRef2" label-width="0" class="form_part">
          <el-form-item :rules="[
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, message: '请输入正确手机号', trigger: 'blur' }
        ]">
            <el-input v-model="phoneNum" placeholder="请输入手机号码">
            </el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item prop="password" :rules="[
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, message: '请输入正确验证码', trigger: 'blur' }
        ]">
            <el-input style="width: 40%;" @keyup.native.13="Login" v-model="phoneCode" placeholder="请输入验证码">
            </el-input>
            <el-button style="margin-left: 20px;" :disabled="showCodeBtn" type="primary" @click="getCode">
              {{showCodeBtn?codeNum:'获取验证码'}}
            </el-button>
          </el-form-item>
          <!-- 按钮区域 -->
          <div class="btns">
            <div style="width: 100%;">
              <el-button class="login_btn" type="primary" @click="wxLogin">登录</el-button>
            </div>
          </div>
          <div @click="isPhone=true"
            style="display: flex;justify-content:center;font-size:14px;color:#409EFF;margin-top: 10px;">账号密码登录
          </div>
        </el-form>
        <div
          style="color: #b5b5b6;font-size:14px;display: flex;align-items:center;justify-content: center;margin-bottom: 16px;margin-top: 20px;">
          第三方登陆</div>
        <!-- 第三方登陆 -->
        <div style="display: flex;justify-content: center;align-items: center;
      position: relative;">
          <div :close="close_login_container" v-custom-shade="login_container" @click="getCodes">
            <transition name="login_container">
              <div v-show="login_container" id="login_container" style="position: absolute;top: 200%;left: -100%;
            background-color: rgb(249, 248, 248);z-index: 99;
            transform: translate(0%, -110%);">
              </div>
            </transition>
            <img style="width: 40px;height:40px;" src="../assets/wx.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 忘记密码区域 -->
    <transition name="fade">
      <div class="forgetPassword_part" v-show="isShow=='wjmm'">
        <div class="find_psd_part">
          <div class="find_psd_title">找回密码</div>
          <!-- 登录表单区域 -->
          <el-form :model="yzmForm" ref="passwordFormRed" :rules="passwordFormRules" label-width="0"
            class="find_psd_Form">
            <el-form-item prop="phoneNum">
              <el-input v-model="yzmForm.phoneNum" placeholder="请输入手机号" prefix-icon="iconfont icon-denglu"></el-input>
            </el-form-item>
            <!-- 密码区域 -->
            <el-form-item class="YZM" prop="yzm">
              <el-input class="yzm_inp" type="primary" v-model="yzmForm.yzm" placeholder="请输入验证码"
                prefix-icon="iconfont icon-mimabeifen"></el-input>
              <el-button class="yzm_btn" type="primary" @click="sendCode">{{!codeTime ? '发送验证码' : codeTime
                + 's' }}</el-button>
            </el-form-item>
            <el-form-item prop="newPsd">
              <el-input type="primary" show-password v-model="yzmForm.newPsd" placeholder="请输入新密码"
                prefix-icon="iconfont icon-mimabeifen"></el-input>
            </el-form-item>
            <!-- 按钮区域 -->
            <el-form-item prop="newPsdAgain">
              <el-input type="primary" show-password v-model="yzmForm.newPsdAgain" placeholder="再次输入新密码"
                prefix-icon="iconfont icon-mimabeifen"></el-input>
            </el-form-item>
            <!-- 确定按钮 -->
            <el-form-item style="margin-bottom:0">
              <el-button style="width:100%" type="primary" @click="submit">确定</el-button>
            </el-form-item>
            <div class="has_record" @click="goLogin('login')">已有账号去登录</div>
          </el-form>
        </div>
      </div>
    </transition>
    <!-- 注册账号 -->
    <transition name="fade" style="position: relative;">
      <div class="forgetPasswordZc" v-show="isShow=='zczh'">
        <!-- 标题 -->
        <div style="display:flex;justify-content:center;align-items:center;height: 50px;color: #333;background-color: #fff;position: fixed;left: 0px;right: 0px;font-weight: 600;font-weight: 700;
                            font-size: 21px;display: flex;justify-content: flex-start;padding-left: 40px;">
          <el-button style="width:100px" @click="goLogin('login')" type="primary">返回</el-button>
          <div style="display:flex;justify-content:center;width:80%">您正在注册牙如意通行证</div>
        </div>
        <!-- 表单 -->
        <div style="padding: 20px;
                        margin-top: 50px;
                        height: calc(100% - 50px);
                        overflow-y: auto;
                        box-sizing: border-box;">
          <div style="padding: 20px">
            <!-- 医院名称 -->
            <div class="hospital_name">
              <div style="width:100px"><i style="color:red">*</i>医院名称:</div>
              <div style="position:relative">
                <input style="width:300px;text-overflow: ellipsis;overflow: hidden;
                                white-space: nowrap;" class="inss" placeholder="请输入医院名称" v-model="editPhoneNumber" />
              </div>
            </div>
            <!-- 权限选择 -->
            <div v-show="isShowPower" class="hospital_name">
              <div style="width:100px">账号权限:</div>
              <el-select @change="choosePower" v-model="value1" placeholder="请选择">
                <el-option v-for="item in options1" :key="item.id" :label="item.roleName" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <!-- 电话号码 -->
            <div class="hospital_name">
              <div style="width:100px"><i style="color:red">*</i>电话号码:</div>
              <el-input style="width:220px" v-model="phoneNum" placeholder="输入医院电话"></el-input>
              <!-- 验证码 -->
              <div style="margin-left:20px">
                <el-input style="width:150px;margin-right: 20px;" v-model="phoneCode" placeholder="输入手机验证码"></el-input>
                <el-button @click="getCode" :disabled="showCodeBtn" size="small" type="primary">
                  {{showCodeBtn?codeNum:'获取验证码'}}</el-button>
              </div>
            </div>
            <!-- 医院地址: -->
            <div style="display:flex;align-items: center; margin-top: 50px;">
              <div style="width:90px"><i style="color:red">*</i>医院地址:</div>
              <div class="search-box">
                <input style="width: 300px;
                                                        height: 40px;
                                                        margin-left: 20px;
                                                        box-sizing: border-box;
                                                        box-sizing: 99;
                                                        border: 1px solid #ccc;" v-model="searchKey" type="search"
                  id="search" autocomplete="off">
                <el-button style="margin-left:20px;background-color: #DCDFE6;" slot="append" icon="el-icon-search"
                  @click="searchByHand"></el-button>
              </div>
              <el-amap class="amap-box" :amap-manager="amapManager" :vid="'amap-vue'" :zoom="zoom" :plugin="plugin"
                :center="center" :events="events" v-show="flist">
                <!-- 标记 -->
                <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index">
                </el-amap-marker>
              </el-amap>

            </div>
            <!-- 地图列表 -->
            <div style="max-height: 200px;
                                                                overflow-y: auto;
                                                                border-radius: 4px;
                                                                background-color: #fff;
                                                                box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
                                                                box-sizing: border-box;
                                                                margin: 5px 0;" class="tip-box" id="searchTip"></div>
            <!-- 医院头像 -->
            <div v-if="false" class="uploadImg">
              <div style="margin-right: 30px">医院头像:</div>
              <!-- <img src="../../../assets/woods.jpg" alt="暂无加载"> -->
              <div>
                <el-upload action="https://www.idemer.com/web/upload/file" list-type="picture-card" :limit="1"
                  :on-exceed="handleExceed3" :on-success="uploadSuccess3" :on-remove="handleRemove3"
                  :on-error="handErr1">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <!-- <el-dialog :visible.sync="dialogVisiblee2">
                                <img width="100%" :src="dialogImageUrlYL2" alt="">
                            </el-dialog> -->
                <div style="color:#CACACA">支持格式jpg,png,jpeg</div>
              </div>
            </div>
            <!-- 营业时间 -->
            <div v-if="false" class="edit_hospital_introduce">
              <div style='margin-right:20px'>营业时间:</div>
              <!-- <el-col :span='8'>
                                <el-input placeholder="请输入营业时间" v-model="startTime">
                                </el-input>
                            </el-col> -->
              <el-time-select placeholder="上班时间" v-model="startTime" :picker-options="{
                              start: '00:00',
                              step: '00:30',
                              end: '24:00'
                            }">
              </el-time-select>
              <el-time-select style="margin-left:10px" placeholder="下班时间" v-model="endTime" :picker-options="{
                              start: '00:00',
                              step: '00:30',
                              end: '24:00',
                              minTime: startTime
                            }">
              </el-time-select>
            </div>
            <!-- 按钮 -->
            <div class="btns">
              <el-button @click="confirm2" style="background-color:#686ce8;color:#FFFFFF">确定</el-button>
              <el-button @click="goLogin('login')" style="border:1px solid #686ce8;color:#686ce8">返回
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


</template>

<script>
  import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
  import '../utils/wxLogin.js'
  let amapManager = new AMapManager();
  import { Debounce } from '../utils/index'
  export default {
    data() {
      let self = this;
      return {
        loginExpires: false,// 免登录复选框
        openID: '',// 微信用户ID
        isPhone: true,
        buttonContent: '获取验证码',
        codeNum2: 60,// 计时
        login_container: false,// 二维码容器
        phoneCode: '',// 验证码
        codeNum: 60,// 计时
        showCodeBtn: false,
        de: new Debounce(500),
        loading2: false,//列表加载状态
        editPhoneNumber: "",// 搜索内容
        list: false,// 列表开关
        username: '',
        name: '',
        phoneNum: '',
        applayer: '',
        hospital_avatar2: [],
        dialogImageUrl: '',
        dialogVisible: false,
        options3: [],
        value: '',
        value1: '',
        isShowPower: false,
        checked: false,
        ids: [],
        page: 1,
        options1: [],
        detailAddress: '',
        introduce: '',
        hospital_name: '',
        hospital_avatar: '',
        hospital_image: '',
        startTime: '',
        endTime: '',
        phoneNum2: '',
        hospital_type: [
          {
            value: '0',
            label: '公立医院'
          },
          {
            value: '1',
            label: '私立医院'
          },
          {
            value: '2',
            label: '综合医院'
          }
        ],
        keywords: "",
        dialogImageUrlYL2: '',// 医院图片预览url
        dialogVisible2: false,// 医院图片预览开关
        searchKey: '',  /* 地图 */
        center: [0, 0],
        label: {
          content: '', offset: [-40, -40],
          markers: [],
        },
        zoom: 12, //缩放比例
        flist: false,
        markers: [],
        amapManager,
        // 忘记密码初始状态
        isShow: 'login',
        loginType: null,// 登陆状态
        // 登录表单的数据对象
        loginForm: {
          username: '',
          password: '',
          loginTag: '1'
        },
        yzmForm: {
          yzm: '',
          phoneNum: '',
          newPsd: '',
          newPsdAgain: ''
        },
        // 复选框状态
        checked: false,
        // 表单验证规则
        loginFormRules: {
          // 登录用户名校验
          username: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, message: '请输入正确手机号', trigger: 'blur' }
          ],
          // 登录密码校验
          password: [
            { required: true, message: '请输入登录密码', trigger: 'blur' },
            { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
          ]
        },
        passwordFormRules: {
          // 登录用户名校验
          phoneNum: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, message: '请输入正确手机号', trigger: 'blur' }
          ],
          // 登录密码校验
          yzm: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            { max: 6, message: '验证码有误', trigger: 'blur' }
          ],
          newPsd: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { min: 6, max: 15, message: '新密码长度在 6 到 15 个字符', trigger: 'blur' }
          ],
          newPsdAgain: [
            { required: true, message: '请再输入一次', trigger: 'blur' },
            { min: 6, max: 15, message: '新密码长度在 6 到 15 个字符', trigger: 'blur' }
          ]
        },
        codeTime: 0,
        userInfo: {},
        searchOption: {
          city: "全国",
          citylimit: true,
        },
        // 一些事件插件
        events: {
          init() {
            lazyAMapApiLoaderInstance.load().then(() => {
              self.initSearch();
            });
          },
          // 点击获取地址的数据
          click(e) {
            self.markers = [];
            let { lng, lat } = e.lnglat;
            self.lng = lng;
            self.lat = lat;
            self.center = {
              lng: lng,
              lat: lat,
            }
            // console.log(self.center);
            // self.center = [lng, lat];
            self.markers.push([lng, lat]);
            // console.log(self.center);
            // 这里通过高德 SDK 完成。
            let geocoder = new AMap.Geocoder({
              radius: 1000,
              extensions: "all",
            });
            geocoder.getAddress([lng, lat], function (status, result) {
              if (status === "complete" && result.info === "OK") {
                if (result && result.regeocode) {
                  self.address = result.regeocode.formattedAddress;
                  self.searchKey = result.regeocode.formattedAddress;
                  self.$nextTick();
                }
              }
            });
          },
        },
        //   一些工具插件
        plugin: [
          {
            // 定位
            pName: "Geolocation",
            events: {
              init(o) {
                // o是高德地图定位插件实例
                o.getCurrentPosition((status, result) => {
                  // if ( result.position.lng == this.HosInfo.longitude) {
                  //   console.log(111);
                  // }else{
                  //   self.lng = result.position.lng;
                  //   // 设置维度
                  //   self.lat = result.position.lat;
                  //   // 设置坐标
                  //   self.center = [self.lng, self.lat];
                  //   // self.markers.push([self.lng, self.lat]);
                  //   // load
                  //   self.loaded = true;
                  //   // 页面渲染好后
                  //   self.$nextTick();
                  // }
                  if (result && result.position) {
                    // 设置经度
                    self.lng = result.position.lng;
                    // 设置维度
                    self.lat = result.position.lat;
                    // 设置坐标
                    self.center = [self.lng, self.lat];
                    // self.markers.push([self.lng, self.lat]);
                    // load
                    self.loaded = true;
                    // 页面渲染好后
                    self.$nextTick();
                  }
                });
                console.log(self.markers);
              },
            },
          },

        ],
        // account: 18788833732,
        // password: 18788833732
      }
    },
    created() {
      this.getHosList()
      this.getPowerList()
    },
    mounted() {
      this.getCookie()
      let str = window.location.href
      let code = str.slice(str.indexOf('code'), str.indexOf('&')).split('=')[1]
      if (code) {
        this.setCode(code)
        this.notify = this.$notify({
          type: 'info',
          title: '提示',
          message: '正在登陆请稍等...',
          duration: 0
        });
      }
    },
    methods: {
      getCookie() {
        const token = this.$cookies.get("token");
        console.log(token)
        const userInfo = this.$cookies.get("userInfo")
        if (token && userInfo) {
          window.sessionStorage.setItem('token', token)
          if (userInfo.timeIsOver == 0) {
            this.loginType = true
          } else {
            this.loginType = false
          }
          this.userInfo = userInfo
          console.log(this.userInfo)
          window.localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          if (this.loginType) {
            this.$router.push({
              path: '/home',
              query: {
                username: this.userInfo.name,
                imgUrl: this.userInfo.headPicture
              }
            })
            this.$message.success('登陆成功')
          } else {
            this.$router.push({
              path: '/chongzhi',
              query: {
                username: this.userInfo.name,
                imgUrl: this.userInfo.headPicture
              }
            })
            this.$message.error('账号已到期，请续费后使用！')
          }
        }
      },
      wxLogin() {
        if (!this.phoneNum && !this.phoneCode) {
          this.$message.error('请输入完整信息')
          return
        }
        this.LoginFirst(`/pay/band`, {
          phone: this.phoneNum,
          openId: this.openID,
          code: this.phoneCode,
        })
      },
      // 发送code
      setCode(code) {
        this.$http.post(`pay/gettoken?code=${code}`).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.notify.close()
            window.sessionStorage.removeItem('token')
            this.$cookies.set('token', res.data.data.token, 3600 * 24 * 30, '/')
            window.sessionStorage.setItem('token', res.data.data.token)
            if (res.data.data.user.timeIsOver == 0) {
              this.loginType = true
            } else {
              this.loginType = false
            }
            this.userInfo = res.data.data.user
            console.log(this.userInfo)
            this.$cookies.set('userInfo', this.userInfo, 3600 * 24 * 30, '/')
            window.localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
            if (this.loginType) {
              this.$router.push({
                path: '/home',
                query: {
                  username: this.userInfo.name,
                  imgUrl: this.userInfo.headPicture
                }
              })
              this.$message.success('登陆成功')
            } else {
              this.$router.push({
                path: '/chongzhi',
                query: {
                  username: this.userInfo.name,
                  imgUrl: this.userInfo.headPicture
                }
              })
              this.$message.error('账号已到期，请续费后使用！')
            }
          } else {
            this.openID = res.data.data
            this.notify.close()
            this.$notify({
              type: 'info',
              title: '提示',
              message: res.data.msg,
              duration: 3000,
            });
            this.isPhone = false
          }
        })
      },
      // 关闭扫码弹框
      close_login_container(val) {
        this.login_container = false
      },
      // 微信登陆
      getCodes() {
        this.login_container = true
        this.$nextTick(() => {
          var obj = new WxLogin({
            self_redirect: false,
            id: "login_container",
            appid: "wx3e597f1bb7732a81",
            scope: "snsapi_login",
            redirect_uri: "https://yry.idemer.com/",
            // state: "",
            style: "black",
            // href: ""
          });
        })
      },
      // 获取手机验证码
      getCode() {
        if (!this.phoneNum) {
          this.$message.error('请输入手机号')
          return
        }
        this.showCodeBtn = true
        let url = `/company/getSmsCode`
        if (false) {
          url = `pay/send`
        }
        this.$http.post(url, null, {
          params: {
            phone: this.phoneNum
          }
        }).then(res => {
          console.log(res)
          if (res.data.code != 200) {
            this.$message.error(res.data.msg)
          }
        })
        this.setInt()
      },
      // 倒计时
      setInt() {
        let setTime = setInterval(() => {
          this.codeNum -= 1
          if (this.codeNum <= 0) {
            this.showCodeBtn = false
            this.codeNum = 60
            clearInterval(setTime)
          }
        }, 1000)
      },
      // 点击选中
      currentItem(item) {
        this.editPhoneNumber = item.companyName
      },
      // 聚焦
      focus() {
        this.list = true
        this.phoneInputChange()
      },
      // 失焦
      blur() {
        this.list = false
        // if(this.options3.length<=0){
        this.editPhoneNumber = ''
      },
      // 模糊搜索
      phoneInputChange() {
        this.loading2 = true
        this.de.debounceEnd().then(res => {
          this.$http.get(`company/selectCompanyByName?companyName=${this.editPhoneNumber}`, {}, {
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.options3 = res.data.data
              this.loading2 = false
            } else {
              if (res.data.msg) {
                this.$message.error(res.data.msg)
              }
            }
          })
        })
      },
      // 注册上传头像数量限制
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制只能上传一张头像`);
      },
      // 添加医院
      addYY() {
        this.isShow = 'zcyy'
      },
      // 注册账号提交
      confirm2() {
        if (this.editPhoneNumber && this.phoneNum && this.searchKey && this.phoneCode) {
          this.$http.post(`/user/add`, {
            phone: this.phoneNum,
            name: this.editPhoneNumber,//默认医生名称就是医院名称
            smsCode: this.phoneCode,// 验证码
            username: this.phoneNum,
            headPicture: this.hospital_avatar,//用户头像
            // companyId: this.value,// 医院类型
            // roleId: this.value1,//权限
            companyInfo: {
              phone: this.phoneNum,//后台需要
              headPicture: this.hospital_image,// 医院头像
              operatingStartTime: this.startTime,
              operatingEndTime: this.endTime,
              latitude: this.center.latitude,// 坐标
              longitude: this.center.longitude,// 坐标
              address: this.searchKey,// 医院地址
              companyName: this.editPhoneNumber,// 医院名称
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.isShow = 'login'
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.warning("信息不完整,请完善信息")
        }
      },
      getHosList() {
        this.$http.get(`/company/getHeadCompany`).then(res => {
          console.log(res)
          this.options = res.data.data
        })
      },
      getPowerList() {
        this.$http.post(`/user/getRole`, {
          page: this.page
        }).then(res => {
          console.log(res)
          this.options1 = res.data.data
        })
      },
      getback2() {
        this.$router.go(-1)
      },
      choosePower(e) {
        console.log(e)
        // console.log(this.options1[e].roleName)
      },
      // 账号上传
      uploadSuccess(file) {
        this.hospital_avatar = file
        console.log(this.hospital_avatar)
      },
      // 账号图片上传删除
      handleRemove1(file, fileList) {
        this.hospital_avatar = ''
        // this.dialogImageUrlYL = file.url;
        // this.dialogVisible = true;
      },
      // 权限显示
      chooseHos(e) {
        console.log(e)
        if (e == 1) {
          this.isShowPower = true
        } else {
          this.isShowPower = false
        }
      },
      chooseHospital_type(k) {
        // console.log(k)
        console.log(this.keywords)
        // console.log(this.keywords)
      },
      // 选择医院类型
      edit() {
        console.log("上传")
      },
      getback() {
        this.$router.go(-1)
      },
      error11(file) {
        console.log(file)
      },
      // 医院图片上传
      uploadSuccess2(file, fileList) {
        this.hospital_avatar2.push(fileList)
      },
      // 医院图片删除
      handleRemove2(file, fileList) {
        this.hospital_avatar2 = fileList
        console.log(fileList)
      },
      // 医院图片预览
      uploadImg(file, fileList) {
        // console.log(file);
        this.dialogImageUrlYL2 = file.url
        this.dialogVisible2 = true
      },
      // 注册上传医院图片数量限制
      handleExceed2(files, fileList) {
        this.$message.warning(`当前限制只能上传5张医院图片`);
      },
      // 医院头像上传
      uploadSuccess3(file) {
        this.hospital_image = file
      },
      // 医院头像删除
      handleRemove3(file) {
        this.hospital_image = ''
      },
      // 注册上传医院头像数量限制
      handleExceed3(files, fileList) {
        this.$message.warning(`当前限制只能上传1张医院头像`);
      },
      // 上传错误
      handErr1(err, file, fileList) {
        this.$message.error('上传失败请重试')
      },
      //高德搜索
      initSearch() {
        let vm = this;
        let map = this.amapManager.getMap();
        AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
          var poiPicker = new PoiPicker({
            input: "search",
            placeSearchOptions: {
              map: map,
              pageSize: 10,
            },
            suggestContainer: "searchTip",
            searchResultsContainer: "searchTip",
          });

          vm.poiPicker = poiPicker;
          // 监听poi选中信息
          poiPicker.on("poiPicked", function (poiResult) {
            console.log(poiResult)
            let source = poiResult.source;
            let poi = poiResult.item;
            this.lat = poi.location.lat
            this.lng = poi.location.lng
            console.log(this.lat, this.lng)
            if (source !== "search") {
              poiPicker.searchByKeyword(poi.name);
            } else {
              poiPicker.clearSearchResults();
              vm.markers = [];
              let lng = poi.location.lng;
              let lat = poi.location.lat;
              let address = poi.cityname + poi.adname + poi.name;
              vm.center = {
                longitude: lng,
                latitude: lat
              };
              vm.markers.push([lng, lat]);
              vm.lng = lng;
              vm.lat = lat;
              vm.address = address;
              vm.searchKey = address;
            }
          });
          // console.log(address );
          // console.log(vm.lat);
        });
      },
      // 搜索
      searchByHand() {
        console.log(this.searchKey);
        if (this.searchKey != '') {
          this.poiPicker.searchByKeyword(this.searchKey);
        }
      },
      // 注册
      clickZc() {
        this.isShow = 'zczh'
      },
      changeLogin(e) {
        console.log(e)
        this.loginExpires = true
      },
      forgetPassword() {
        this.isShow = 'wjmm'
      },
      // 登陆
      Login() {
        // 表单登录的预验证
        this.$refs.loginFormRef.validate(voild => {
          console.log(voild)
          if (!voild) return this.$message.error('格式错误');
          this.LoginFirst()
        })
      },
      // 登录接口
      LoginFirst(u = '', d = '') {
        window.sessionStorage.removeItem('token')
        let url = u ? u : '/login';
        let params = d ? { params: d } : null
        let data = d ? null : this.loginForm
        this.$http.post(url, data, params).then(res => {
          console.log(res)
          if (res.data.code === 200) {
            if (this.loginExpires) {
              this.$cookies.set('token', res.data.data.token, 3600 * 24 * 30, '/')
            }
            window.sessionStorage.setItem('token', res.data.data.token)
            if (res.data.data.user.timeIsOver == 0) {
              this.loginType = true
            } else {
              this.loginType = false
            }
            this.userInfo = res.data.data.user
            console.log(this.userInfo)
            if (this.loginExpires) {
              this.$cookies.set('userInfo', this.userInfo, 3600 * 24 * 30, '/')
            }
            window.localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
            if (this.loginType) {
              this.$router.push({
                path: '/home',
                query: {
                  username: this.userInfo.name,
                  imgUrl: this.userInfo.headPicture
                }
              })
              this.$message.success('登陆成功')
            } else {
              this.$router.push({
                path: '/chongzhi',
                query: {
                  username: this.userInfo.name,
                  imgUrl: this.userInfo.headPicture
                }
              })
              this.$message.error('账号已到期，请续费后使用！')
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
      },
      // 获取用户信息接口
      getUserInfo() {
        this.$store.commit('getUserData')
        this.$http.get(`/order/homePage`, {}).then(res => {
          console.log(res)
          if (res.data.code == 200) {
          } else {
            this.$message.error("请求失败")
          }
        })
      },
      // 返回
      goLogin(str) {
        this.isShow = str
      },
      // 发送验证码
      sendCode() {
        if (this.yzmForm.phoneNum !== "") {
          let rule = /^1[3-9]\d{9}$/
          console.log(rule.test(this.yzmForm.phoneNum))
          if (rule.test(this.yzmForm.phoneNum)) {
            if (this.codeTime > 0) {
              return this.$message.warning("请勿重复操作")
            } else {
              this.getPhoneCode(this.yzmForm.phoneNum)
              this.codeTime = 60
              let timer = setInterval(() => {
                this.codeTime--;
                if (this.codeTime < 1) {
                  clearInterval(timer)
                  this.codeTime = 0
                }
              }, 1000)
            }
          } else {
            return this.$message.error('手机号码格式不正确')
          }
        } else {
          this.$message.warning('手机号码不能为空')
        }
      },
      getPhoneCode(e) {
        this.$http.post(`/smsCode/${e}`).then(res => {
          console.log(res)
          if (res.data.code === 200) {
            this.$message.success("发送成功")
          } else if (res.data.code === 401) {
            this.$message.error("无访问权限")
          } else {
            this.$message.error("请求失败")
          }
        })
      },
      // 注册提交
      submit() {
        if (this.yzmForm.phoneNum && this.yzmForm.yzm && this.yzmForm.newPsd.length > 5 && this.yzmForm.newPsdAgain.length > 5 !== "") {
          this.$http.post(`/updatePassword`, {
            password: this.yzmForm.newPsd,
            phone: this.yzmForm.phoneNum,
            code: this.yzmForm.yzm
          }).then(res => {
            if (res.data.code === 200) {
              this.$message.success("密码已设置成功")
              this.yzmForm.phoneNum = ""
              this.yzmForm.yzm = ""
              this.yzmForm.newPsd = ""
              this.yzmForm.newPsdAgain = ""
              this.isShow1 = false
              this.isShow = 'login'
            } else {
              this.$message.warning(res.data.msg)
            }
          })
        } else {
          this.$message.warning("请完善信息")
        }
      }
    }
  }
</script>

<style scoped lang="less">
  /* /deep/.el-icon-view:before {
        content: "\e764";
    } */

  .inss {
    height: 40px;
    width: 220px;
    padding-left: 3px;
    border: 1px solid #dddddd;
    outline: none;
  }

  .inssList {
    width: 100%;
    max-height: 200px;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    color: #666;
    font-size: 16px;
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .item {
    padding-left: 10px;
    height: 40px;
    width: 90%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
  }

  //  [v-cloak] {
  //      display: none;
  //  }
  .login_container {
    height: 100%;
    background: #666EE8;

  }

  .hospital_name {
    display: flex;
    align-items: center;
  }

  /* .uploadImg {
        display: flex;
        align-items: center;
        margin-top: 50px;

        img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin: 0 20px;
        }

        >div button {
            margin-bottom: 7px;
        }
    } */

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .btns {
    display: flex;
    justify-content: center;

    >button {
      margin: 0 50px;
    }
  }

  .search-box {
    display: flex;
  }

  .search-box input:focus {
    outline: none
  }

  .login_container_box {
    width: 700px;
    border-radius: 3px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;

    .box_image {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 250px;
        width: 250px;
      }
    }

    .box_login_part {
      width: 50%;
      padding: 20px 50px 20px 0;

      .box_login_part_title {
        font-size: 20px;
        margin-top: 22px;
        font-weight: bold;
        color: #000000;
      }
    }

    .form_part {
      margin-top: 25px;
    }

    .changeCheckBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      div {
        font-size: 14px;
        color: #606266;
        cursor: pointer;
      }

      div:hover {
        color: #409EFF;
      }
    }

    .btns {
      .login_btn {
        width: 100%;
        background-color: #666ee8
      }
    }

  }

  .forgetPassword_part {
    height: 350px;
    width: 700px;
    border-radius: 3px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    padding: 20px;

    .find_psd_part {
      .find_psd_title {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
      }

      .find_psd_Form {
        margin-top: 10px;

        .el-form-item {
          margin-top: 10px;
        }

        .YZM {
          .yzm_inp {
            width: 65%;
          }

          .yzm_btn {
            width: 30%;
            margin-left: 5%;
          }
        }

        .has_record {
          text-align: center;
          //    line-height: 30px;
          font-size: 14px;
          color: #409EFF;
          cursor: pointer;
          margin-top: 10px;

        }
      }
    }
  }

  .forgetPasswordZc {
    height: 600px;
    width: 1000px;
    border-radius: 3px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }

  .hospital_name {
    display: flex;
    align-items: center;
    margin: 50px 0;
  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;

    img {
      height: 400px;
      width: 400px;
      border-radius: 50%;
      margin: 0 20px;
    }

    /* >div button {
            margin-bottom: 7px;
        } */
  }


  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .btns {
    display: flex;
    justify-content: center;

    >button {
      margin: 0 50px;
    }
  }
</style>
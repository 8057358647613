<template>
  <el-container class="home_container">
    <el-aside v-show="user.timeIsOver==0" :width="isCollapse ? '64px' : '200px'">
      <!-- 侧边栏缩放 -->
      <div style="display:flex;justify-content: center;margin:20px 0 20px 0" @click="geback">
        <img src="../assets/logoyruy.png" style="width:50%;" alt="">
      </div>
      <div class="translate_button" @click="translateCollps">|||</div>
      <el-menu :uniqueOpened="true" class="el-menu-vertical-demo" background-color="#001529" text-color="#fff"
        active-text-color="#7c85be" :collapse='isCollapse' :collapse-transition='false' router
        :default-active="activePath">
        <!-- 一级菜单 -->
        <el-submenu :index="item.id + ''" v-for='item in menusData' :key='item.id'>
          <!-- 一级菜单模板区域 -->
          <template slot="title">
            <!-- 图标 -->
            <i :class="iconsObj[item.id]"></i>
            <!-- 文本 -->
            <span>{{item.title}}</span>
          </template>
          <!-- 二级菜单 -->
          <el-menu-item :index="'/' + subItem.path" v-for="subItem in item.children" :key="subItem.id"
            @click="saveActivePath('/' + subItem.path)">
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-menu"></i>
              <!-- 文本 -->
              <span>{{subItem.title}}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <el-container>
      <!-- 头部区域 -->
      <el-header v-show="user.timeIsOver==0" style="height:100px">
        <div>
          牙如意—口腔管理
        </div>
        <div class="header_img">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="font-size:16px;">医院：{{userInfo.companyName}}</div>
              <div style="font-size:16px;">手机号：{{userInfo.phone}}</div>
            </div>
            <div style="display:flex;align-items:center">
              <img :src="userInfo.headPicture" style="height:50px;width:50px;border-radius:50%;margin-right:15px"
                alt="">
              <div class="user_name_part">
                <!-- <div>{{username ? username : userInfo.username}}</div> -->
                <div>{{userInfo ? userInfo.name:username}}</div>
              </div>
              <div style="font-size: 13px;cursor: pointer;margin-left: 20px;font-size: 16px;" @click='LoginOut'>退出
              </div>
            </div>
          </el-tooltip>
        </div>

      </el-header>
      <!-- 主体部分-->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        // 左侧菜单模拟数据
        menusData: [
          {
            id: 105,
            title: '患者管理',
            path: 'guanli',
            children: [
              {
                id: 155,
                title: '患者叫号',
                path: 'Huanzhejiaohoa',
              },
              {
                id: 156,
                title: '今日患者',
                path: 'jinrihuangzhe',
              },
              {
                id: 116,
                title: '患者列表',
                path: 'guanli',
              },

            ]
          },
          {
            id: 101,
            title: '数据统计',
            path: 'shuju',
            children: [
              {
                id: 110,
                title: '患者统计',
                path: 'shuju',
              },
              {
                id: 126,
                title: '挂号统计',
                path: 'guahaotongji',
              }
            ]
          }
        ],
        iconsObj: {
          // '125': 'iconfont icon-iconfontzhizuobiaozhun023101',
          '105': 'iconfont icon-yonghu',
          '101': 'iconfont icon-tongji',
          '102': 'iconfont icon-gongwen',
          '103': 'iconfont icon-xitong'
        },
        // 左边栏初始状态
        isCollapse: false,
        // 路由初始状态
        activePath: '',
        userInfo: {},
        username: '',
        imgUrl: ''
      }
    },
    mounted() {
      this.$store.commit('getUserData')
      this.activePath = window.sessionStorage.getItem('activePath')
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      console.log(this.userInfo)
      this.username = this.$route.query.username
      this.imgUrl = this.$route.query.imgUrl
      this.getList()
    },
    computed: {
      ...mapState({
        user: state => state.userInfo
      })
    },
    methods: {
      LoginOut() {
        // 用户退出登录，清楚token记录
        window.sessionStorage.clear()
        this.$cookies.remove('token')
        this.$cookies.remove('userInfo')
        // console.log(window.localStorage.getItem("userInfo"))
        window.localStorage.removeItem('userInfo')
        // console.log(window.localStorage.getItem("userInfo"))
        this.$router.push('/login')
        this.$message.success('退出成功')
      },
      // 切换侧边栏伸缩
      translateCollps() {
        this.isCollapse = !this.isCollapse
      },
      // 保存路由状态
      saveActivePath(activePath) {
        console.log(activePath)
        window.sessionStorage.setItem('activePath', activePath)
        this.activePath = activePath
        console.log(activePath);
      },
      async getList() {
        this.$http.get(`/getHomePageByHospital`).then(res => {
          if (res.data.code === 200) {
            // 权限控制
            if (JSON.parse(window.localStorage.getItem('userInfo')).roleId == 0) {
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].id === 102 || res.data.data[i].id === 103) {
                  res.data.data.splice(i--, 1);
                }
              }
            }
            console.log(res.data.data)
            this.menusData = res.data.data
          } else {
            this.$message.error("列表加载失败请重试")
          }
        })
      },
      geback() {
        this.$router.push('/welcome')
      }
    }
  }
</script>

<style scoped lang='less'>
  .home_container {
    height: 100%;
  }

  .el-container {
    background-color: #eaedf1;
  }

  .el-aside {
    background-color: #001529;
    padding-top: 0px;

    .el-menu {
      border: none;
    }
  }

  .el-header {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #233333;
    padding: 42px 30px;
  }

  .header_img {
    /* display: flex;
        align-items: center; */
  }

  .user_name_part {
    text-align: center;
  }

  .iconfont {
    margin-right: 10px;
  }

  .translate_button {
    background-color: #485164;
    text-align: center;
    font-size: 10px;
    line-height: 24px;
    color: #FFFFFF;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
</style>
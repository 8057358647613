<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>患者列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs v-model="activeName" type="card" @tab-click="changeTabs">
        <el-tab-pane label="个人信息" name="first">
          <!-- 分割线 -->
          <div style="margin:50px 0;color:#233333;font-size:17px;font-weight:600">
            基本信息
          </div>
          <div style="padding:0 30px">
            <div style="margin:50px 0;display:flex;justify-content: space-between;">
              <div style="display:flex;align-items: center;">
                <div style="width:80px">患者姓名:</div>
                <el-input style="width:300px" v-model="name" :placeholder="Info.username"></el-input>
              </div>
              <div style="display:flex;align-items: center;">
                <div style="width:80px">ID:</div>
                <el-input style="width:300px" v-model="ID" :placeholder="Info.id" disabled></el-input>
              </div>
            </div>
            <div style="margin:50px 0;display:flex;justify-content: space-between;">
              <div style="display:flex;align-items: center;">
                <div style="width:80px">性别:</div>
                <el-input style="width:300px" v-model="gender" :placeholder="Info.gender"></el-input>
              </div>
              <div style="display:flex;align-items: center;">
                <div style="width:80px">年龄:</div>
                <el-input style="width:300px" v-model="age" :placeholder="Info.age"></el-input>
              </div>
            </div>
            <div style="margin:50px 0;display:flex;justify-content: space-between;">
              <div style="display:flex;align-items: center;">
                <div style="width:80px">电话:</div>
                <el-input style="width:300px" v-model="telPhone" :placeholder="Info.phone"></el-input>
              </div>
            </div>
            <div style="margin:50px 0;display:flex;justify-content: space-between;">
              <div style="display:flex;align-items: center;">
                <div style="width:80px">地址:</div>
                <div class="search-box">
                  <input v-model="searchKey" type="search" id="search" autocomplete="off">
                  <el-button slot="append" icon="el-icon-search" @click="searchByHand"></el-button>
                </div>
                <el-amap class="amap-box" :amap-manager="amapManager" :vid="'amap-vue'" :zoom="zoom" :plugin="plugin"
                  :center="center" :events="events" v-show="flist">
                  <!-- 标记 -->
                  <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index">
                  </el-amap-marker>
                </el-amap>
              </div>
            </div>
            <div class="tip-box" id="searchTip"></div>
            <div class="btns">
              <el-button @click="confirm" style="background-color:#686ce8;color:#FFFFFF">确认</el-button>
              <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="病历信息" name="second">
          <div style="margin:50px 0;display:flex;">
            <div>
              <!-- 时间线 -->
              <div style="overflow-y: scroll; overflow-x:hidden; width:650px; height:550px;" class="p">
                <el-timeline>
                  <el-timeline-item v-for="(item, index) in dianziRecord" :timestamp="item.orderTime" :key="index"
                    placement="top" :icon="item.icon" :color="item.color" :size="item.size">
                    <el-card>
                      <div @click="watchId(item.id, item.category)">
                        <p>预约诊疗项目：{{item.treatmentName}}</p>
                        <p>接诊医生：{{item.doctorName}}</p>
                        <p>接诊医院：{{item.companyName}}</p>
                      </div>
                    </el-card>

                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
            <div style="flex:1;padding:0 30px;">
              <div v-show="showDtail"
                style="border:1px solid #eee;border-radius:10px;padding: 20px 10px;height:530px;overflow-y: auto;"
                class="box3">
                <div style="display:flex;justify-content: space-between;align-items: center;">
                  <el-tag type="warning" style="margin-right:20px">{{this.type1}}</el-tag>
                  <div>
                    <el-button type="primary" icon="el-icon-edit" @click="outFiles">打印病历</el-button>
                  </div>
                </div>
                <div style="display:flex;margin:40px 0;align-items: center;">
                  <div style="font-size:16px;color:#233333;font-weight:600;width:65px">主诉:</div>
                  <div style="margin-left: 30px;font-size:15px;color: #b3b2b5">{{DetailInfo.tell}}
                  </div>
                </div>
                <div style="display:flex;margin:40px 0;align-items: center;">
                  <div style="font-size:16px;color:#233333;font-weight:600;width:65px">现病史:</div>
                  <div style="margin-left: 30px;font-size:15px;color: #b3b2b5">
                    {{DetailInfo.nowDisease}}</div>
                </div>
                <div style="display:flex;margin:40px 0;align-items: center;">
                  <div style="font-size:16px;color:#233333;font-weight:600;width:65px">既往史:</div>
                  <div style="margin-left: 30px;font-size:15px;color: #b3b2b5;flex:1">
                    {{DetailInfo.isBleed}}，{{DetailInfo.isHeat}}，{{DetailInfo.isHighBleed}}，{{DetailInfo.record}}
                  </div>
                </div>
                <!-- <div style="display:flex;margin:40px 0;align-items: center;">
                                    <div style="font-size:16px;color:#233333;font-weight:600;width:65px">诊断:</div>
                                    <div style="margin-left: 30px;font-size:15px;color: #b3b2b5">
                                        {{DetailInfo.diagnosis}}</div>
                                </div> -->
                <div style="display:flex;margin:40px 0;">
                  <div style="font-size:16px;color:#233333;font-weight:600;white-space:nowrap;width:65px">
                    治疗方案:</div>
                  <div style="margin-left: 30px;font-size:15px;color: #b3b2b5;">
                    {{DetailInfo.treatmentOptions}}</div>
                </div>
                <div style="display:flex;margin:40px 0;;align-items: center;">
                  <div style="font-size:16px;color:#233333;font-weight:600;width:65px">医嘱:</div>
                  <div style="margin-left: 30px;font-size:15px;color: #b3b2b5">
                    {{DetailInfo.doctorAdvice}}</div>
                </div>
                <div>
                  <!-- 新添加 -->
                  <div class="name_content">
                    <div style="margin:40px 0;align-items: center;display: flex;">
                      <div style="font-size:16px;color:#233333;font-weight:600">口腔检查:</div>
                      <div style="margin-left: 30px;font-size:15px;color: #b3b2b5">
                        {{DetailInfo | oralCavity}}</div>
                    </div>
                    <div class="box1">
                      <div class="upper-toos">
                        <div class="fontsize">上</div>

                        <div class="upper">
                          <div class="tooth">
                            <el-checkbox-group size="mini" v-model="checkedCities1" @change="change">
                              <el-checkbox-button v-for="(item, index) in range" :label="Number(item.localTeeth)"
                                :key="index">
                                {{ item.localTeeth }}
                              </el-checkbox-button>
                            </el-checkbox-group>
                          </div>
                          <div class="tooths">
                            <el-checkbox-group size="mini" v-model="checkedCities2" @change="change">
                              <el-checkbox-button v-for="(item, index) in range1" :label="Number(item.localTeeth)"
                                :key="index">
                                {{ item.localTeeth }}
                              </el-checkbox-button>
                            </el-checkbox-group>
                          </div>
                        </div>

                        <div class="Lefts" style="display: flex;position: relative;">
                          <div class="fontsizes">右</div>
                          <div class="Lefts_top"></div>
                          <div class="Lefts-bottom"></div>
                          <div class="fontsizes">左</div>
                        </div>
                        <div class="fonsLef">


                        </div>
                        <div class="upper">
                          <div class="tooth">
                            <el-checkbox-group size="mini" v-model="checkedCities3" @change="change">
                              <el-checkbox-button v-for="(item, index) in range2" :label="Number(item.localTeeth)"
                                :key="index">
                                {{ item.localTeeth }}
                              </el-checkbox-button>
                            </el-checkbox-group>
                          </div>
                          <div class="tooths">
                            <el-checkbox-group size="mini" v-model="checkedCities4" @change="change">
                              <el-checkbox-button v-for="(item, index) in range3" :label="Number(item.localTeeth)"
                                :key="index">
                                {{ item.localTeeth }}
                              </el-checkbox-button>
                            </el-checkbox-group>
                          </div>
                        </div>
                        <div class="fontsize">下</div>
                      </div>
                      <!-- <el-input type="textarea" class="inputss" :rows="3" placeholder="请输入内容"
                                                v-model="Oralexaminationeditor" @focus="Oralexaminamuns">
                                            </el-input> -->
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="cc2" style="border:1px solid #eee;border-radius:10px;padding: 20px 30px;height:530px">
                <div style='display: flex;justify-content: center;align-items: center;height: inherit;color:#d9d9dc'>
                  请点击左侧列表查看详细病历</div>
              </div>
              <div v-show='cc' style="border:1px solid #eee;border-radius:10px;padding: 20px 30px;height:530px">
                <div style='display: flex;justify-content: center;align-items: center;height: inherit;color:#d9d9dc'>
                  当前日期暂无详细病历</div>
              </div>
            </div>
          </div>
          <div class="btns">
            <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
          </div>
        </el-tab-pane>
        <!-- 口腔检查 -->
        <el-tab-pane label="口腔检查" name="third">
          <div class="p_2" style="">
            <div class="fontsize">上</div>
            <div style="    width: 100%;
                        display: grid;
                        grid-template-columns: repeat(16,calc((100% - 3.75rem) / 16));
                        grid-template-rows: repeat(2,250px)" class="thoohss">
              <div v-for="(item, index) in badImg" :key="index" class="p_5" @mouseenter="undTooth(item, index)">
                <div style="color: #FFFFFF;margin-bottom:15px">{{item.localTeeth}}</div>
                <!-- <div style="color: #FFFFFF;margin-bottom:15px">{{item.num}}</div> -->
                <img :src="item.oralCavity == null ? item.goodImg : item.badImg" class="p_1">
                <div v-show="activeIndex == index " class="toddtop">
                  <div :class="item.oralCavity == null ? '' : 'todd' ">
                    <div v-for="(item, index) in conetex" :key="index">
                      <span class="p1">{{ index+1>9?index+1:"0"+(index+1) }}</span> {{item}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fontleft">右</div>
            <div class="fontright">左</div>
            <div class="fontsize">下</div>
            <!-- 下边牙齿 -->
            <!-- <div style="display: flex; margin-top:50px ;text-align: center;" >
                            <div v-for="(item, index) in undToothData" :key='index' class="p_5" style="" @mouseenter="undTooth(item, index)">
                                <img :src="item.img" class="p_1"  :class=" buttClick == index ? 'classA' : 'classB'">
                                <div style="color: #FFFFFF;margin-top:15px;" >{{item.num}}</div>
                                <div v-show="activeIndex1 == index  && isData1.length > 0">
                                    <div v-for="(item1, index1) in isData1" :key='index1' 
                                    style="position: absolute;color:#bfbfbf;z-index:1002;font-size:15px;
                                    border-radius:10px;;height: 45px;padding:10px;
                                    box-shadow: 0px 3px 3px 0px rgba(61, 69, 190, 0.5);;width: 220px;
                                    background:;top: 20%;left: 50%;transform: translate(-20%,-50%);
                                    background:#FFFFFF">{{item1}}</div>
                                </div>
                            </div>
                        </div> -->
          </div>
          <div style="margin-top:50px;display: flex;justify-content: center;">
            <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
          </div>
        </el-tab-pane>
        <!-- 收费项目 -->
        <el-tab-pane label="收费管理" name="hsFeesList">
          <div style="margin-bottom: 20px;">
            <!-- 列表 -->
            <el-table v-loading="loading" :data="tableData2" border style="width: 100%">
              <el-table-column prop="createTime" label="创建时间">
              </el-table-column>
              <el-table-column prop="name" label="项目" width="200">
                <template slot-scope="scope">
                  <el-button @click="showcenterDialogVisible(scope.row)" type="success" size="mini">
                    查看项目</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="totalMoney" label="总金额">
              </el-table-column>
              <!-- <el-table-column prop="createDoctor" label="医生">
                            </el-table-column> -->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="danger" size="mini">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="display:flex;justify-content:center;margin-top: 20px;">
              <el-pagination background @current-change="handleCurrentChange" :current-page="current" :page-size="size"
                layout="total, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
          <!-- 返回 -->
          <div class="btns">
            <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- <div v-for="(item, index) in TopToothData" :key='index' >
                <img :src="item.img" alt="">
                <div>{{item.num}}</div>
            </div> -->
    </el-card>
    <!-- 查看弹框 -->
    <el-dialog title="收费项目列表" :visible.sync="centerDialogVisible2" width="50%" center>
      <el-table max-height="500" :data="tableData3" style="width: 100%">
        <el-table-column prop="createTime" label="创建日期">
        </el-table-column>
        <el-table-column prop="moneyNames" label="项目名称">
        </el-table-column>
        <el-table-column prop="moneyCounts" label="项目数量">
        </el-table-column>
        <el-table-column prop="moneyAmounts" label="默认项目金额">
        </el-table-column>
        <el-table-column prop="actualMoney" label="实收项目金额">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
  let amapManager = new AMapManager();
  export default {
    data() {
      let self = this;
      return {
        centerDialogVisible2: false,// 查看项目表格开关
        tableData3: [],// 查看项目表格
        loading: false,//加载状态
        size: 9,// 每页数量
        current: 1,// 当前页
        total: 0,// 总条数
        tableData2: [],// 收费列表
        checkedCities1: ['1', '3'],
        checkedCities2: [],
        checkedCities3: [],
        checkedCities4: [],
        range: [
          { value: 1, localTeeth: "8", choose: 0 },
          { value: 2, localTeeth: "7", choose: 0 },
          { value: 3, localTeeth: "6", choose: 0 },
          { value: 4, localTeeth: "5", choose: 0 },
          { value: 5, localTeeth: "4", choose: 0 },
          { value: 6, localTeeth: "3", choose: 0 },
          { value: 7, localTeeth: "2", choose: 0 },
          { value: 8, localTeeth: "1", choose: 0 },
        ],
        range1: [
          { value: 9, localTeeth: "1", choose: 1 },
          { value: 10, localTeeth: "2", choose: 1 },
          { value: 11, localTeeth: "3", choose: 1 },
          { value: 12, localTeeth: "4", choose: 1 },
          { value: 13, localTeeth: "5", choose: 1 },
          { value: 14, localTeeth: "6", choose: 1 },
          { value: 15, localTeeth: "7", choose: 1 },
          { value: 16, localTeeth: "8", choose: 1 },
        ],
        range2: [
          { value: 1, localTeeth: "8", choose: 3 },
          { value: 2, localTeeth: "7", choose: 3 },
          { value: 3, localTeeth: "6", choose: 3 },
          { value: 4, localTeeth: "5", choose: 3 },
          { value: 5, localTeeth: "4", choose: 3 },
          { value: 6, localTeeth: "3", choose: 3 },
          { value: 7, localTeeth: "2", choose: 3 },
          { value: 8, localTeeth: "1", choose: 3 },
        ],
        range3: [
          { value: 9, localTeeth: "1", choose: 4 },
          { value: 10, localTeeth: "2", choose: 4 },
          { value: 11, localTeeth: "3", choose: 4 },
          { value: 12, localTeeth: "4", choose: 4 },
          { value: 13, localTeeth: "5", choose: 4 },
          { value: 14, localTeeth: "6", choose: 4 },
          { value: 15, localTeeth: "7", choose: 4 },
          { value: 16, localTeeth: "8", choose: 4 },
        ],
        activeName: 'first',
        loading: false,
        buttonClick: '',
        buttClick: '',
        classA: "",
        classB: '',
        cc: false,
        num: 3,
        cc2: true,
        biss: false, /* 图片判断 */
        conetex: [],
        name: '',
        ID: '',
        gender: '',
        age: '',
        telPhone: '',
        showDtail: false,
        // showDtail: true,
        type: [
          {
            tips: '初诊'
          },
          {
            tips: '复诊'
          },
          {
            tips: '快速挂号'
          }
        ],
        TopToothData: [
          {
            'num': 1,
            'img': require('../../../assets/tooth.png'),
            'imgs': require('../../../assets/tooths.png'),
            localTeeth: ''
          },
          {
            'num': 2,
            'img': require('../../../assets/tooth2.png'),
            'imgs': require('../../../assets/tooths.png'),
            localTeeth: '2'
          },
          {
            'num': 3,
            'img': require('../../../assets/tooth3.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 4,
            'img': require('../../../assets/tooth4.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 5,
            'img': require('../../../assets/tooth5.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 6,
            'img': require('../../../assets/tooth6.png'),
            'imgs': require('../../../assets/tooths.png'),

          },
          {
            'num': 7,
            'img': require('../../../assets/tooth7.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 8,
            'img': require('../../../assets/tooth8.png'),
            'imgs': require('../../../assets/tooths.png'),
            localTeeth: 8
          },
          {
            'num': 9,
            'img': require('../../../assets/tooth9.png'),
            'imgs': require('../../../assets/tooths.png'),
            localTeeth: 9

          },
          {
            'num': 10,
            'img': require('../../../assets/tooth10.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 11,
            'img': require('../../../assets/tooth11.png'),
            'imgs': require('../../../assets/tooths.png'),
            localTeeth: '11'
          },
          {
            'num': 12,
            'img': require('../../../assets/tooth12.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 13,
            'img': require('../../../assets/tooth13.png'),
            'imgs': require('../../../assets/tooths.png'),

          },
          {
            'num': 14,
            'img': require('../../../assets/tooth14.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
          {
            'num': 15,
            'img': require('../../../assets/tooth15.png'),
            'imgs': require('../../../assets/tooths.png'),

          },
          {
            'num': 16,
            'img': require('../../../assets/tooth16.png'),
            'imgs': require('../../../assets/tooths.png'),
          },
        ],
        buskosdd: [],
        js: [],
        badImg: [],
        undToothData: [
          {
            'num': 8,
            'img': require('../../../assets/undTooth.png')
          },
          {
            'num': 7,
            'img': require('../../../assets/undTooth2.png')
          },
          {
            'num': 6,
            'img': require('../../../assets/undTooth3.png')
          },
          {
            'num': 5,
            'img': require('../../../assets/undTooth4.png')
          },
          {
            'num': 4,
            'img': require('../../../assets/undTooth5.png')
          },
          {
            'num': 3,
            'img': require('../../../assets/undTooth6.png')
          },
          {
            'num': 2,
            'img': require('../../../assets/undTooth7.png')
          },
          {
            'num': 1,
            'img': require('../../../assets/undTooth8.png')
          },
          {
            'num': 1,
            'img': require('../../../assets/undTooth9.png')
          },
          {
            'num': 2,
            'img': require('../../../assets/undTooth10.png')
          },
          {
            'num': 3,
            'img': require('../../../assets/undTooth11.png')
          },
          {
            'num': 4,
            'img': require('../../../assets/undTooth12.png')
          },
          {
            'num': 5,
            'img': require('../../../assets/undTooth13.png')
          },
          {
            'num': 6,
            'img': require('../../../assets/undTooth14.png')
          },
          {
            'num': 7,
            'img': require('../../../assets/undTooth15.png')
          },
          {
            'num': 8,
            'img': require('../../../assets/undTooth16.png')
          },
        ],
        //   chooseTypes: [],
        chooseType: [] /* 多选 */,
        page: 1,
        dianziRecord: [],
        id: 0,
        type1: '',
        DetailInfo: {},
        isData: [],
        isData1: [],
        cc: false,
        cc1: false,
        activeIndex: "",
        activeIndex1: -1,
        oralCavity: '',  /* bhv */
        Info: {},
        searchKey: '',  /* 地图 */
        zoom: 12, //缩放比例
        flist: false,
        markers: [],
        amapManager,
        searchOption: {
          city: "全国",
          citylimit: true,
        },
        center: [0, 0],
        label: {
          content: '', offset: [-40, -40],
          markers: [],
        },
        // 一些事件插件
        events: {
          init() {
            lazyAMapApiLoaderInstance.load().then(() => {
              self.initSearch();
            });
          },
          // 点击获取地址的数据
          click(e) {
            // console.log(e)
            self.markers = [];
            let { lng, lat } = e.lnglat;
            self.lng = lng;
            self.lat = lat;
            self.center = [lng, lat];
            self.markers.push([lng, lat]);
            console.log(self.center);
            // 这里通过高德 SDK 完成。
            let geocoder = new AMap.Geocoder({
              radius: 1000,
              extensions: "all",
            });
            geocoder.getAddress([lng, lat], function (status, result) {
              if (status === "complete" && result.info === "OK") {
                if (result && result.regeocode) {
                  self.address = result.regeocode.formattedAddress;
                  self.searchKey = result.regeocode.formattedAddress;
                  self.$nextTick();
                }
              }
            });
          },
        },
        //   一些工具插件
        plugin: [
          {
            // 定位
            pName: "Geolocation",
            events: {
              init(o) {
                // o是高德地图定位插件实例
                o.getCurrentPosition((status, result) => {
                  // if ( result.position.lng == this.HosInfo.longitude) {
                  //   console.log(111);
                  // }else{
                  //   self.lng = result.position.lng;
                  //   // 设置维度
                  //   self.lat = result.position.lat;
                  //   // 设置坐标
                  //   self.center = [self.lng, self.lat];
                  //   // self.markers.push([self.lng, self.lat]);
                  //   // load
                  //   self.loaded = true;
                  //   // 页面渲染好后
                  //   self.$nextTick();
                  // }
                  if (result && result.position) {
                    // 设置经度
                    self.lng = result.position.lng;
                    // 设置维度
                    self.lat = result.position.lat;
                    // 设置坐标
                    self.center = [self.lng, self.lat];
                    // self.markers.push([self.lng, self.lat]);
                    // load
                    self.loaded = true;
                    // 页面渲染好后
                    self.$nextTick();
                  }
                });
                console.log(self.markers);
              },
            },
          },

        ],

      }
    },
    created() {
      console.log(this.$route.query.Info)
      this.Info = this.$route.query.Info
      console.log(this.Info, 100);
      this.name = this.Info.username,
        this.gender = this.Info.gender,
        this.age = this.Info.age
      this.telPhone = this.Info.phone
      this.searchKey = this.Info.memberAddres
      this.getChoose()
      this.getList()
    },
    computed: {

    },
    filters: {
      oralCavity(item) {
        console.log(item)
        if (item.localTeeth) {
          return item.localTeeth.length ? item.localTeeth[0].oralCavity : ''
        } else {
          return ''
        }
      }
    },
    methods: {
      // 删除项目流水单
      handleClick(item) {
        this.$http.post(`/fees/delete`, null, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          params: {
            id: item.id
          }
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.msg) {
              this.$message.success(res.data.msg)
            }
            this.getList()
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
        }).catch(err => {
          console.log(err)
        })
      },
      // 查看弹框打开
      showcenterDialogVisible(list) {
        console.log(list)
        this.tableData3 = list.hsFeesList
        this.centerDialogVisible2 = true
      },
      // 获取列表数据
      getList() {
        this.loading = true
        this.$http.post(`/fees/getList`, null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {
            patientId: this.Info.id,// 患者ID
            size: this.size,
            current: this.current,
          }
        }).then(res => {
          console.log(111, res)
          if (res.data.code == 200) {
            this.tableData2 = res.data.data.records
            this.total = res.data.data.total
            this.loading = false
            // if (res.data.msg) {
            //     this.$message.success(res.data.msg)
            // }
          } else {
            this.loading = false
            if (res.data.msg) {
              this.$message.success(res.data.msg)
            }
          }
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      },
      // 选择页码
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.current = val
        this.getList()
      },
      change(e) {
        console.log(e);
        this.localTeh = e;
        // console.log(this.localTeh);
      },
      //高德搜索
      initSearch() {
        let vm = this;
        let map = this.amapManager.getMap();
        AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
          var poiPicker = new PoiPicker({
            input: "search",
            placeSearchOptions: {
              map: map,
              pageSize: 10,
            },
            suggestContainer: "searchTip",
            searchResultsContainer: "searchTip",
          });

          vm.poiPicker = poiPicker;
          // 监听poi选中信息
          poiPicker.on("poiPicked", function (poiResult) {
            console.log(poiResult)
            let source = poiResult.source;
            let poi = poiResult.item;
            this.lat = poi.location.lat
            this.lng = poi.location.lng
            //   console.log(this.lat, this.lng)
            if (source !== "search") {
              poiPicker.searchByKeyword(poi.name);
            } else {
              poiPicker.clearSearchResults();
              vm.markers = [];
              let lng = poi.location.lng;
              let lat = poi.location.lat;
              let address = poi.cityname + poi.adname + poi.name;
              vm.center = [lng, lat];
              vm.markers.push([lng, lat]);
              vm.lng = lng;
              vm.lat = lat;
              vm.address = address;
              vm.searchKey = address;
            }
          });
          // console.log(address );
          // console.log(vm.lat);
        });
      },
      searchByHand() {
        console.log(this.searchKey);
        if (this.searchKey != '') {
          this.poiPicker.searchByKeyword(this.searchKey);
        }
      },
      // 修改信息接口
      editInfo() {
        this.$http.get('/api/hospital/hos_patient/update', {
          params: {
            patientId: this.patientId,
            hospitalId: 5,
            telephone: this.telPhone
          },
          headers: {
            hospitalId: 5,
          }
        }).then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            this.$message.success(res.data.message)
            this.telPhone = ''
          }
        })
      },
      changeTabs(tab) {
        // console.log(tab)
        if (tab.name === 'second') {
          console.log('病历信息')
          this.getRecord()
          this.showDtail = false
        } else if (tab.name === 'third') {
          console.log('口腔检查')
          this.showDtail = false
          this.cc2 = true
          // this.getChoose()

        } else {
          this.showDtail = false
          this.cc2 = true
        }
      },
      getRecord() {
        this.$http.post(`/order/getOrderByPatientId`, {
          patientId: this.Info.id,
          page: this.page
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.dianziRecord = res.data.data
            if (this.dianziRecord.length == 0) {
              this.cc2 = false
              this.$message('暂无列表数据')
            } else {
              this.cc2 = true
            }
            for (let i = 0; i < this.dianziRecord.length; i++) {
              switch (this.dianziRecord[i].category) {
                case "0":
                  this.dianziRecord[i].category = '快速挂号'
                  break;
                case "1":
                  this.dianziRecord[i].category = "初诊"
                  break;
                case "2":
                  this.dianziRecord[i].category = '复诊'
                  break;
                case "3":
                  this.dianziRecord[i].category = "老人挂号";
                  break;
                default: this.dianziRecord[i].category = '预约挂号'
                  break;
              }
            }
          }
        })
      },
      confirm() {
        console.log(this.Info.id);
        let sex = "";
        if (this.gender === "男") {
          sex = "1"
        } else if (this.gender === "女") {
          sex = "2"
        } else {
          sex = ""
        }
        let obj = {
          username: this.name,
          gender: sex,
          phone: this.telPhone,
          age: this.age,
          id: this.Info.id,
          memberAddres: this.searchKey
        }
        this.$http.put(`/member/updateById`, obj).then(res => {
          if (res.data.code == 200) {
            this.$message.success("编辑成功")
            this.name = "",
              this.gender = "",
              this.telPhone = "",
              this.age = "",
              this.searchKey = ''
          } else {
            this.$message.error(res.data.msg)
          }
        })
        this.$router.go(-1)
      },
      getback() {
        this.$router.go(-1)
      },
      outFiles() {
        console.log("导出文件")
      },
      watchId(e, c) {
        console.log(e, c)
        this.type1 = c
        this.$http.get(`/medical/getMedicalByOrderId/${e}`).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.showDtail = true
            this.cc2 = false
            this.DetailInfo = res.data.data
            this.chooseType = res.data.data.localTeeth
            console.log(this.chooseType)
            this.checkedCities1 = []
            this.checkedCities2 = []
            this.checkedCities3 = []
            this.checkedCities4 = []
            for (let i = 0; i < this.chooseType.length; i++) {
              switch (Number(this.chooseType[i].choose)) {
                case 0:
                  console.log(1, Number(this.chooseType[i].choose))
                  this.checkedCities1.push(this.chooseType[i].localTeeth)
                  break;
                case 1:
                  console.log(2)
                  this.checkedCities2.push(this.chooseType[i].localTeeth)
                  break;
                case 3:
                  console.log(3)
                  this.checkedCities3.push(this.chooseType[i].localTeeth)
                  break;
                case 4:
                  console.log(4)
                  this.checkedCities4.push(this.chooseType[i].localTeeth)
                  break;
              }
            }

          } else {
            this.cc = true
            this.cc2 = false
            this.showDtail = false
            this.$message('所选日期暂无病历');
          }
        })

      },
      getChooseTooth(e) {
        this.$http.post(`/medical/selecMedical`, {
          patientId: this.Info.id,
          choose: 0,
          localTeeth: e
        }).then(res => {
          console.log(res, 123)
          if (res.data.code == 200) {
            this.isData = res.data.data
            console.log(this.isData)

          }
          for (let i = 0; i < this.isData.length; i++) {
            console.log(this.isData[i].diagnosis)
              ; /* 牙齿检查内容 */
          }
        })
      },
      // 有问题的牙齿
      getChoose() {
        console.log(this.Info.id);
        this.loading = true
        this.$http.post(`/medical/selectBadTooth`, {
          patientId: this.Info.id,
        }).then(res => {
          console.log(res)
          this.badImg = res.data.data
          console.log(this.badImg);
        })
      },
      getChooseundTooth(e) {
        console.log(e);
        console.log(this.Info.id);
        this.$http.post(`/medical/selecMedical`, {
          patientId: this.Info.id,
          choose: 1,
          localTeeth: e
        }).then(res => {
          console.log(res, 123)
          if (res.data.code == 200) {
            this.isData1 = res.data.data
            // this.cc1 = true
          } else {
            // this.cc1 = false
          }
        })
      },
      topTooth(e, i) {
        console.log(i);
        // console.log(e, i)
        this.buttonClick = i
        // this.activeIndex = i
        if (e.num) {
          this.getChooseTooth(e.num)
          this.getChoose(e.num)
        }
      },
      undTooth(e, i) {
        console.log(e, 1)
        if (e.oralCavity == null) {

        } else {
          this.conetex = e.oralCavity.split('|')
          this.activeIndex = i
        }
      },

    }
  }
</script>

<style scoped lang="less">
  .box3::-webkit-scrollbar {
    display: none;
  }

  .box3::-webkit-scrollbar {
    width: 0 !important
  }

  .name_content {
    /* display: flex; */
    /* align-items: center; */
    /* padding: 5px 15px; */
  }

  .name_content_tips {
    margin-right: 20px;
  }

  .box1 {
    padding: 3px;
    width: 99%;
    /* height: 300px; */
    border: 1px solid#DCDFE6;
  }

  .fontsize {
    color: #232323 !important;
    font-size: 16px;
    text-align: center;
  }

  .upper {
    margin-top: 15px;
    color: #b3b0b7;
    display: flex;
    justify-content: space-around;
  }

  .Lefts-bottom {
    position: absolute;
    right: 41%;
    width: 130px;
    height: 2px;
    background-color: #dcdfe6;
    transform: rotate(90deg);
    margin: auto;
  }

  .tooth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .tooths {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 25px;
  }

  .Lefts {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Lefts_top {
    width: 600px;
    height: 1px;
    background-color: #dcdfe6;
    margin: auto;
  }


  .thoohss {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .btns {
    display: flex;
    justify-content: center;

    >button {
      margin: 0 30px;
    }
  }

  .p::-webkit-scrollbar {
    display: none;
  }

  .p_1 {
    height: 130px;

  }

  .p_2 {
    padding: 100px 0;
    margin-top: 50px;
    max-width: 1540px;
    min-width: 500px;
    background: #666de8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .p_5 {
    width: calc((100% - 60px) / 16);
    text-align: center;
    margin-top: 20px;
  }

  .p1 {
    color: #524e4e;
  }

  .toddtop {
    display: flex;
    justify-content: space-between;
  }

  .todd {
    top: 9%;
    position: absolute;
    color: #bfbfbf;
    z-index: 1002;
    font-size: 12px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 3px 3px 0px rgba(61, 69, 190, 0.5);
    width: 220px;
    background: #FFFFFF;
    text-align: initial;
  }

  .todd:nth-child(2) {
    margin-top: 30px;
  }

  .fontsize {
    color: #FFFFFF;
    font-size: 16px;
    margin: auto;
  }

  .fontleft {
    color: #FFFFFF;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 60px;
  }

  .fontright {
    color: #FFFFFF;
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 60px;
  }

  .search-box {
    display: flex;
  }

  .search-box input {
    border: 1px solid #DCDFE6;
    width: 400px;
  }

  .search-box button {
    background: #DCDFE6;
    border: 1px solid #DCDFE6;
  }

  .upper {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>患者列表</el-breadcrumb-item>
      <el-breadcrumb-item>挂号</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-col :span="6">
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="width: 120px; font-weight: bold">医生名称:</div>
          <el-input placeholder="请输入" v-model="doctorname" style="width: 260px"></el-input>
        </div>
      </el-col>
      <el-table v-loading="loading" ref="multipleTable" :data="Info" tooltip-effect="dark" style="width: 100%"
        @selection-change="chooseType">
        <el-table-column label="医生名称" prop="name">
          <!-- <template #default="scope">{{ scope.row.date }}</template> -->
        </el-table-column>
        <el-table-column label="医生头像" prop="headPicture">
          <template slot-scope="scope">
            <img :src="scope.row.headPicture" style="height: 80px; width: 80px; border-radius: 50%" />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="positions" label="医生职称"> </el-table-column>
        <el-table-column prop="companyName" label="所属医院"> </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="watchDetail(scope.row)" size="mini">挂号</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!-- 诊疗项目 -->
      <el-dialog v-if="CaseDialog" title="诊疗项目" :visible.sync="CaseDialog" width="600px">
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in DiagnosisInfo" :label="item" :key="item.id">
            <div class="d-f m-l-30 a-c">
              <!-- <img class="h-w-62 m-r-39" :src="item.img"> -->
              <div class="f-w">{{ item.title }}</div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
        <el-button type="primary" @click="next" class="btn">挂号</el-button>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import { parseTime } from "../../../utils/index.js";
  export default {
    data() {
      return {
        doctorname: "",// 输入医生姓名
        currentdoctorName: '',// 选中挂号医生姓名
        currentdoctorId: '',// 选中挂号医生ID
        Info: [],
        page: 1,
        limit: 10,
        total: 0,
        loading: false,
        CaseDialog: false,
        idss: '',  /* 类型 */
        checkedCities: [],
        DiagnosisInfo: [] /* 诊疗项目 */,
        doctorId: '',
        pag: {}, /*获取患者实列 */
      };
    },
    created() {
      this.doctorList();
      console.log(this.$route.query.Info);
      this.pag = this.$route.query.Info;
    },
    computed: {

    },
    methods: {
      next() {
        let arr = []
        for (let i = 0; i < this.checkedCities.length; i++) {
          arr.push(this.checkedCities[i].title)
        }
        if (arr.length <= 0) {
          this.$message.error('请选择诊疗项目')
          return
        }
        this.$http
          .post("/order/createOrder", {
            treatmentName: arr.join(','),
            patientId: this.pag.id,
            patientName: this.pag.username,
            doctorId: this.currentdoctorId,
            doctorName: this.currentdoctorName,
            orderStatus: '1',
            category: '1',
            orderTime: parseTime(new Date().getTime(), "{y}-{m}-{d}"),
          })
          .then((res => {
            if (res.data.code == 200) {
              this.$message.success('挂号成功')
              this.CaseDialog = false
              this.$router.go(0)
            } else {
              this.$message.error(res.data.msg)
              this.$router.go(-1)
            }
          }))

        // this.$store.commit('savePath',arr);  
        // console.log(this.$store.state.pathName);
      },
      handleCheckedCitiesChange(e) {
        console.log(this.checkedCities)
      },
      watchDetail(item) {
        console.log(item.id);
        this.currentdoctorName = item.name;
        this.currentdoctorId = item.id;
        (this.CaseDialog = true),
          this.$http
            .post("/doctor/getProjectById", {
              id: item.id,
            })
            .then((res) => {
              console.log(res.data.data);
              this.DiagnosisInfo = res.data.data;
            });
      },
      chooseType(val) {
        console.log(val);
        // this.multipleSelection = val
        // console.log(this.multipleSelection)
      },
      doctorList() {
        this.loading = true;
        this.$http
          .post("/doctor/selectDoctor", {
            page: this.page,
            limit: this.limit,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code === 200) {
              this.Info = res.data.data;
              this.total = res.data.count;
              this.loading = false;
            }
          });
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize;
        console.log(this.limit);
        this.doctorList();
      },
      handleCurrentChange(newPage) {
        this.page = newPage;
        console.log(this.page);
        this.doctorList();
      },
    },
  };
</script>

<style scoped lang="less">
  .d-f {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .m-8 {
    width: 50px;
    margin: 0 0 20rpx 0;
  }

  .f-w {
    margin-top: 10px;
  }

  .btn {
    margin-top: 45px;
    margin-left: 39%;
  }
</style>
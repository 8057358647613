<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>收费管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮 -->
      <el-row>
        <el-button @click="dialogVisible('add')" icon="el-icon-plus" type="primary">添加收费项目</el-button>
      </el-row>
      <!-- 表格 -->
      <template>
        <el-table v-loading="loading" :data="tableData" border style="width: 100%">
          <el-table-column fixed prop="createTime" label="创建日期" width="200">
          </el-table-column>
          <el-table-column prop="moneyNames" label="项目名称" width="300">
          </el-table-column>
          <el-table-column prop="moneyAmounts" label="金额" width="150">
            <template slot-scope="scope">
              <div>
                {{scope.row.moneyAmounts?scope.row.moneyAmounts:0}}
              </div>
            </template>
          </el-table-column>
          <!-- 锁定金额 -->
          <el-table-column prop="isLock" label="锁定金额状态" width="150">
            <template slot-scope="scope">
              <div>
                <el-button size="mini" @click="isLock(scope.row)" :type="scope.row.isLock==1?'warning':'success'">
                  {{scope.row.isLock==1?'锁定':'未锁定'}}
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="dialogVisible(scope.row)" type="info" size="mini">编辑</el-button>
              <el-button @click="handleClick(scope.row)" type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <div style="display:flex;justify-content:center;margin-top: 20px;">
        <el-pagination background @current-change="handleCurrentChange" :current-page="current" :page-size="size"
          layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <!-- 添加项目弹出层 -->
      <el-dialog @closed="closed" :title="dialogTitle" :visible.sync="centerDialogVisible" width="50%" center>
        <div>
          <el-form :rules="rules" ref="addform" :model="form" label-width="100px">
            <!-- 选择项目 -->
            <el-form-item v-if="currentType=='add'" label="选择项目" prop="projectName">
              <customForm @update="update" :value="form.projectName" ref="customForm" :options="options"></customForm>
            </el-form-item>
            <!-- 编辑 一级目录 -->
            <el-form-item v-if="currentType=='compile'" prop="router1" label="一级目录">
              <el-input class="no-number" size="medium" v-model="form.projectName2">
              </el-input>
            </el-form-item>
            <!-- 二级目录 -->
            <el-form-item v-if="currentType=='compile'" prop="router2" label="二级目录">
              <el-input class="no-number" size="medium" v-model="form.projectName3">
              </el-input>
            </el-form-item>
            <!-- 项目金额 -->
            <el-form-item prop="money" label="项目金额">
              <el-input id="money" class="no-number" size="medium" type="number" v-model="form.money">
              </el-input>
            </el-form-item>
            <div style="display: flex;justify-content: space-evenly;">
              <el-button type="primary" @click="currentSubmit">提交</el-button>
              <el-button @click="resetDialogVisible">取消</el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  import customForm from './component/index.vue';
  import { Debounce } from '../../../utils/index'
  export default {
    components: { customForm },
    data() {
      var nameValidator = (rule, value, callback) => {
        console.log(rule, value)
        if (this.form.value3) {
          callback()
        } else {
          callback(new Error('请选择医生'))
        }
      }
      return {
        de: new Debounce(100),
        userInfo: '',// 个人信息
        options: [],
        loading: false,//加载状态
        size: 9,// 每页数量
        current: 1,// 当前页
        total: 0,// 总条数
        currentType: '',// 弹出层类型
        rules: {//验证规则
          projectName: [// 项目名称
            { required: true, message: '请选择项目', trigger: 'blur' },
            { required: true, min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
          ],
          money: [// 金额
            { required: false, message: '请输入项目金额', trigger: 'change' }
          ],
          doctor: [// 医生
            // { required: true, message: '请选择医生', trigger: 'blur' },
            { required: true, validator: nameValidator, trigger: 'change' }
          ],
        },
        form: {// 表单数据
          projectName: '',//创建项目名称
          projectName2: '',// 一级项目名称
          projectName3: "",//二级项目名称
          money: '',// 项目金额
          id: '',
          id2: '',
          isLock: '',// 锁定金额
        },
        centerDialogVisible: false,// 添加弹出层
        currentPage4: 4,// 当前页
        tableData: [],// 表格数据
      }
    },
    created() {
      this.fetch()
      this.getList()
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    },
    computed: {
      ...mapState(["pathName", "DbSource"]),
      dialogTitle() {
        if (this.currentType == 'add') {
          return '添加项目'
        } else if (this.currentType == 'compile') {
          return '编辑项目'
        }
      },
    },
    methods: {
      // 重置表单验证
      clearValidate() {
        if (this.$refs['addform'])
          this.$refs['addform'].clearValidate('projectName')
      },
      // 锁定金额
      isLock(item) {
        // 延迟处理100ms
        this.de.debounceEnd().then(() => {
          if (item.isLock == 1) {
            item.isLock = '0'
          } else {
            item.isLock = '1'
          }
          this.$nextTick(() => {
            this.upisLock(item)
          })
        })
      },
      // 改变锁定金额状态
      upisLock(item) {
        this.$http.post(`/fees/lock`, null, {
          params: {
            id: item.id,
            isLock: item.isLock
          }
        }).then(res => {
          // console.log(res)
          if (res.data.msg) {
            this.$message.success(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      update(str) {
        this.form.projectName = str;
      },
      // 获取项目列表
      fetch() {
        this.$http.post(`/fees/getTree`).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.options = res.data.data;
          }
        });
      },
      ...mapMutations(['savePath', "saveDbSource"]),
      // 删除
      handleClick(row) {
        console.log(row, '删除');
        this.$http.post(`/fees/remove`, null, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          params: {
            id: row.id
          }
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.msg) {
              this.$message.success(res.data.msg)
            }
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
          this.getList()
        })
      },
      // 获取列表数据
      getList() {
        this.loading = true
        this.$http.post(`/fees/list`, null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {// 后台规定params表单提交
            size: this.size,
            current: this.current,
            // createDoctor: this.form.value3,// 医生ID
          }
        }).then(res => {
          this.loading = false
          console.log(res)
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.total = res.data.data.total
          } else {
            if (res.data.msg) {
              this.$message.error()
            }
          }
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      },
      //打开弹出层
      dialogVisible(type) {
        console.log(type)
        if (type == 'add') {// 添加模式
          this.currentType = 'add'
        } else {// 编辑模式
          let arr = []
          arr = type.moneyNames.split('/')
          this.currentType = 'compile'
          this.form.projectName2 = arr[0] || ''
          this.form.projectName3 = arr[1] || ''
          console.log(this.form.projectName2)
          this.form.money = type.moneyAmounts
          this.form.id = type.oneId
          this.form.id2 = type.twoId
        }
        this.centerDialogVisible = true
      },
      // 关闭弹出层
      resetDialogVisible() {
        this.$refs['addform'].resetFields()
        this.centerDialogVisible = false
        this.form.value3 = ''
        console.log('关闭弹出层')
        this.form.projectName = ''
        this.form.money = ''
        // console.log(this.$refs['customForm'])
        if (this.$refs['customForm']) {
          this.$refs['customForm'].value1 = ''
          this.$refs['customForm'].value = ''
        }
      },
      closed() {
        this.$refs['addform'].resetFields()
        this.form.value3 = ''
      },
      // 选择页码
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.current = val
        this.getList()
      },
      // 提交按钮
      currentSubmit() {
        if (this.currentType == 'add') {
          this.onSubmit()
        } else if (this.currentType == 'compile') {
          this.compileSubmit()
        }
      },
      // 创建项目提交
      onSubmit() {
        this.$refs['addform'].validate(res => {
          if (res) {// 验证成功
            // console.log(this.$refs['customForm'].routeItem1.id)
            // console.log(this.$refs['customForm'].routeItem2.id)
            // return
            this.$http.post(`/fees/insert`, null, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              params: {
                moneyAmounts: this.form.money || '0',// 金额
                oneId: this.$refs['customForm'].routeItem1.id,
                twoId: this.$refs['customForm'].routeItem2.id,
                moneyNames: `${this.$refs['customForm'].routeItem1.moneyNames}_${this.$refs['customForm'].routeItem2.moneyNames}`,// 名称
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 200) {
                if (res.data.msg) {
                  this.$message.success(res.data.msg)
                }
                this.centerDialogVisible = false
                this.resetDialogVisible()
              } else if (res.data.code == 400) {
                if (res.data.msg) {
                  this.$message.error(res.data.msg)
                }
              }
              else {
                if (res.data.msg) {
                  this.$message.error(res.data.msg)
                }
              }
              this.getList()
            }).catch(err => {
              console.log(err)
            })
            console.log('创建');
          }
        })
      },
      // 编辑项目提交
      compileSubmit() {
        if (!this.form.projectName2) {
          this.$message.error('一级目录不能为空')
          return
        } else if (!this.form.projectName3) {
          this.$message.error('一级目录不能为空')
          return
        }
        console.log('编辑项目')
        let hsFees = [
          {
            id: this.form.id,//一级目录ID
            moneyNames: this.form.projectName2,// 一级目录名称
          }, {
            id: this.form.id2,//二级目录ID
            moneyNames: this.form.projectName3,// 二级目录名称
            moneyAmounts: this.form.money,// 金额
          }
        ]
        this.$http.post(`/fees/update`, {
          hsFees
        }
        ).then(res => {
          if (res.data.code == 200) {
            if (res.data.msg) {
              this.fetch()
              this.$message.success(res.data.msg)
            }
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
          this.getList()
        }).catch(err => {
          console.log(err)
        })
        this.centerDialogVisible = false
      },
    },
  };
</script>

<style scoped>
  /deep/ .no-number.el-input--medium .el-input__inner {
    width: 50%;
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>快捷短语</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-button type="primary" @click="addList"> +添加</el-button>
      <el-button type="primary" @click="delesList"> 删除</el-button>
      <div v-if="phas">
        <el-cascader
          v-model="value"
          :options="options"
          @change="handleChange"
          ref="demoCascader"
        >
        </el-cascader>
        <input
          class="inputss"
          type="text"
          placeholder="请输入内容"
          v-model="Shortcut"
        />
        <el-button type="primary" class="btn_1" @click="placbtn"
          >确定</el-button
        >
      </div>
      <el-divider></el-divider>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="序号" width="180">
        </el-table-column>
        <el-table-column prop="createTime" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="oneCatalogue" label="一级路由" width="180">
        </el-table-column>
        <el-table-column prop="twoCatalogue" label="二级路由" width="180"></el-table-column>
        <el-table-column prop="phrase" label="内容" > </el-table-column>
        <el-table-column  label="操作" width="180"> 
             <template slot-scope="scope">
        <el-button @click="delecClick(scope.row)" type="text" size="small">删除</el-button>
      </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

 <script>
export default {
  data() {
    return {
      phas: false,
      Shortcut: "",
      value: [],
      onelogue: "",
      twologue: "",
      options: [
        {
          value: "1",
          label: "患者管理",
          children: [
            {
              value: "1",
              label: "主诉",
            },
            {
              value: "2",
              label: "现病史",
            },
            {
              value: "3",
              label: "口腔检查",
            },
            {
              value: "4",
              label: "治疗方案",
            },
            {
              value: "5",
              label: "医嘱",
            },
          ],
        },
        {
          value: "收费管理",
          label: "收费管理",
          children: [
            {
              value: "2",
              label: "1111",
            },
          ],
        },
      ],
      tableData: []
    };
  },
  created() {
    this.InfoList()
  },
  computed: {},
  methods: {
    delesList(){
        //  for (let  i = 0; i < 5; i++) {
        //  console.log(i,12);
        //  setTimeout(function(){
        //    console.log(i);
        //  })}

          for (var  i = 0; i < 5; i++) {
             console.log(i,12);
             setTimeout(function(){
           console.log(i);
         },2)}
    },
    delecClick(e){
   let  id  = e.id
   console.log(id);
      this.$http
        .post(`/phrase/delete?id=` + id)
        .then((res) => {
           this.$message.success(res.data.data)
           this.$router.go(0);
        });
    },
    InfoList(){
       this.$http
        .post(`/phrase/select`,{
          id:1
        })
        .then((res) => {
          console.log(res,40);
           for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].oneCatalogue === "1") {
                res.data.data[i].oneCatalogue = "患者管理";
              }  else {
                res.data.data[i].oneCatalogue = "收费管理";
              }
              if (res.data.data[i].twoCatalogue === "1") {
                res.data.data[i].twoCatalogue = "主诉";
              }else if(res.data.data[i].twoCatalogue === "2"){
                  res.data.data[i].twoCatalogue = "现病史";
              }else if(res.data.data[i].twoCatalogue === "3"){
                  res.data.data[i].twoCatalogue = "口腔检查";
              }else if(res.data.data[i].twoCatalogue === "4"){
                  res.data.data[i].twoCatalogue = "治疗方案";
              }else{
                res.data.data[i].twoCatalogue = "医嘱";
              }
            }
          this.tableData = res.data.data
        });
    },
    addList() {
      this.phas = true;
    },
    placbtn() {
      // this.phas = false;
      if ( this.twologue == '' && this.Shortcut == '') {
          this.$message.warning("请填写内容");
      }else{
         this.$http
        .post(`/phrase/insert`, {
          oneCatalogue: this.onelogue,
          twoCatalogue: this.twologue,
          phrase: this.Shortcut,
        })
        .then((res) => {
           this.$message.success("添加成功")
           this.$router.go(0);
        });
      }
     
    },
    handleChange(value) {
      this.onelogue = value[0];
      this.twologue = value[1];
    },
  },
};
</script>

<style scoped >
.inputss {
  width: 400px;
  height: 60px;
  margin-top: 25px;
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid#DCDFE6;
}
.btn_1 {
  margin-left: 15px;
}
</style>
<template>
  <div>
    <el-card>
      <el-tabs v-model="type" type="card" @tab-click="handleClick">
        <el-tab-pane label="门诊日志" name="first">
          <!-- 分割线 -->
          <el-divider></el-divider>
          <!-- 时间选择器 -->
          <el-row :gutter="20">
            <el-col :span='8'>
              <div style="display:flex">
                <div class="time_title" style="width:120px;">就诊时间</div>
                <el-date-picker v-model="value1" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="chooseTime">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span='8'>
              <div style="display:flex">
                <div class="time_title" style="width:120px">就诊医院</div>
                <el-select v-model="value" placeholder="请选择" @change="chooseDocter">
                  <el-option v-for="item in options" :key="item.id" :label="item.companyName" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span='8'>
              <el-input placeholder="手机号码" v-model='keywords'>
                <el-button slot="append" icon="el-icon-search" @click='search'></el-button>
              </el-input>
            </el-col>
          </el-row>
          <!-- tab表格 -->
          <el-table :data='userList' v-loading="loading">
            <el-table-column type="index" label="序号" width="70"></el-table-column>
            <el-table-column label="患者ID" prop="patientId" width="150"></el-table-column>
            <el-table-column label="就诊状态" prop="orderStatus">
              <template slot-scope="scope">
                <span v-if="scope.row.orderStatus==='待就诊'" style="color: #a4a6d4">待就诊</span>
                <span v-else-if="scope.row.orderStatus==='就诊中'" style="color:#f5de7a">就诊中</span>
                <span v-else-if="scope.row.orderStatus === '已就诊' " style="color:#80d9c0">已就诊</span>
                <span v-else style="color: #ec8ea3">取消就诊</span>
              </template>
            </el-table-column>
            <el-table-column label="患者姓名" prop="username"></el-table-column>
            <el-table-column label="性别" prop="gender"></el-table-column>
            <el-table-column label="年龄" prop="age"></el-table-column>
            <el-table-column label="电话" prop="phone" width="150"></el-table-column>
            <el-table-column label="诊别" prop="category"></el-table-column>
            <el-table-column label="预约项目" prop="treatmentName"></el-table-column>
            <el-table-column label="医院" prop="companyName" width="150"></el-table-column>
            <el-table-column label="预约医生" prop="doctorName"></el-table-column>
            <el-table-column label="最后更新时间" prop="createTime" width="200"></el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <!-- <el-tooltip content="编辑" placement="top" :enterable='false'>
                                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="edit"></el-button>
                                </el-tooltip> -->
                <el-tooltip content="删除" placement="top" :enterable='false'>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click='open(scope.row)'>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页区域 -->
          <div class='fenye'>
            <!-- <el-button type="primary" @click="removeOut" icon="el-icon-upload2">导出</el-button>      -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10,20,100]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
          <!-- 添编辑用户弹出框 -->
          <el-dialog title="编辑患者信息" :visible.sync="editVisible" width="30%">
            <span>
              <el-form label-position="left" label-width="80px" :model="userInfo" :rules="editRules" ref="editRef">
                <el-form-item label="患者姓名" prop="name">
                  <el-input v-model="userInfo.name" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                  <el-input v-model="userInfo.gender" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phoneNum">
                  <el-input v-model="userInfo.phoneNum" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="ID" prop="id">
                  <el-input v-model="userInfo.id" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="患者年龄" prop="age">
                  <el-input v-model="userInfo.age" :clearable="true"></el-input>
                </el-form-item>
              </el-form>
            </span>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="editVisible = false">取 消</el-button>
              <el-button type="primary" @click="Confirm(userInfo)">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="年龄分析" name="second">
          <!-- 分割线 -->
          <el-divider></el-divider>
          <!-- 时间选择器 -->
          <div class="time_part">
            <div class="time_title_1">就诊时间</div>
            <el-date-picker v-model="ageValue" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" @change="ageFenXi">
            </el-date-picker>
          </div>
          <el-card class="charts_card">
            <!-- echarts图形区域 -->
            <div id="main" style="width: 1650px;height:400px;"></div>
          </el-card>
          <!-- table表格 -->
          <el-table :data="chartsData" border style="width: 100%"
            :header-cell-style="{background:'#c3c4f5',color: '#233333'}">
            <el-table-column type="index" label="序号" width="100"></el-table-column>
            <el-table-column prop="age" label="年龄段"></el-table-column>
            <el-table-column prop="count" label="人次"></el-table-column>
          </el-table>
          <div class="heji">
            <div class="heji_title">合计</div>
            <div class="heji_result">{{count}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="性别分析" name="third">
          <!-- 性别分析区域 -->
          <el-divider></el-divider>
          <!-- 时间选择器 -->
          <div class="time_part">
            <div class="time_title_1">就诊时间</div>
            <el-date-picker v-model="sexValue" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" @change="SexTimeChoose">
            </el-date-picker>
          </div>
          <!-- 饼图区域 -->
          <el-card class="charts_card">
            <!-- echarts图形区域 -->
            <div id="main_1" style="width: 100%;height:400px;"></div>
          </el-card>
          <!-- tab表格区域 -->
          <!-- table表格 -->
          <el-table :data="chartsData_1" border style="width: 100%"
            :header-cell-style="{background:'#c3c4f5',color: '#233333'}">
            <el-table-column type="index" label="序号" width="100"></el-table-column>
            <el-table-column prop="man" label="男"></el-table-column>
            <el-table-column prop="woman" label="女"></el-table-column>
            <el-table-column prop="count" label="合计"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="诊别分析" name="fourth">
          <!-- 分析 -->
          <!-- 分割线 -->
          <el-divider></el-divider>
          <!-- 时间选择器 -->
          <div class="time_part">
            <div class="time_title_1">就诊时间</div>
            <el-date-picker v-model="TypeValue" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" @change="TypeChooseTime">
            </el-date-picker>
          </div>
          <!-- 饼图区域 -->
          <el-card class="charts_card">
            <!-- echarts图形区域 -->
            <div id="main_2" style="width: 100%;height:400px;"></div>
          </el-card>
          <!-- tab表格区域 -->
          <!-- table表格 -->
          <el-table :data="chartsData_2" border style="width: 100%"
            :header-cell-style="{background:'#c3c4f5',color: '#233333'}">
            <el-table-column type="index" label="序号" width="100"></el-table-column>
            <el-table-column prop="chuzhen" label="初诊"></el-table-column>
            <el-table-column prop="fuzhen" label="复诊"></el-table-column>
            <el-table-column prop="kuaisuguahao" label="快速挂号"></el-table-column>
            <el-table-column prop="count" label="合计"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

    </el-card>
  </div>
</template>

<script>
  // 导入echarts
  import echarts from 'echarts'

  export default {
    data() {
      return {
        loading: false,
        type: 'first',
        value1: [],//时间选择器
        ageValue: [new Date(2021, 10, 10, 10, 10), new Date(2021, 10, 11, 10, 10)],
        sexValue: [new Date(2021, 10, 10, 10, 10), new Date(2021, 10, 11, 10, 10)],
        TypeValue: [new Date(2021, 10, 10, 10, 10), new Date(2021, 10, 11, 10, 10)],
        value: [],// 级联选择器
        options: [
          // {
          //     value: '1',
          //     label: '安徽冰川增幅口腔',
          // },
          // {
          //     value: '2',
          //     label: '安徽佳德口腔医院',
          // },
          // {
          //     value: '3',
          //     label: '安徽威尔口腔医院',
          // }
        ],
        userList: [],
        // 当前页
        currentPage: 1,
        // 编辑用户提示框
        editVisible: false,
        // //删除当前列表
        // deleteVisible: false,
        //弹出层绑定数据信息
        userInfo: {
          name: '',
          gender: '',
          phoneNum: '',
          id: '',
          age: ''
        },
        keywords: '',
        // 编辑用户表单验证规则
        editRules: {
          name: [
            { required: true, message: '请输入患者姓名', trigger: 'blur' },
            { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
          ],
          gender: [
            { required: true, message: '请输入性别', trigger: 'blur' },
            { min: 1, max: 1, message: '请输入正确性别', trigger: 'blur' }
          ],
          phoneNum: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: '请输入正确手机号码', trigger: 'blur' }
          ],
          id: [
            { required: true, message: '请输入患者ID', trigger: 'blur' },
            { min: 11, max: 11, message: '长度位11位', trigger: 'blur' }
          ],
          age: [
            { required: true, message: '请输入年龄', trigger: 'blur' },
            { min: 1, max: 3, message: '长度在 1 到 3 个字符', trigger: 'blur' }
          ]
        },
        chartsData: [

        ],
        chartsData_1: [

        ],
        chartsData_2: [

        ],
        page: 1,
        limit: 10,
        total: 0,
        hospitalName: '',
        count: null,
        searchStatus: false
      }
    },
    created() {
      this.getUserList()
      this.getHosList()
    },
    methods: {
      handleClick(tab) {
        console.log(tab.name)
        if (tab.name === 'second') {
          this.ageTongji()
          this.agePeopleTongji()
        } else if (tab.name === 'third') {
          this.getSexInfo()
          this.getSexTongji()
        } else if (tab.name === 'fourth') {
          this.TypeInfo()
          this.TypeTongji()
        }
      },
      getUserList() {
        this.loading = true
        this.$http.post(`/order/pageList`, {
          page: this.page,
          limit: this.limit
        }).then(res => {
          if (res.data.code === 200) {
            console.log(res)
            for (let i = 0; i < res.data.data.length; i++) {
              switch (res.data.data[i].gender) {
                case "1":
                  res.data.data[i].gender = "男"
                  break;
                case "2":
                  res.data.data[i].gender = "女"
                  break;
                default:
                  res.data.data[i].gender = "未知"
                  break;
              }
              switch (res.data.data[i].category) {
                case "0":
                  res.data.data[i].category = "快速挂号"
                  break;
                case "1":
                  res.data.data[i].category = "初诊"
                  break;
                default:
                  res.data.data[i].category = "复诊"
                  break;
              }
              switch (res.data.data[i].orderStatus) {
                case "1":
                  res.data.data[i].orderStatus = "待就诊"
                  break;
                case "2":
                  res.data.data[i].orderStatus = "就诊中"
                  break;
                case "3":
                  res.data.data[i].orderStatus = "已就诊"
                  break;
                default:
                  res.data.data[i].orderStatus = "取消就诊"
                  break;
              }
            }
            this.userList = res.data.data
            this.total = res.data.count
            this.loading = false
          } else {
            this.$message.error('列表加载失败,请重试')
          }
        })
      },
      edit() {
        this.$message.warning("功能暂不开放")
        // this.$router.push({
        //     path: '/shuju/edit'
        // })
        // this.editVisible = true
      },
      chooseTime(value1) {
        if (!this.value1) {
          this.value1 = []
        } else {
          let start = value1[0]
          let end = value1[1]
          // console.log(start, end)
          this.$http.post(`/order/pageList`, {
            orderStartTime: start,
            orderEndTime: end
          }).then(res => {
            console.log(res)
            if (res.data.code === 200) {
              if (res.data.data.length == 0) {
                this.$message.warning('暂无匹配数据')
              } else {
                for (let i = 0; i < res.data.data.length; i++) {
                  switch (res.data.data[i].gender) {
                    case "1":
                      res.data.data[i].gender = "男"
                      break;
                    case "2":
                      res.data.data[i].gender = "女"
                    default:
                      res.data.data[i].gender = "未知"
                      break;
                  }
                  switch (res.data.data[i].category) {
                    case "0":
                      res.data.data[i].category = "快速挂号"
                      break;
                    case "1":
                      res.data.data[i].category = "初诊"
                    default:
                      res.data.data[i].category = "复诊"
                      break;
                  }
                }
                this.total = res.data.count
                this.userList = res.data.data
              }
            } else {
              this.$message.error('数据加载失败，请重试，或联系管理员')
            }
          })
        }
      },
      // 所有医院信息
      getHosList() {
        this.$http.get(`/company/getCompany`).then(res => {
          console.log(res)
          if (res.data.code === 200) {
            this.options = res.data.data
          }
        })
      },
      // 年龄分析时间选择器
      ageFenXi(ageValue) {
        // console.log(ageValue)
        if (ageValue != null) {
          console.log(ageValue)
          let start = ageValue[0]
          let end = ageValue[1]
          this.ageTongji(start, end)
          this.agePeopleTongji(start, end)
        }
        // if(value1 != null) {
        //     let arr = []
        //     arr = value1

        //     for(let i = 0;i<arr.length;i++) {
        //         arr[i]
        //         arr[i]=arr[i].getFullYear() + '-' + (arr[i].getMonth() + 1) + '-' + arr[i].getDate() + ' ' + arr[i].getHours() + ':' + arr[i].getMinutes() + ':' + arr[i].getSeconds();

        //     }
        //     console.log(arr)
        //     this.ageTongji(arr)
        //     this.agePeopleTongji(arr)
        // }

      },
      // 年龄分析统计图接口
      ageTongji(start, end) {
        let starttime = start
        let endtime = end
        if (starttime && endtime) {
          this.$http.post(`/order/ageCount`, {
            orderStartTime: starttime,
            orderEndTime: endtime
          }).then((res) => {
            if (res.data.code === 200) {
              // console.log(res)
              this.getEcharts(res.data.data)
            } else {
              this.$message.error('数据加载失败')
            }
            // this.count =  res.data.data
          })
        } else {
          this.$http.post(`/order/ageCount`, {

          }).then(res => {
            if (res.data.code === 200) {
              // console.log(res)
              this.getEcharts(res.data.data)
            } else {
              this.$message.error('数据加载失败')
            }
            // this.count =  res.data.data
          })
        }
      },
      // 年龄分析人数统计
      agePeopleTongji(start, end) {
        let starttime = start
        let endtime = end
        if (starttime, endtime) {
          this.$http.post(`/order/ageCounts`, {
            orderStartTime: starttime,
            orderEndTime: endtime
          }).then((res) => {
            console.log(res)
            this.chartsData = res.data.data[0]
            this.count = res.data.data[1]
          })
        } else {
          this.$http.post(`/order/ageCounts`, {

          }).then((res) => {
            console.log(res)
            this.chartsData = res.data.data[0]
            this.count = res.data.data[1]
          })
        }
      },
      // 性别分析饼状图接口数据
      getSexInfo(start, end) {
        if (start && end) {
          this.$http.post('/order/genderCounts', {
            orderStartTime: start,
            orderEndTime: end
          }).then(res => {
            // console.log(res)
            if (res.data.code == 200) {
              this.getbinEcharts(res.data.data)
            } else {
              this.$message.error('图表数据加载失败,请重试')
            }
          })
        } else {
          this.$http.post('/order/genderCounts', {

          }).then(res => {
            console.log(res)
            this.getbinEcharts(res.data.data)
          })
        }
      },
      // 性别统计接口数据
      getSexTongji(start, end) {
        if (start && end) {
          this.$http.post('/order/genderCount', {
            orderStartTime: start,
            orderEndTime: end
          }).then((res) => {
            if (res.data.code == 200) {
              this.chartsData_1 = res.data.data
            } else {
              this.$message.error("表格数据加载失败")
            }
          })
        } else {
          this.$http.post('/order/genderCount', {

          }).then(res => {
            if (res.data.code == 200) {
              this.chartsData_1 = res.data.data
            } else {
              this.$message.error("表格数据加载失败")
            }
          })
        }
      },
      SexTimeChoose(sexValue) {
        if (sexValue != null) {
          console.log(sexValue)
          let start = sexValue[0]
          let end = sexValue[1]
          this.getSexInfo(start, end)
          this.getSexTongji(start, end)
          // this.getSexTongji(start,end)
        }
      },
      // 诊别分析
      TypeChooseTime(TypeValue) {
        if (TypeValue != null) {
          let start = TypeValue[0]
          let end = TypeValue[1]
          this.TypeInfo(start, end)
          this.TypeTongji(start, end)

        }
      },
      TypeInfo(start, end) {
        if (start && end) {
          this.$http.post(`/order/categoryCount`, {
            orderStartTime: start,
            orderEndTime: end
          }).then(res => {
            if (res.data.code == 200) {
              console.log(res)
              this.getbinEcharts_1(res.data.data)
            } else {
              this.$message.error('图表数据加载失败,请重试')
            }
          })
        } else {
          this.$http.post(`/order/categoryCount`, {}).then(res => {
            if (res.data.code == 200) {
              console.log(res)
              this.getbinEcharts_1(res.data.data)
            } else {
              this.$message.error('图表数据加载失败,请重试')
            }
          })
        }
        // this.$http.get('/api/backstage/sufferer_count/visittype_analyse_chart', {

        // }).then((res) => {
        //     console.log(res)
        //     for(let i = 0;i<res.data.data.length;i++) {
        //         if(res.data.data[i].name === "0") {
        //             res.data.data[i].name = "初诊"
        //         }else if(res.data.data[i].name === "1") {
        //             res.data.data[i].name = "复诊"
        //         }else {
        //             res.data.data[i].name = "快速挂号"
        //         }
        //     }
        //     this.getbinEcharts_1(res.data.data)
        // })
      },
      TypeTongji(start, end) {
        if (start && end) {
          this.$http.post(`/order/categoryCounts`, {
            orderStartTime: start,
            orderEndTime: end
          }).then(res => {
            if (res.data.code == 200) {
              console.log(res)
              this.chartsData_2 = res.data.data
            } else {
              this.$message.error('表格数据加载失败,请重试')
            }
          })
        } else {
          this.$http.post(`/order/categoryCounts`, {

          }).then(res => {
            if (res.data.code == 200) {
              console.log(res)
              this.chartsData_2 = res.data.data
            } else {
              this.$message.error('表格数据加载失败,请重试')
            }
          })
        }
        // this.$http.get('/api/backstage/sufferer_count/visittype_analyse_table', {
        //     params: {
        //         startDate: arr[0],
        //         endDate: arr[1]
        //     }
        // }).then((res) => {
        //     console.log(res)
        //     this.chartsData_2 = res.data.data
        // })
      },
      chooseDocter(value) {
        this.$http.post(`/order/pageList`, {
          companyId: value
        }).then(res => {
          console.log(res)
          if (res.data.code === 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              switch (res.data.data[i].gender) {
                case "1":
                  res.data.data[i].gender = "男"
                  break;
                case "2":
                  res.data.data[i].gender = "女"
                default:
                  res.data.data[i].gender = "未知"
                  break;
              }
              switch (res.data.data[i].category) {
                case "0":
                  res.data.data[i].category = "快速挂号"
                  break;
                case "1":
                  res.data.data[i].category = "初诊"
                default:
                  res.data.data[i].category = "复诊"
                  break;
              }
            }
            this.userList = res.data.data
            this.total = res.data.count
          }
        })
        // if(value === '1') {
        //     value = '安徽冰川增幅口腔医院'
        // }else if(value === '2') {
        //     value = '安徽佳德口腔医院' 
        // }else {
        //     value = '安徽威尔口腔医院'
        // }
        // this.hospitalName = value

      },
      search() {
        this.searchStatus = true
        let rxg = /^1[3-9]\d{9}$/
        if (rxg.test(this.keywords)) {
          this.$http.post(`/order/pageList`, {
            page: this.page,
            limit: this.limit,
            phone: this.keywords
          }).then(res => {
            if (res.data.code === 200) {
              console.log(res)
              if (res.data.data.length == 0) {
                this.$message.warning('暂无匹配结果')
              } else {
                for (let i = 0; i < res.data.data.length; i++) {
                  switch (res.data.data[i].gender) {
                    case "1":
                      res.data.data[i].gender = "男"
                      break;
                    case "2":
                      res.data.data[i].gender = "女"
                      break;
                    default:
                      res.data.data[i].gender = "未知"
                      break;
                  }
                  switch (res.data.data[i].category) {
                    case "0":
                      res.data.data[i].category = "快速挂号"
                      break;
                    case "1":
                      res.data.data[i].category = "初诊"
                      break;
                    default:
                      res.data.data[i].category = "复诊"
                      break;
                  }
                  switch (res.data.data[i].orderStatus) {
                    case "1":
                      res.data.data[i].orderStatus = "待就诊"
                      break;
                    case "2":
                      res.data.data[i].orderStatus = "就诊中"
                    case "3":
                      res.data.data[i].orderStatus = "已就诊"
                      break;
                    default:
                      res.data.data[i].orderStatus = "取消就诊"
                      break;
                  }
                }
                this.userList = res.data.data
                this.total = res.data.count
              }
            } else {
              this.$message.warning('请求失败,请重试')
            }
          })
        } else {
          this.$message.warning('手机号码格式不正确')
        }
      },
      handleSizeChange(newSize) {
        // console.log(newSize)
        // this.limit = newSize
        // this.getUserList()
        if (!this.searchStatus) {
          this.limit = newSize
          this.getUserList()
        } else {
          this.page = newSize
          this.search()
        }
      },
      handleCurrentChange(newPage) {
        console.log(newPage)
        if (!this.searchStatus) {
          this.page = newPage
          this.getUserList()
        } else {
          this.page = newPage
          this.search()
        }
      },
      // 弹框提示
      open(item) {
        this.$confirm('此操作将永久删除该患者, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          this.deleteVisible(item)
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
      //删除当前列表
      deleteVisible(id) {
        console.log(id.id)
        this.$http.delete(`/order/deleteOrder/${id.id}`).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.getUserList()
          } else {
            this.$message.error("操作失败")
          }
        })
      },
      // 柱状图
      getEcharts(e) {
        // 基于准备好的dom，初始化echarts实例
        console.log(e)
        var myChart = echarts.init(document.getElementById('main'));
        // 指定图表的配置项和数据
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function (params) {
              // console.log(params)
              let tar = params[0];
              // console.log(tar)
              return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
            }
          },
          xAxis: {
            data: ["0-11岁", "11-20岁", "21-30岁", "31-40岁", "41-50岁", "51-60岁", "61-70岁", "71-80岁", "80岁以上"]
          },
          yAxis: {
            name: '人数',
            type: 'value',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          series: [{
            name: '人次',
            type: 'bar',
            barWidth: '55%',
            data: e,
            itemStyle: {
              color: '#5c7bd9',
            }

          }],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      },
      // 饼图
      getbinEcharts(e) {
        console.log(e)
        var myChart = echarts.init(document.getElementById('main_1'));
        var option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            icon: "circle"
          },
          series: [
            {
              name: '统计结果',
              type: 'pie',
              radius: [100, 130],
              data: e,
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    var colorList = [
                      '#28cf92', '#ef4963'
                    ];
                    return colorList[params.dataIndex]
                  }
                }
              },
              labelLine: {
                show: true,
                normal: {
                  length: 50
                }
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  // fontWeight: 'bold'
                },
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option)
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      },
      // 饼图
      getbinEcharts_1(e) {
        console.log(e)
        var myChart = echarts.init(document.getElementById('main_2'));
        var option = {

          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            icon: "circle"
          },
          series: [
            {
              name: '统计结果',
              type: 'pie',
              radius: [100, 130],
              data: e,
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    var colorList = [
                      '#28cf92', '#ef4963', '#ffc81b'
                    ];
                    return colorList[params.dataIndex]
                  }
                }
              },
              labelLine: {
                show: true,
                normal: {
                  length: 50
                }
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  // fontWeight: 'bold'
                },
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option)
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      },
      // 导出
      removeOut() {

      }
    },
    mounted() {
      // 柱状图
      this.getEcharts()
      // 饼图
      // this.getbinEcharts()
      // 饼图
      // this.getbinEcharts_1()
    }
  }
</script>

<style scope lang='less'>
  body {
    margin: 0;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
  }

  .time_title {
    // text-align: center;
    line-height: 40px;
    // margin-right: 20px;
    // font-size: 15px;
    color: #233333;
    font-weight: 600;

  }

  .el-table {
    margin-top: 30px;
  }

  .fenye {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px
  }

  .time_part {
    display: flex;
  }

  .time_title_1 {
    margin-right: 30px;
    line-height: 40px;
    font-size: 15px;
    color: #233333;
    font-weight: 600;
  }

  .charts_card {
    margin-top: 30px;
  }

  .heji {
    height: 45px;
    border: 1px solid #ebeef5;
    border-top: none;
    display: flex;
    align-items: center;
    color: #606266;
    font-size: 14px;
    font-weight: 600;
  }

  .heji_title {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: inherit;
    border-right: 1px solid #ebeef5;
  }

  .heji_result {
    display: flex;
    height: inherit;
    align-items: center;
    flex: 1;
    justify-content: center;
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>快捷短语</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
                 <input
          class="inputss"
          type="text"
          placeholder="请输入内容"
          v-model="Shortcut"
        />
        <div class="bss">
               <el-button type="primary" icon="el-icon-check" class="btn_1" @click="addList">确定</el-button>
        </div>
    </el-card>
  </div>
</template>

 <script>
export default {
  data() {
    return {
     Shortcut:''
    };
  },
  created() {
   
  },
  computed: {},
  methods: {
  
  },
};
</script>

<style scoped >
.inputss {
  width: 400px;
  height: 60px;
  margin-top: 25px;
  margin-left: 20%;
  border-radius: 5px;
  border: 1px solid#DCDFE6;
}
.bss{
  margin-top: 10px;
   margin-left: 28%;
}
</style>
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import store from "./store/index";

import "./rem";
import VueAMap from "vue-amap";
import vueQr from "vue-qr";

import cookies from "vue-cookies";
Vue.use(cookies);

export default {
  components: { vueQr },
};

Vue.prototype.$store = store;

Vue.use(VueAMap);
// 初始化高德地图的 key 和插件
//  高德key密钥
window._AMapSecurityConfig = {
  securityJsCode: "868689b5890fa232295e2e914ae748b9",
};

VueAMap.initAMapApiLoader({
  key: "78f3e62f5e8fb5cf652fbb47bc1fa4fe",
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4",
  uiVersion: "1.0", // 版本号
});

// 全局样式表
import "./assets/global_css/css.css";
// 导入层叠样式表
import "./assets/fonts/iconfont.css";
import "./assets/insetFonts/iconfont.css";

// 导入nprogress包
import nProgress from "nprogress";
import "nprogress/nprogress.css";

// 导入axios包
import axios from "axios";

// axios.defaults.baseURL = 'http://www.wq888.top'
axios.defaults.baseURL = "https://www.idemer.com/web";
// axios.defaults.baseURL = 'https://121.5.30.221:8069'
// axios.defaults.baseURL = 'http://192.168.19.207:8069'

//在request拦截器中展示进度条
// 配置请求预处理
axios.interceptors.request.use((config) => {
  nProgress.start();
  config.headers.Authorization = window.sessionStorage.getItem("token");
  return config;
});

axios.interceptors.response.use((config) => {
  nProgress.done();
  return config;
});

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "默认标题"; //此处写默认的title
  }
  next();
});

import { custom } from "./utils/directive";
// 全局指令
Vue.directive("custom-shade", custom);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

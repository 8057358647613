<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{  path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医疗服务</el-breadcrumb-item>
      <el-breadcrumb-item>医院管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns">
        <el-button type="primary" icon="el-icon-delete" class="btn_2" @click='delete_info'>删除</el-button>
      </div>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- table表格区域 -->
      <el-table v-loading="loading" ref="multipleTable" :data="Info" tooltip-effect="dark" style="width: 100%"
        @selection-change="chooseList">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="医院名称" prop="companyName">
          <!-- <template #default="scope">{{ scope.row.date }}</template> -->
        </el-table-column>
        <el-table-column label="医院头像" prop="headPicture">
          <template slot-scope="scope">
            <img :src="scope.row.headPicture" style="height:70px;width:70px;border-radius:50%" />
          </template>
        </el-table-column>
        <el-table-column prop="address" label="医院地址">
        </el-table-column>
        <el-table-column prop="types" label="医院类型">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="success" plain>成功按钮</el-button> -->
            <div style="color:#1890FF;cursor: pointer;" @click="watchDetail(scope.row.id)">查看详情</div>
            <!-- <el-button type="success" icon="el-icon-tickets"  @click="watchDetail(scope.row.id)" plain>详情</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>

    </el-card>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        Info: [

        ],
        Info_1: {
          hospital_name: ''
        },
        multipleSelection: [], //当前选中的项
        currentPage: 1,
        keywords: '',
        value: '',
        dialogVisible: false,
        dialogVisible_1: false,
        value: [],  //医院类型默认值
        detailAddress: '',
        hospital_type: [
          {
            value: '0',
            label: '公立医院',
          },
          {
            value: '1',
            label: '私立医院',
          },
          {
            value: '2',
            label: '综合医院',
          }
        ],
        page: 1,
        limit: 10,
        total: 0,
        loading: false
      }
    },
    created() {
      this.getListInfo()
    },
    methods: {
      // 获取列表数据
      getListInfo() {
        this.loading = true
        this.$http.post('/company/searchModel', {
          page: this.page,
          limit: this.limit
        }).then(res => {
          console.log(res)
          // for(let i = 0;i<res.data.data.length;i++) {
          //     if(res.data.data[i].types === "0") {
          //         res.data.data[i].types = "公立医院"
          //     } else if(res.data.data[i].types === "1") {
          //             res.data.data[i].types = "私立医院"
          //     } else {
          //             res.data.data[i].types = "综合医院"
          //     }
          // }
          if (res.data.code === 200) {
            this.Info = res.data.data.list
            for (let i = 0; i < this.Info.length; i++) {
              switch (this.Info[i].types) {
                case "0":
                  this.Info[i].types = "公立医院"
                  break;
                case "1":
                  this.Info[i].types = "私立医院"
                  break;
                default:
                  this.Info[i].types = "综合医院"
                  break;
              }
            }
            console.log(this.Info)
            this.total = res.data.data.all
            console.log(this.total)
            this.loading = false
          }
        }).catch(err => {
          console.log(err)
        })
      },
      search() {
        if (this.keywords || this.value != "") {
          console.log(this.keywords, this.value)
          this.$http.post('/company/searchModel', {
            page: this.page,
            limit: this.limit,
            companyName: this.keywords ? this.keywords : '',
            types: this.value ? this.value : ''
          }).then(res => {
            console.log(res)
            if (res.data.code === 200) {
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].types === "0") {
                  res.data.data[i].types = "公立医院"
                } else if (res.data.data[i].types === "1") {
                  res.data.data[i].types = "私立医院"
                } else {
                  res.data.data[i].types = "综合医院"
                }
              }
              console.log(this.Info)
              this.Info = res.data.data
              this.total = res.data.count
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.warning("查询条件不完整")
        }

      },
      chooseList(val) {
        this.multipleSelection = val
        console.log(this.multipleSelection)
      },
      // 删除选中的当前项目
      delete_info() {
        if (this.multipleSelection.length > 0) {
          let arr = this.multipleSelection.map(item => {
            return item.id
          })
          arr = arr.toString()
          this.delHospital(arr)
        } else {
          this.$message.error('当前无选中选项')
        }
      },
      // 删除当前选项
      delHospital(id) {
        console.log(id)
        this.$http.get(`/company/delete/?ids=` + id).then(res => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.getListInfo()
          } else {
            this.$message.success(res.data.msg)
          }
        })
      },
      chooseType(value) {
        console.log(value)
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize
        console.log(this.limit)
        this.getListInfo()
      },
      handleCurrentChange(newPage) {
        this.page = newPage
        console.log(this.page)
        this.getListInfo()
      },
      watchDetail(id) {
        console.log(id)
        // this.dialogVisible = true
        // 跳转至查看详情页
        this.$router.push({
          path: '/gongwen/edit',
          query: {
            id: id
          }
        })
      },
      chooseHospital_type(value) {
        console.log(value)
        this.value = value
      },
      reset() {
        this.keywords = "",
          this.value = ""
        this.getListInfo()
      }
    }
  }
</script>

<style scoped lang='less'>
  .search_part {
    display: flex;
  }

  .btns {
    display: flex;

    .btn_1 {
      background-color: #666de8;
      border: none;
    }

    .btn_2 {
      background-color: #f14866;
      border: none;
    }
  }

  .reset_btn {
    border: 1px solid #666EE8;
    color: #666EE8;
    background-color: #FFFFFF;
    margin-left: 50px;

  }

  .search_btn {
    background-color: #666EE8;
    color: #FFFFFF;
    border: none;

  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin: 0 20px;
    }

    >div button {
      margin-bottom: 7px;
    }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
</style>
export let custom = {
  update(el, binding, vnode) {
    vnode.elm.style.zIndex = 999;
    if (binding.value) {
      let div = document.createElement("div");
      div.style.position = "fixed";
      div.style.width = "100vw";
      div.style.height = "100vh";
      div.style.top = "0";
      div.style.backgroundColor = "rgba(0,0,0,0)";
      div.style.display = "flex";
      div.style.justifyContent = "center";
      div.style.alignItems = "center";
      div.setAttribute("id", "login_container_container");
      div.addEventListener("click", (e) => {
        document.getElementById("login_container_container").remove();
        vnode.data.attrs.close();
      });
      document.body.appendChild(div);
    }
  },
};

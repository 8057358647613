<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医疗服务</el-breadcrumb-item>
      <el-breadcrumb-item>预约设置</el-breadcrumb-item>
      <el-breadcrumb-item>添加预约</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding: 50px 0;display: flex;justify-content: center;align-items: center;">
        <div>
          <!-- 单选框 -->
          <!-- <div style="display: flex;">
            <div style='margin-right: 30px; color:#233333; font-weight:bold'>业务类型：</div>
            <el-radio-group v-model="radio" @change="chooseType(radio)">
              <el-radio v-model="radio" label="0" size="medium">预约挂号</el-radio>
              <el-radio v-model="radio" label="1" size="medium" disabled>专家门诊</el-radio>
            </el-radio-group>
          </div> -->
          <!-- 当前医生 -->
          <div style="display: flex;">
            <div style='margin-right: 30px; color:#233333; font-weight:bold'>当前医生：</div>
            <div></div>
          </div>
          <!-- 日期 -->
          <div style="display: flex;margin: 35px 0">
            <div style='margin-right: 30px; color:#233333; font-weight:bold'>日期:</div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="city in cities" :label="city.label" :key="city.value" @change="cc(city)">
                {{city.label}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 时间段 -->
          <div style="display: flex;margin-bottom: 35px;align-items: center;">
            <div style='margin-right: 30px; color:#233333; font-weight:bold'>时间段:</div>
            <div style="display: flex;flex-direction: column;">
              <div style="display:flex;align-items: center;margin:10px 0">
                <div style="margin-right: 15px">上午</div>
                <el-select v-model="value4" placeholder="请选择" @change="morTime">
                  <el-option v-for="item in timeDate1" :key="item.value" :label="item.time" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div style="display:flex;align-items: center;margin:10px 0">
                <div style="margin-right: 15px">下午</div>
                <el-select v-model="value5" placeholder="请选择" @change="afterTime">
                  <el-option v-for="item in timeDate2" :key="item.value" :label="item.time" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 选择医生 -->
          <div style="display: flex;margin: 0 0 35px 0;align-items: center;">
            <div style='margin-right: 30px; color:#233333; font-weight:bold'>选择医生:</div>
            <el-select v-model="value3" placeholder="请选择" @change="changeDoc">
              <el-option v-for="item in options" :key="item.id" :label="item.doctorName" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="display: flex;margin: 50px 0;align-items: center;justify-content: center;">
            <el-button style="background:#666ee8;color:#FFFFFF;margin: 0 30px" @click="confirm">确定</el-button>
            <el-button style="margin: 0 30px" @click="getback()">返回</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  const cityOptions = [
    {
      value: 1,
      label: '周一',
    },
    {
      value: 2,
      label: '周二'
    },
    {
      value: 3,
      label: '周三'
    },
    {
      value: 4,
      label: "周四"
    },
    {
      value: 5,
      label: '周五'
    },
    {
      value: 6,
      label: '周六'
    },
    {
      value: 7,
      label: '周日'
    }

  ];
  export default {
    data() {
      return {
        radio: "1",
        checkedCities: [],
        cities: cityOptions,
        timeDate1: [
          {
            value: 0,
            time: '09:30-12:00'
          }
        ],
        timeDate2: [
          {
            value: 0,
            time: '13:30-20:30'
          }
        ],
        value1: [new Date(2016, 9, 10, 6, 30), new Date(2021, 9, 10, 12, 30)],
        value2: [new Date(2016, 9, 10, 13, 20), new Date(2021, 9, 10, 19, 30)],
        options: [],
        value3: '',
        value4: '',
        value5: '',
        page: 1,
        docId: null,
        types: 0
      }
    },
    created() {
      this.getDocInfo()
    },
    methods: {
      getDocInfo() {
        this.$http.post(`/doctor/selectDoctor`, {
          page: this.page
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.options = res.data.data
          }
        })
      },
      chooseType(r) {
        console.log(r)
        this.types = r
      },
      handleCheckAllChange(val) {
        console.log(val)
      },
      handleCheckedCitiesChange(e) {
        // console.log(e)
        this.checkedCities = e
        console.log(this.checkedCities)
      },
      morTime(e) {
        this.value4 = this.timeDate1[e].time
      },
      afterTime(e) {
        this.value5 = this.timeDate2[e].time
      },
      changeDoc(e) {
        console.log(e)
        this.docId = e
      },
      cc(e) {
        //   console.log(e)
        //   let arr = []
        //   arr.push(e)
        //   arr = arr.concat(arr)
        //   console.log(arr)
      },
      confirm() {
        let timeArr = []
        if (this.value4 && this.value5 !== "") {
          timeArr.push(this.value4, this.value5)
          console.log(timeArr)
        }
        if (this.types !== "" && this.docId !== "" && this.checkedCities.length && timeArr.length) {
          this.$http.post(`/medical/addYuyueParm`, {
            types: this.types,
            doctorId: this.docId,
            dateParm: this.checkedCities,
            timeSpace: timeArr
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              // this.getList()
              this.$router.go(-1)
            }
          })
        } else {
          this.$message.warning('请检查漏选项目')
        }
      },
      getback() {
        this.$router.go(-1)
      }
    }
  }
</script>


<style>
  .cc {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .el-radio__inner {
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    text-align: center !important;
  }

  .el-radio__label {
    font-size: 16px;
    padding-left: 18px;
    color: #233333;
  }

  /* .el-checkbox__inner {
    height:17px!important;
    width: 17px!important;
    border-radius: 100%!important;
} */
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医生管理</el-breadcrumb-item>
      <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding: 20px">
        <!--医生名称  -->
        <div class="hospital_name">
          <div style="margin-right:20px"><i style="color:red">*</i>医生名称:</div>
          <el-input style="width:220px;" v-model="doctorInfo.name" class="p" placeholder="请输入医生名称">
          </el-input>
        </div>
        <!-- 医生电话 -->
        <div style="margin-top: 50px;" class="hospital_name">
          <div style="margin-right:20px"><i style="color:red">*</i>医生电话:</div>
          <el-input style="width:220px;" v-model="doctorInfo.phone" class="p" placeholder="请输入医生电话">
          </el-input>
        </div>
        <!-- 医院头像 -->
        <div class="uploadImg">
          <div><i style="color:red">*</i>医生头像:</div>
          <img :src="imageUrl ? imageUrl : doctorInfo.headPicture">
          <div>
            <el-upload style="border: 1px dashed #d9d9d9;margin-left:20px" class="avatar-uploader"
              action="https://www.idemer.com/web/upload/file" :show-file-list="false" :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <!-- <el-upload style="border: 1px dashed #d9d9d9;margin-left:20px"
                            class="avatar-uploader"
                            action="http://192.168.3.165:8069/upload/file"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload> -->
          </div>
        </div>
        <!--医生职称  -->
        <div class="hospital_name" style="margin-top: 50px;">
          <div style="margin-right:20px">医生职称:</div>
          <el-input style="width:220px;" v-model="doctorInfo.jobTitle" class="p" placeholder="请输入医生名称">
          </el-input>
        </div>
        <!-- 擅长领域 -->
        <div class="edit_hospital_introduce">
          <div style='margin-right:20px'>擅长领域:</div>
          <el-col :span="8">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入擅长领域"
              v-model="doctorInfo.strongpoint">
            </el-input>
          </el-col>
        </div>
        <div class="btns">
          <el-button @click="confirm" style="background-color:#686ce8;color:#FFFFFF">确定</el-button>
          <el-button @click="getback" style="border:1px solid #686ce8;color:#686ce8">返回</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        imageUrl: "",// 上传头像
        detailAddress: '',
        introduce: '',
        hospital_name: '',
        docPosition: '',
        doc_name: '',
        begoodat: '',
        positions: '',
        value: '',  //医院类型默认值
        HosId: 0,
        doctorInfo: {}
      }
    },
    created() {
      console.log(this.$route.query)
      this.doctorInfo = this.$route.query
      // this.HosId = this.$route.query.id
      // console.log(this.HosId)
    },
    methods: {
      chooseAddress(e) {
        console.log(e, this.detailAddress)
      },
      chooseHospital_type(value) {
        switch (value) {
          case "0":
            this.value = "0"
            break;
          case "1":
            this.value = "1"
            break;
          default:
            this.value = "2"
            break;
        }
        console.log(this.value)
      },
      handleAvatarSuccess(res, file) {
        console.log(res)
        console.log(file)
        this.imageUrl = file.response;
        console.log(this.imageUrl)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      // 提交
      confirm() {
        if (this.doctorInfo.name && this.doctorInfo.phone) {
          this.$http.post('/doctor/updateAccount', {
            id: Number(this.doctorInfo.id),
            headPicture: this.imageUrl ? this.imageUrl : this.doctorInfo.headPicture,// 头像
            strongpoint: this.doctorInfo.strongpoint,// 擅长领域
            // positions: this.positions,
            name: this.doctorInfo.name,// 姓名
            phone: this.doctorInfo.phone,// 手机号
            jobTitle: this.doctorInfo.jobTitle,// 医生职称
            // shortintro: this.doctorInfo.shortintro,
            // companyName: this.doctorInfo.companyName
          }).then(res => {
            console.log(res)
            if (res.data.code === 200) {
              this.$message.success(res.data.msg)
              this.$router.push({
                path: '/doctorManage/detail',
                query: {
                  id: this.doctorInfo.id
                }
              })
            } else {
              this.$message.error("编辑失败")
            }
          })
        } else {
          this.$message.error('信息填写不完整')
          console.log(this.doctorInfo.strongpoint, this.doctorInfo.name, this.doctorInfo.phone)
          return
        }
      },
      changePosition(e) {
        console.log(e)
        this.positions = e
      },
      getback() {
        this.$router.go(-1)
      },

    }
  }
</script>

<style lang="less" scoped>
  .hospital_name {
    display: flex;
    align-items: center;
  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin: 0 20px;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      // margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;

    >button {
      margin: 0 50px;
    }
  }
</style>
<template>
  <div id="app">
    <!-- 占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app',
    provide() {
      return {
      }
    },
    data() {
      return {
        isRouterShow: true
      }
    },
    created() {
      this.beforeunloadFn()
    },
    methods: {
      beforeunloadFn() {
        this.$http
          .post(`/user/getuser`)
          .then((res) => {
            console.log('APP', res);
            if (res.data.code == 200) {
              window.localStorage.setItem(
                "userInfo",
                JSON.stringify(res.data.data)
              );
              this.$store.commit("getUserInfo", res.data.data)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    }
  }
</script>

<style>

</style>
<template>
  <div>
    <!-- 自定义表单组件 -->
    <div class="contarner" style="position:relative">
      <input v-model="value" @blur="blur" @focus="focus" class="inss" type="text">
      <div style="display:flex;background-color: #fff;
      color: #666;
      font-size: 16px;
      z-index: 99;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
      box-sizing: border-box;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;;position: absolute;
      width: 100%;">
        <!-- 弹出层 -->
        <div id="123" style="width: 100%;
        height: 100%;" @mouseenter.stop="mouseenter" @mouseleave="mouseleave">
          <!-- 一级目录 -->
          <div class="inssList">
            <el-collapse-transition>
              <div v-show="list">
                <!-- 一级目录 -->
                <div :style="currentItem==index?'color: #409eff;':''" @click="selectItem(item,index)" class="item"
                  v-for="(item,index) in options" :key="index">
                  <div>{{item.moneyNames}}</div>
                </div>
                <!-- 一级目录输入框 -->
                <div v-show="input1" style="height:40px;display: flex;justify-content: space-between;align-items: center;
                    padding: 0 5px;">
                  <el-input ref="input1Route" size="mini" placeholder="请输入一级项目名称" maxlength="10" show-word-limit
                    v-model="input1Content" clearable>
                  </el-input>
                  <el-button @click="addInputRoute" size="mini" type="success" :disabled="!Boolean(input1Content)">添加
                  </el-button>
                </div>
                <!-- 一级目录添加按钮 -->
                <div style="display:flex;justify-content: center;align-items: center;height: 30px;padding:10px 10px;">
                  <el-button @click="clickForm1" size="mini" icon="el-icon-circle-plus-outline" round></el-button>
                </div>
              </div>
            </el-collapse-transition>
          </div>
          <!-- 二级目录 -->
          <div v-show="list2" class="inssList" style="right:-100%">
            <div v-show="list">
              <!-- 二级目录 -->
              <div>
                <div @click="selectItem2(item,index)" class="item" v-for="(item,index) in options2" :key="index">
                  <div>{{item.moneyNames}}</div>
                </div>
              </div>
              <!-- 二级目录输入框 -->
              <div v-show="input2" style="height:40px;display: flex;justify-content: space-between;align-items: center;
                      padding: 0 5px;">
                <el-input ref="input1Route" size="mini" placeholder="请输入二级项目名称" maxlength="10" show-word-limit
                  v-model="input2Content" clearable>
                </el-input>
                <el-button @click="addInput2Route" size="mini" type="success" :disabled="!Boolean(input2Content)">添加
                </el-button>
              </div>
              <!-- 二级目录添加按钮 -->
              <div style="display:flex;justify-content: center;align-items: center;height: 30px;padding:10px 10px;">
                <el-button @click="clickForm2" size="mini" icon="el-icon-circle-plus-outline" round></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Array,
        default: () => []
      },
      value1: {// 输入框内容
        type: [Number, String],
        default: ''
      }
    },
    data() {
      return {
        currentItem: -1,// 当前选中一级路由
        blurList: false,// 失焦开关
        list: false,//一级目录列表开关
        list2: false,//二级目录列表开关
        index1: '',// 选中的一级目录
        index2: '',// 选中的二级目录
        input1: false,// 一级目录输入框开关
        input2: false,// 二级目录输入框开关
        input1Content: '',// 一级目录内容
        input2Content: '',// 二级目录内容
        routeItem1: '',// 选中一级目录
        routeItem2: '',// 选中二级目录
        value: this.value1,
        // options: [{// 一级项目列表
        //   value: 'zhinan',
        //   label: '指南',
        //   children: [{
        //     value: 'yizhi',
        //     label: '一致'
        //   }, {
        //     value: 'fankui',
        //     label: '反馈'
        //   }, {
        //     value: 'xiaolv',
        //     label: '效率'
        //   }, {
        //     value: 'kekong',
        //     label: '可控'
        //   }]
        // }, {// 项目列表
        //   value: 'zhinan',
        //   label: '指南',
        //   children: [{
        //     value: 'yizhi',
        //     label: '一致'
        //   }, {
        //     value: 'fankui',
        //     label: '反馈'
        //   }, {
        //     value: 'xiaolv',
        //     label: '效率'
        //   }, {
        //     value: 'kekong',
        //     label: '可控'
        //   }]
        // }],
        options2: [],// 二级目录
      }
    },
    watch: {
      // 监听父组件传入的数据，更新到本地
      value1(newVal, oldVal) {
        this.value = newVal;
      },
      // 监听本地数据的变化，通知父组件更新
      value(newVal, oldVal) {
        this.$emit('update', newVal);
      }
    },
    mounted() {
      console.log(this.options)
      // 监听鼠标按下
      window.addEventListener('mousedown', this.handleMousedown)
    },
    destroyed() {
      // 移除监听事件
      window.removeEventListener('mousedown', this.handleMousedown);
    },
    methods: {
      // 添加路由
      addRoute() {
        this.$http.post(``, {

        }).then(res => {

        }).catch(err => {

        })
      },
      // 鼠标按下
      handleMousedown() {
        if (this.blurList) {
          this.list = false
          this.list2 = false
          this.input1 = false
          this.input2 = false
          this.input2Content = ''
          this.input1Content = ''
          this.currentItem = -1
          this.$parent.clearValidate()
        }
      },
      // 鼠标移入
      mouseenter() {
        this.blurList = false
      },
      // 鼠标移出
      mouseleave() {
        this.blurList = true
        console.log('鼠标移出')
      },
      // 选中一级级目录
      selectItem(item, index) {
        console.log(item, index)
        this.routeItem1 = item
        this.options2 = item.childenList
        this.index1 = index
        this.input1 = false
        this.input1Content = ''
        this.input2 = false
        this.input2Content = ''
        this.currentItem = index
        // 打开二级目录
        this.list2 = true
      },
      // 选中二级级目录
      selectItem2(item, index) {
        console.log(item, index)
        this.routeItem2 = item
        console.log(`${this.options[this.index1].moneyNames}/${this.options2[index].moneyNames}`)
        this.value = `${this.options[this.index1].moneyNames}/${this.options2[index].moneyNames}`
        this.currentItem = -1
        this.list = false
        this.list2 = false
      },
      // 添加一级目录
      addInputRoute() {
        let obj = {
          value: 'zhinan1',
          moneyNames: this.input1Content,
          childenList: [],
        }
        this.options.push(obj)
        this.input1 = false
        this.input1Content = ''
      },
      // 添加二级目录
      addInput2Route() {
        console.log(this.options2)
        this.options2.push({
          value: 'zhinan1',
          moneyNames: this.input2Content,
        })
        this.input2 = false
        this.input2Content = ''
      },
      // 打开一级目录输入框
      clickForm1() {
        this.input1 = true
        this.list2 = false
        // console.log(this.$refs['input1Route'])
        // this.$refs.input1Route.focus()
      },
      // 打开二级目录输入框
      clickForm2() {
        this.input2 = true
        this.input1 = false
        // console.log(this.$refs['input1Route'])
        // this.$refs.input1Route.focus()
      },
      blur(e) {
        this.$nextTick(() => {
          if (this.blurList) {
            // this.list = false
            // this.lis2 = false
          }
        })
      },
      focus(e) {
        this.list = true
      },
    },
  }
</script>

<style scoped>
  .contarner {
    width: 300px;
    height: 40px;
  }

  .inss {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #eee;
    font-size: 16px;
    color: #666;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .inssList {
    width: 100%;
    max-height: 200px;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    color: #666;
    font-size: 16px;
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }


  .item {
    padding-left: 10px;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
  }

  .item:hover {
    background-color: #f5f7fa;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
  }

  .inssList::-webkit-scrollbar {
    width: 0 !important
  }

  .inssList2::-webkit-scrollbar {
    width: 0 !important
  }

  .inssList .el-input {
    width: 70%;
  }

  .inssList .el-button--mini,
  .el-button--mini.is-round {
    padding: 0px;
    height: 30px;
    width: 50px;
  }

  input:focus {
    border: 1px solid #bbb;
    outline: none;
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医院管理</el-breadcrumb-item>
      <el-breadcrumb-item>查看详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding: 20px">
        <!-- 医院名称 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>医院名称:</div>
          <div style="margin-left:20px">{{HosInfo.companyName}}</div>
        </div>
        <!-- 医院头像 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>医院头像:</div>
          <!-- <el-image style="height:50px;width:50px;border-radius:50%;margin-left:30px" :src="HosInfo.headPicture"></el-image> -->
          <img :src="HosInfo.headPicture" style="height:50px;width:50px;border-radius:50%;margin-left:30px">
        </div>
        <!-- 医院地址 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>医院地址:</div>
          <div style="margin-left:20px">{{HosInfo.address}}</div>
        </div>
        <!-- 医院类型 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>医院类型:</div>
          <div style="margin-left:20px">{{HosInfo.types}}</div>
        </div>
        <!-- 营业时间 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>营业时间:</div>
          <div style="margin-left:20px">
            {{HosInfo.operatingStartTime?HosInfo.operatingStartTime+' - '+HosInfo.operatingEndTime:''}}</div>
        </div>
        <!-- 医院简介 -->
        <div style="display:flex;margin-bottom:50px;">
          <div>医院简介:</div>
          <div style="margin-left:20px;flex:0.7;">
            {{HosInfo.companyIntroduction}}
          </div>
        </div>
        <!-- 医院背景图片 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>医院背景图片:</div>
          <img v-if="HosInfo.headPics" v-for="(item,index) in HosInfo.headPics" :src="item"
            style="height:50px;width:50px;border-radius:50%;margin-left:30px">
        </div>
        <!-- 视频展示 -->
        <div style="display:flex;margin-bottom:50px;">
          <div>视频展示:</div>
          <div style="margin-left:20px;flex:0.7; width: 400px;">
            <video :src="videosrc" class="avatar video-avatar" controls="controls">您的浏览器不支持视频播放</video>
          </div>
        </div>
        <!-- 营业执照照片 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>营业执照照片:</div>
          <!-- <el-image style="height:50px;width:50px;border-radius:50%;margin-left:30px" :src="HosInfo.headPicture"></el-image> -->
          <img :src="HosInfo.businessLicense" style="height:50px;width:50px;border-radius:50%;margin-left:30px">
        </div>
        <!-- 医疗机构执业许可证照片 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>医疗机构执业许可证照片:</div>
          <!-- <el-image style="height:50px;width:50px;border-radius:50%;margin-left:30px" :src="HosInfo.headPicture"></el-image> -->
          <img :src="HosInfo.medicalInstitution" style="height:50px;width:50px;border-radius:50%;margin-left:30px">
        </div>
        <!-- 法人身份证照片 -->
        <div style="display:flex;margin-bottom:50px;align-items: center;">
          <div>法人身份证照片:</div>
          <!-- <el-image style="height:50px;width:50px;border-radius:50%;margin-left:30px" :src="HosInfo.headPicture"></el-image> -->
          <img :src="HosInfo.frontPicture" style="height:50px;width:50px;border-radius:50%;margin-left:30px">
          <img :src="HosInfo.reversePicture" style="height:50px;width:50px;border-radius:50%;margin-left:30px">
        </div>
        <!-- 编辑按钮 -->
        <div style='margin-top: 70px;display: flex;justify-content: center;'>
          <el-button type="primary" icon="el-icon-edit" @click="edit">编辑</el-button>
          <el-button type="primary" @click="getback">返回</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        HosId: 0,
        HosInfo: {},
        videosrc: ""
      }
    },
    methods: {
      edit() {
        // 跳转至编辑页面
        this.$router.push({
          path: '/gongwen/detail/edit',
          query: {
            id: this.HosId,
            HosInfo: this.HosInfo,
            videosrc: this.videosrc,
            latitude: this.HosInfo.latitude,
            longitude: this.HosInfo.longitude,
          }
        })
      },
      getInfo() {
        this.$http.get(`/company/selectCompanyById/${this.HosId}`).then(res => {
          if (res.data.code === 200) {
            this.HosInfo = res.data.data
            console.log(this.HosInfo)
            if (this.HosInfo.headPics) {
              this.HosInfo.headPics = this.HosInfo.headPics.split(',')
            } else {
              this.HosInfo.headPics = []
            }
            console.log(this.HosInfo.headPics)
            this.videosrc = res.data.data.hospitalVideo
            switch (this.HosInfo.types) {
              case "0":
                this.HosInfo.types = "公立医院"
                break;
              case "1":
                this.HosInfo.types = "私立医院"
              default:
                this.HosInfo.types = "综合医院"
                break;
            }
            console.log(this.HosInfo)
          }
        })
      },
      getback() {
        this.$router.back(-1)
      }
    },
    created() {
      this.HosId = this.$route.query.id
      this.getInfo()
    }
  }
</script>

<style scoped lang="less">
  .video-avatar {
    width: 400px;
    height: 400px;
    margin-bottom: 50px;

    .bight {
      width: 100px;
      height: 50px;
      position: absolute;
      top: 80%;
      left: 50%;
    }
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>患者列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加病例</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs v-model="activeTab" type="card" @tab-click="handleClick" class="items">
        <el-tab-pane v-for="item in options" :key="item.id" :label="item.name" :value="item.name">
        </el-tab-pane>
      </el-tabs>
      <!-- <el-divider></el-divider> -->
      <!-- 添加病例 -->
      <div class="Casecontent">
        <!-- 主诉 -->
        <div class="name_content">
          <div class="name_content_tips">主诉:</div>
          <div class="inss">
            <input style="padding:3px" id="focusInput" class="inss" type="" placeholder="" v-model="Chiefcomplaint"
              name="zs" @change="bssss" @focus="focusInput" @blur="blurInput" />
            <!-- 快捷短语 -->
            <el-collapse-transition v-if="Shortcutcontent.length">
              <div v-if="shows" class="inss showscs">
                <ul>
                  <li class="focusLi" v-for="(item, index) in Shortcutcontent" :key="index" @click="busss(item)">
                    {{ item.phrase }}
                  </li>
                </ul>
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <!-- 现病史 -->
        <div class="name_content">
          <div class="name_content_tips">现病史:</div>
          <div class="inss">
            <input id="focusInput" class="inss" type="text" placeholder="" v-model="Presentdisease" @focus="focusInput"
              @blur="blurInput" name="xbs" />
            <el-collapse-transition v-if="Presentcontent.length">
              <div v-if="Presenshows" class="inss showscs">
                <ul>
                  <li class="focusLi" v-for="(item, index) in Presentcontent" :key="index" @click="Presenclick(item)">
                    {{ item.phrase }}
                  </li>
                </ul>
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <!-- 项目 -->
        <div id="projec" class="name_content">
          <div class="name_content_tips"><i style="color:red;">*</i>项目:</div>
          <el-checkbox-group size="medium" v-model="currentprojectList">
            <el-checkbox v-for="(item,index) in projectList" :label="item.title" :key="index">
              {{item.title}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 既往史： -->
        <div class="name_content">
          <div class="name_content_tips">既往史：</div>
          <div>
            <div style="margin-bottom:10px">1.是否有重大疾病史</div>
            <div>
              <el-radio-group v-model="Major">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </div>
          </div>

        </div>
        <div class="name_content">
          <div class="name_content_tip">
            <div style="margin-bottom:10px">2.是否有高血压</div>
            <div>
              <el-radio-group v-model="hypertension" class="radioss">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="name_content">
          <div class="name_content_ti">
            <div>
              <div style="margin-bottom:10px">3.是否有心脏病史</div>
              <div>
                <el-radio-group v-model="heart" class="radioss_a">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
        <div class="name_content">
          <div class="name_content_tp">
            <div style="margin-bottom:10px">4.是否有血液疾病史</div>
            <div>
              <el-radio-group v-model="blood">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- 口腔检查 -->
        <div class="name_content">
          <div class="name_content_tips"><i style="color:red;">*</i>口腔检查</div>
          <div class="box1">
            <div class="upper-toos">
              <div class="fontsize">上</div>

              <div class="upper">
                <div class="tooth">
                  <el-checkbox-group v-model="chooseType" @change="change">
                    <el-checkbox-button v-for="(item, index) in range" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
                <div class="tooths">
                  <el-checkbox-group v-model="chooseType" @change="change">
                    <el-checkbox-button v-for="(item, index) in range1" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
              </div>

              <div class="Lefts">
                <div class="Lefts_top"></div>
                <div class="Lefts-bottom"></div>
              </div>
              <div class="fonsLef">
                <div class="fontsizes">右</div>
                <div class="fontsizes">左</div>
              </div>
              <div class="upper">
                <div class="tooth">
                  <el-checkbox-group v-model="chooseType" @change="change">
                    <el-checkbox-button v-for="(item, index) in range2" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
                <div class="tooths">
                  <el-checkbox-group v-model="chooseType" @change="change">
                    <el-checkbox-button v-for="(item, index) in range3" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="fontsize">下</div>
            </div>
            <div style="display:flex;justify-content:center;margin-top: 20px;">
              <div style="position:relative">
                <el-input id="focusInput" type="textarea" class="inputss" rows="3" cols="30" placeholder="请输入内容"
                  v-model="Oralexaminationeditor" name="kqjc" @focus="focusInput" @blur="blurInput">
                </el-input>
                <el-collapse-transition v-if="Oralexaminacontent.length">
                  <div v-if="Oralexaminashows" class="showscs inputss">
                    <ul>
                      <li class="focusLi" v-for="(item, index) in Oralexaminacontent" :key="index"
                        @click="Oralexaminaclick(item)">
                        {{ item.phrase }}
                      </li>
                    </ul>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </div>
        </div>

        <!-- 编辑口腔检查 -->
        <div v-show="hide" class="hidetop">
          <div class="name_content">
            <div class="name_content_tips"></div>
            <div class="upper-toos">
              <div class="upper">
                <div class="tooth">
                  <el-checkbox-group v-model="chooseTypes" @change="change">
                    <el-checkbox-button v-for="(item, index) in range" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
                <div class="tooths">
                  <el-checkbox-group v-model="chooseTypes" @change="change">
                    <el-checkbox-button v-for="(item, index) in range1" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
              </div>

              <div class="Lefts">
                <div class="Lefts_top"></div>
                <div class="Lefts-bottom"></div>
              </div>
              <div class="upper">
                <div class="tooth">
                  <el-checkbox-group v-model="chooseTypes" @change="change">
                    <el-checkbox-button v-for="(item, index) in range2" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
                <div class="tooths">
                  <el-checkbox-group v-model="chooseTypes" @change="change">
                    <el-checkbox-button v-for="(item, index) in range3" :label="item" :key="index">
                      {{ item.localTeeth }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>
              </div>
            </div>

            <div class="icons">
              <i class="el-icon-circle-plus-outline" @click="topchange"></i>
            </div>
          </div>
          <!-- <input
            class="inputss"
            type="text"
            placeholder=""
            v-model="Oralexaminationeditorss"
          /> -->
          <!-- <el-input
               class="inputss"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="Oralexaminationeditorss">
            </el-input> -->
        </div>
        <!-- 治理方案 -->
        <div class="name_content">
          <div class="name_content_tips">治理方案:</div>
          <div style="position:relative">
            <el-input type="textarea" class="inpess" :rows="3" placeholder="请输入内容" v-model="Governancescheme"
              name="zlfa" @focus="focusInput" @blur="blurInput">
            </el-input>
            <el-collapse-transition v-if="Governancontent.length">
              <div class="inpess showscs" v-if="Governanshows">
                <ul>
                  <li class="focusLi" v-for="(item, index) in Governancontent" :key="index"
                    @click="Governanclick(item)">
                    {{ item.phrase }}
                  </li>
                </ul>
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <!-- 医嘱 -->
        <div class="name_content">
          <div class="name_content_tipes">医嘱:</div>
          <div style="position:relative">
            <el-input type="textarea" class="inpess" :rows="3" placeholder="请输入内容" v-model="Medicaladvice"
              @focus="focusInput" @blur="blurInput" name="yz">
            </el-input>
            <el-collapse-transition v-if="Medicontent.length">
              <div v-if="Medishows" class="inpess showscs">
                <ul>
                  <li class="focusLi" v-for="(item, index) in Medicontent" :key="index" @click="Mediclick(item)">
                    {{ item.phrase }}
                  </li>
                </ul>
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <!-- 时间： -->
        <div class="name_contents" style="align-items: center;">
          <div class="name_content_tipes">
            时间：
          </div>
          <el-date-picker v-model="orderTime" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
        <!-- 复诊提醒： -->
        <div class="name_contents" style="align-items:center;height:50px">
          <div class="name_content_tipes" style="display:flex;align-items: center;">
            复诊提醒：
          </div>
          <el-switch v-model="value1" style="margin-right:20px">
          </el-switch>
          <el-date-picker v-show="value1" v-model="appointmentTime" clearable type="datetime" default-time="10:00:00"
            :picker-options="startDatePicker" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>

        <div class="btn_content">
          <div class="btn_content_cancel" @click="cancel">取消</div>
          <div class="btn_content_confirm" @click="confirmbss">确定</div>
          <div class="btn_content_confirm" @click="centerDialogVisible2 = true">收费</div>
        </div>
      </div>
    </el-card>

    <!-- 弹出层 -->
    <el-dialog title="收费" :visible.sync="centerDialogVisible2" width="70%" center>
      <div>
        <div class="name_contents" style="padding:0px;display: block;">
          <el-form :rules="rules" ref="addform" :model="form">
            <!-- 选择收费项目 -->
            <el-form-item label="选择收费项目：" prop="name">
              <div style="display: flex;">
                <div>
                  <!-- 收费 -->
                  <el-select value-key="id" size="medium" v-model="form.value5" placeholder="请选择收费项目" multiple>
                    <el-option v-for="(item) in cities5" :key="item.id" :label="item.moneyNames" :value="item">
                      <span style="float: left;">项目名称:{{ item.moneyNames }}</span>
                      <span style="float: right; color: #8492a6; font-size: 14px;margin-right: 20px;">{{
                        item.moneyAmounts
                        }}￥</span>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <!-- 选择医生 -->
            <!-- <el-form-item label="选择医生:" prop="doctor">
              <el-select clearable size="medium" v-model="form.value3" placeholder="请选择医生">
                <el-option v-for="(item,index) in options" :key="index" :label="item.doctorName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <!-- 收费项目列表 -->
        <div style="width:100%;margin-top: 20px;min-height: 200px;overflow-y: auto;max-height:400px">
          <el-table show-summary :summary-method="getNodeSummaries" size="mini" :data="form.value5"
            style="width: 100%;border:1px solid #ccc">
            <el-table-column label="项目名称">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.moneyNames }}</span>
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.isLock==1" :content="scope.row.isLock==1?'金额已锁定':''" placement="top">
                  <div>{{scope.row.moneyAmounts}}￥</div>
                </el-tooltip>
                <el-input-number @change="changeMoneyCounts(scope.row)" v-if="!scope.row.isLock" size="small"
                  v-model="scope.row.moneyAmounts" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  :min="0" :max="99999999">
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="数量" width="200px">
              <template slot-scope="scope">
                <el-input-number size="small" v-model="scope.row.moneyCounts" :min="1" :max="999">
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisibleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { parseTime } from "../../../utils/index.js";
  export default {
    data() {
      var doctorValidator = (rule, value, callback) => {
        if (this.form.value3) {
          callback()
        } else {
          callback(new Error('请选择医生'))
        }
      }
      var nameValidator = (rule, value, callback) => {
        if (this.form.value5.length) {
          callback()
        } else {
          callback(new Error('请选择项目'))
        }
      }
      return {
        currentprojectList: [],// 项目选中列表
        projectList: [],// 项目筛选列表
        rules: {//验证规则
          doctor: [// 医生
            { required: true, validator: doctorValidator, trigger: 'change' }
          ],
          name: [// 项目
            { required: true, validator: nameValidator, trigger: 'change' }
          ],
        },
        form: {// 表单数据
          value3: '',// 医生ID
          value5: [],// 选中项目
        },
        options2: [],// 医生列表
        value6: '',// 选中医生
        num: 0,// 选中收费总金额
        centerDialogVisible2: false,// 弹出层
        // 选中收费列表
        tableData: [],
        // 收费列表
        cities5: [],
        info: '',//
        startDatePicker: this.startDate(),
        appointmentTime: '',// 复诊时间
        value1: false,//复诊提醒
        chooseType: [] /* 多选 */,
        chooseTypes: [],
        shows: false /* 主诉隐藏内容 */,
        Presenshows: false /* 现病史隐藏内容 */,
        Oralexaminashows: false /* 口腔检查隐藏内容 */,
        Shortcutcontent: [] /* 主诉短语 */,
        Presentcontent: [] /* 现病史快捷短语 */,
        Oralexaminacontent: [] /* 口腔快捷短语 */,
        Governanshows: false /* 治疗方案隐藏内容 */,
        Governancontent: [] /* 治疗方案短语 */,
        Medishows: false,
        Medicontent: [] /* 医嘱短语 */,
        hide: false,
        localTeh: "",
        localTeeth: "",
        showDialog: false,
        OraleDialog: false,
        options: [],
        c: [],
        element: "",
        call: "",
        activeTab: "false",
        isActived: "",
        doctorName: "",
        doctorID: "",
        localTeethKey: "" /* 挂号订单 */,
        caseid: "", // 订单id
        dialogTitle: "编辑",
        CaseTitle: "添加病例",
        Major: "", // 重大疾病
        hypertension: "", // 高血压
        heart: "", // 心脏
        blood: "", // 血液
        choose: "" /* 判断牙齿位置 */,
        Oralexaminationeditor: "" /* 口腔内容 */,
        Oralexaminationeditorss: "",
        Chiefcomplaint: "", //  主诉
        Presentdisease: "", //  现病史
        Diagnosticresults: "", //  诊断结果
        Governancescheme: "", //  治理方案
        Medicaladvice: "", //  医嘱
        range: [
          { value: 1, localTeeth: "8", choose: 0 },
          { value: 2, localTeeth: "7", choose: 0 },
          { value: 3, localTeeth: "6", choose: 0 },
          { value: 4, localTeeth: "5", choose: 0 },
          { value: 5, localTeeth: "4", choose: 0 },
          { value: 6, localTeeth: "3", choose: 0 },
          { value: 7, localTeeth: "2", choose: 0 },
          { value: 8, localTeeth: "1", choose: 0 },
        ],
        range1: [
          { value: 9, localTeeth: "1", choose: 1 },
          { value: 10, localTeeth: "2", choose: 1 },
          { value: 11, localTeeth: "3", choose: 1 },
          { value: 12, localTeeth: "4", choose: 1 },
          { value: 13, localTeeth: "5", choose: 1 },
          { value: 14, localTeeth: "6", choose: 1 },
          { value: 15, localTeeth: "7", choose: 1 },
          { value: 16, localTeeth: "8", choose: 1 },
        ],
        range2: [
          { value: 1, localTeeth: "8", choose: 3 },
          { value: 2, localTeeth: "7", choose: 3 },
          { value: 3, localTeeth: "6", choose: 3 },
          { value: 4, localTeeth: "5", choose: 3 },
          { value: 5, localTeeth: "4", choose: 3 },
          { value: 6, localTeeth: "3", choose: 3 },
          { value: 7, localTeeth: "2", choose: 3 },
          { value: 8, localTeeth: "1", choose: 3 },
        ],
        range3: [
          { value: 9, localTeeth: "1", choose: 4 },
          { value: 10, localTeeth: "2", choose: 4 },
          { value: 11, localTeeth: "3", choose: 4 },
          { value: 12, localTeeth: "4", choose: 4 },
          { value: 13, localTeeth: "5", choose: 4 },
          { value: 14, localTeeth: "6", choose: 4 },
          { value: 15, localTeeth: "7", choose: 4 },
          { value: 16, localTeeth: "8", choose: 4 },
        ],
        nowDate: "", // 当前日期
        nowTime: "", // 当前时间
        nowWeek: "", // 当前星期
        orderTime: "",
      };
    },
    created() {
      this.fetch();
      this.watchDetail()
      if (this.$route.query.Info) {
        this.info = this.$route.query.Info
      }
      if (this.$route.query.type == 'telephone') {
        this.centerDialogVisible2 = true
      }
      console.log(this.info);
      // this.orderTime = this.dateString();
      this.orderTime = parseTime(new Date().getTime(), "{y}-{m}-{d} {h}:{i}:{s}")
      console.log(this.orderTime, 99);
    },
    mounted() {
      // this.currentTime();
      this.getList()
      this.fetch()
      this.muns()
      this.Presentmuns()
      this.Oralexaminamuns()
      this.Governanceschememuns()
      this.Medicaladvicemuns()
    },
    computed: {},
    methods: {
      // 获取项目列表
      watchDetail() {
        this.$http
          .post("/doctor/getProjectById", {
            id: 999,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code = 200) {
              this.projectList = res.data.data
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 改变默认金额
      changeMoneyCounts(item) {
        item.actualMoney = item.moneyAmounts
      },
      // 获取医生列表
      fetch() {
        this.$http.post(`/doctor/selectDoctor`).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.options = res.data.data;
          }
        });
      },
      // 聚焦事件
      focusInput(e) {
        console.log(e.srcElement.name)
        switch (e.srcElement.name) {
          case 'zs':
            this.shows = true;
            break;
          case 'xbs':
            this.Presenshows = true;
            break;
          case 'kqjc':
            this.Oralexaminashows = true;
            break;
          case 'zlfa':
            this.Governanshows = true;
            break;
          case 'yz':
            this.Medishows = true;
            break;
        }
      },
      // 失焦事件
      blurInput(e) {
        console.log(e.srcElement.name)
        switch (e.srcElement.name) {
          case 'zs':
            this.shows = false;
            break;
          case 'xbs':
            this.Presenshows = false;
            break;
          case 'kqjc':
            this.Oralexaminashows = false;
            break;
          case 'zlfa':
            this.Governanshows = false;
            break;
          case 'yz':
            this.Medishows = false;
            break;
        }
      },
      // 合计金额
      getNodeSummaries(param) {
        console.log(param)
        const { columns, data } = param;
        const sums = [];
        let num = 0;
        for (let i = 0; i < data.length; i++) {
          num += Number(data[i].moneyAmounts) * Number(data[i].moneyCounts)
        }
        columns.forEach((column, index) => {
          if (index == columns.length - 2) {
            sums.push('总金额:');
            return;
          } else if (index == columns.length - 1) {
            sums.push(`${num.toFixed(3)}￥`);
            return;
          } {
            sums.push(null);
          }
        })
        this.num = num
        return sums
      },
      // 弹框确定
      centerDialogVisibleSubmit() {
        for (let i = 0; i < this.form.value5.length; i++) {
          if (this.form.value5[i].actualMoney === null) {
            this.form.value5[i].actualMoney = this.form.value5[i].moneyAmounts
          }
        }
        console.log(this.form.value5)
        // return
        this.$refs['addform'].validate((res, object) => {
          if (res) {
            this.$http.post(`/fees/insertOrders`, {
              hsFeesList: this.form.value5,// 选中收费项目
              patientId: this.info.id,// 患者ID
              totalMoney: this.num.toFixed(3),// 总金额
              createDoctor: this.form.value3,// 医生ID
            }, {
              headers: { 'Content-Type': 'application/json' },
            }).then(res => {
              if (res.data.code == 200) {
                if (res.data.msg) {
                  this.$message.success(res.data.msg)
                }
                this.form.value3 = ''
                this.form.value5 = []
                this.centerDialogVisible2 = false
              } else {
                if (res.data.msg) {
                  this.$message.error(res.data.msg)
                }
              }
            }).catch(err => {
              if (res.data.msg) {
                this.$message.error(res.data.msg)
              }
            })
          }
        })

      },
      // 取消弹窗
      dialogVisible2() {
        this.form.value5 = []
        this.form.value3 = ''
        this.centerDialogVisible2 = false
      },
      handleEdit(index, row) {
        console.log(index, row);
      },
      // 选中收费项目删除
      handleDelete(index, row) {
        this.form.value5.splice(index, 1);
      },
      getList() {
        this.$http.post(`/fees/list`, null, {
          params: {
            size: 9,
          }
        }).then(res => {
          console.log(111, res)
          if (res.data.code == 200) {
            this.cities5 = res.data.data.records
            console.log(this.cities5)
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
        })
      },
      // 时间选择限制
      startDate() {
        const ts = this
        return {
          disabledDate(time) {
            // return new Date(ts.appointmentTime).getTime() < time.getTime() || time.getTime() < Date.now() - 8.64e7
            // if (ts.appointmentTime) { // 开始时间小于结束时间且今天之前的日期不可选择
            //   return new Date(ts.appointmentTime).getTime() < time.getTime() || time.getTime() < Date.now() - 8.64e7
            // } else {
            return time.getTime() < Date.now() - (1000 * 3600 * 24) //结束时间不选时，开始时间不能选择今天之前的日期
            // return time.getTime() < Date.now() //结束时间不选时，开始时间不能选择今天之前或之前的日期
            // }
          }
        }
      },
      dateString: function () {
        let year = new Date().toLocaleDateString().substring(0, 4);
        let month = new Date().toLocaleDateString().substring(5, 6);
        let day = new Date().toLocaleDateString().substring(7, 9);
        let hours = new Date().toLocaleTimeString();
        return year + "-" + month + "-" + day + "-" + hours;

        // var _this = this;
        //     let yy = new Date().getFullYear();
        //     let mm = new Date().getMonth() + 1;
        //     let dd = new Date().getDate();
        //     let week = new Date().getDay();
        //     let hh = new Date().getHours();
        //     let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        //     let mf =
        //         new Date().getMinutes() < 10
        //             ? "0" + new Date().getMinutes()
        //             : new Date().getMinutes();
        //     if (week == 1) {
        //         this.nowWeek = "星期一";
        //     } else if (week == 2) {
        //         this.nowWeek = "星期二";
        //     } else if (week == 3) {
        //         this.nowWeek = "星期三";
        //     } else if (week == 4) {
        //         this.nowWeek = "星期四";
        //     } else if (week == 5) {
        //         this.nowWeek = "星期五";
        //     } else if (week == 6) {
        //         this.nowWeek = "星期六";
        //     } else {
        //         this.nowWeek = "星期日";
        //     }
        //     _this.nowTime = hh + ":" + mf + ":" + ss;
        //     _this.nowDate = yy + "-" + mm + "-" + dd;
        //   return  _this.nowTime+"-"+ _this.nowDate;
      },
      // 获取时间
      // currentTime() {
      //         setInterval(this.getDate, 500);
      //     },
      //       getDate: function() {
      //         var _this = this;
      //         let yy = new Date().getFullYear();
      //         let mm = new Date().getMonth() + 1;
      //         let dd = new Date().getDate();
      //         let week = new Date().getDay();
      //         let hh = new Date().getHours();
      //         let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      //         let mf =
      //             new Date().getMinutes() < 10
      //                 ? "0" + new Date().getMinutes()
      //                 : new Date().getMinutes();
      //         if (week == 1) {
      //             this.nowWeek = "星期一";
      //         } else if (week == 2) {
      //             this.nowWeek = "星期二";
      //         } else if (week == 3) {
      //             this.nowWeek = "星期三";
      //         } else if (week == 4) {
      //             this.nowWeek = "星期四";
      //         } else if (week == 5) {
      //             this.nowWeek = "星期五";
      //         } else if (week == 6) {
      //             this.nowWeek = "星期六";
      //         } else {
      //             this.nowWeek = "星期日";
      //         }
      //         _this.nowTime = hh + ":" + mf + ":" + ss;
      //         _this.nowDate = yy + "-" + mm + "-" + dd;
      //     },
      //   // 销毁定时器
      // beforeDestroy: function() {
      //     if (this.getDate) {
      //         console.log("销毁定时器")
      //         clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
      //     }
      // },
      bssss(e) {
        this.Chiefcomplaint = e.target.value.replace(/^[\u4E00-\u9FA5]{1,12}$/);
        console.log(1111);
      },
      Medicaladvicemuns() {
        this.$http
          .post(`/phrase/select`, {
            oneCatalogue: 1,
            twoCatalogue: 5,
          })
          .then((res) => {
            this.Medicontent = res.data.data;
          });
      },
      Mediclick(item) {
        this.Medicaladvice = item.phrase;
        this.Medishows = false;
      },
      Governanceschememuns() {
        this.$http
          .post(`/phrase/select`, {
            oneCatalogue: 1,
            twoCatalogue: 4,
          })
          .then((res) => {
            // console.log(res)
            this.Governancontent = res.data.data;
          });
      },
      Governanclick(item) {
        this.Governancescheme = item.phrase;
        this.Governanshows = false;
      },
      Oralexaminamuns() {
        this.$http
          .post(`/phrase/select`, {
            oneCatalogue: 1,
            twoCatalogue: 3,
          })
          .then((res) => {
            this.Oralexaminacontent = res.data.data;
          });
      },
      Oralexaminaclick(item) {
        this.Oralexaminationeditor = item.phrase;
        this.Oralexaminashows = false;
      },
      Presentmuns() {
        this.$http
          .post(`/phrase/select`, {
            oneCatalogue: 1,
            twoCatalogue: 2,
          })
          .then((res) => {
            this.Presentcontent = res.data.data;
            console.log(res.data.data);
          });
      },
      Presenclick(item) {
        this.Presentdisease = item.phrase;
        // this.Presenshows = false;
      },
      busss(item) {
        console.log(item.phrase);
        this.Chiefcomplaint = item.phrase;
        this.shows = false;
      },
      muns() {
        this.$http
          .post(`/phrase/select`, {
            oneCatalogue: 1,
            twoCatalogue: 1,
          })
          .then((res) => {
            console.log(res)
            this.Shortcutcontent = res.data.data;
          });
      },
      change(e) {
        console.log(e);
        this.localTeh = e;
      },
      handleClick(e) {
        // console.log(e,100);
        this.doctorName = e.label;
        this.doctorID = e.$vnode.key;
      },
      fetch() {
        this.$http.post(`/doctor/selectDoctor`, {
          limit: 10,
          page: 1
        }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.options = res.data.data;
          }
        });
      },
      Oralexconfirm() {
        // console.log(this.choose);
        // console.log(this.toot);
        // console.log(this.localTeethKey);
        // console.log(this.Oralexaminationeditor);
        /* 这边需要数据展示 分开展示  不需要接口描述 */
        // console.log(this.localTeh,9999);
        //  for (let i = 0; i < this.localTeh.length; i++) {
        //   this.element = this.localTeh[i];
        //     this.$set( this.element,'oralCavity', this.Oralexaminationeditor)
        //      console.log(this.element,89);
        //    this.c.push(this.element,)
        // }
        console.log(this.c);
        this.OraleDialog = false;
      },

      topchange() {
        this.hide = true;
        for (let i = 0; i < this.localTeh.length; i++) {
          this.element = this.localTeh[i];
          this.$set(this.element, "oralCavity", this.Oralexaminationeditor);
          console.log(this.c);
          this.c.push(this.element);
        }
        window.sessionStorage.setItem("biss", JSON.stringify(this.c));
        console.log(this.element.oralCavity, 100);
        let bosss = JSON.parse(window.sessionStorage.getItem("biss"));
      },
      // 编辑病例确定
      confirmbss() {
        console.log(this.$route.query.Info)
        if (this.doctorName == "") {
          this.$message.error("请选择医生");
        } else {
          for (let i = 0; i < this.localTeh.length; i++) {
            this.element = this.localTeh[i];
            this.$set(this.element, "oralCavity", this.Oralexaminationeditor);
            this.c.push(this.element);
          }
          if (this.currentprojectList == '') {
            this.$message.error('请选择项目')
            return
          }
          if (this.Oralexaminationeditor == '') {
            this.$message.error("请编辑口腔检查内容");
          } else {
            // console.log(this.c)
            // return
            this.$http
              .post("/order/createOrderAndMedical", {
                key: this.localTeethKey,
                localTeethList2: this.c,
                medical: {
                  tell: this.Chiefcomplaint /* 主诉 */,
                  nowDisease: this.Presentdisease /* 现病史 */,
                  record: this.Major /*  疾病*/,
                  isHighBleed: this.hypertension /*  血压*/,
                  isHeat: this.heart /*  心脏*/,
                  isBleed: this.blood /*  心液*/,
                  diagnosis: this.Diagnosticresults /*  诊断*/,
                  treatmentOptions: this.Governancescheme /*  方案*/,
                  doctorAdvice: this.Medicaladvice /*  医嘱*/,
                  patientId: this.$route.query.Info.id,//
                  appointmentTime: this.appointmentTime,// 复诊时间
                  memberPhone: this.info.phone,// 手机号
                  smsOrPhone: this.value1 ? [0] : [],//复诊提醒
                  patientName: this.$route.query.Info.username,// 患者姓名
                  companyName: this.$route.query.Info.companyName,// 医院名称

                },
                orderInfo: {
                  treatmentName: this.currentprojectList.join(','),// 选中项目
                  patientId: this.$route.query.Info.id,
                  patientName: this.$route.query.Info.username,
                  doctorName: this.doctorName /* 医生名称 */,
                  doctorId: this.doctorID /* 医生id */,
                  orderStatus: "3",
                  category: "1",
                  orderTime: this.orderTime
                },
              })
              .then((res) => {
                console.log(res);
                if (res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.$router.go(-1);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          }
        }
      },
      // 编辑病例取消
      cancel() {
        this.appointmentTime = ''
        this.$router.go(-1);
        console.log(8888);
      },
      // 取消
      cancels() {
        this.OraleDialog = false;
      },
    },
  };
</script>

<style scoped>
  /deep/.el-table {
    margin-top: 0px;
  }

  /deep/#projec .el-checkbox__inner {
    zoom: 170%
  }

  /deep/#projec .el-checkbox {
    margin-bottom: 10px;
  }

  /deep/#projec .el-checkbox-group {
    width: 600px !important;
  }

  /deep/.el-select>.el-input {
    width: 400px;
  }

  /deep/.el-textarea__inner {
    font-size: 15px;
  }

  /deep/.el-textarea__inner {
    max-height: 100px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  /deep/.el-form-item__label {
    width: 120px !important;
    text-align: left;
  }

  /deep/.el-form-item__error {
    left: 130px;
  }

  .name_content {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin-bottom: 10px;
  }

  .name_contents {
    display: flex;
    /* align-items: center; */
    padding: 5px 15px;
    margin-top: 20px;
  }

  .name_content_tip {
    padding-left: 100px;
    padding-right: 46px;
  }

  .name_content_tips {
    width: 100px;
  }

  .name_content_ti {
    padding-left: 100px;
    padding-right: 33px;
  }

  .name_content_tp {
    padding-left: 100px;
    padding-right: 21px;
  }

  .inss {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #eee;
  }

  #focusInput:focus {
    border: 1px solid #bbb;
    outline: none;
  }

  .insss {
    width: 200px;
    height: 30px;
    margin-left: -14px;
    border-radius: 5px;
    border: 1px solid#DCDFE6;
  }

  .focusLi {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px;
  }

  .Casecontent ul {
    padding-left: 10px;
  }

  .box1 {
    width: 80%;
    /* height: 300px; */
    padding-bottom: 20px;
    border: 1px solid#DCDFE6;
  }

  .inpess {
    width: 400px;
    font-size: 14px;
    /* margin-top: 25px; */
    border-radius: 5px;
    overflow: visible;
    word-break: break-all;
  }

  .inputss {
    position: relative;
    width: 400px;
    font-size: 14px;
    /* margin-top: 25px;
    margin-left: 20%; */
    border-radius: 5px;
    word-break: break-all;
  }

  .inses {
    width: 200px;
    height: 40px;
    margin-left: -14px;
    border-radius: 5px;
    border: 1px solid#DCDFE6;
  }

  .showscs {
    z-index: 999;
    position: absolute;
    background-color: #fff;
    margin-top: 3px;
    font-size: 14px;
    height: auto;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .showscs::-webkit-scrollbar {
    width: 0 !important
  }

  .hidetop {
    margin-top: 40px;
  }

  .name_content_tipes {
    width: 100px;
  }

  .btn_content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  .btn_content_confirm {
    margin-left: 80px;
    width: 60px;
    height: 40px;
    background-color: #4b8dfd;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 10%;
  }

  .btn_content_cancel {
    margin-left: 150px;
    background-color: #4b8dfd;
    width: 60px;
    height: 40px;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 10%;
  }

  .Orale {
    top: 10%;
  }

  .Lefts_top {
    width: 600px;
    height: 1px;
    background-color: #dcdfe6;
    margin: auto;
  }

  .upper {
    margin-top: 15px;
    color: #b3b0b7;
    display: flex;
    justify-content: space-around;
  }

  .Lefts-bottom {
    width: 130px;
    height: 2px;
    background-color: #dcdfe6;
    transform: rotate(90deg);
    margin: auto;
  }

  .upper {
    margin-top: 15px;
    color: #b3b0b7;
    display: flex;
    justify-content: space-around;
  }

  .Lefts {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Rights {
    margin-top: 10px;
  }

  .el-checkbox-group {
    width: 400px;
  }

  .tooth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .icons {
    font-size: 25px;
  }

  .tooths {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 25px;
  }

  .toothif {
    margin-top: 10px;
    width: 25px;
    background-color: #4b8dfd;
    margin-left: 12px;
    color: #232323;
    text-align: center;
    border: 1px solid #dddddd;
  }

  .Casecontent {
    margin-top: 20px;
  }

  .radioss {
    margin-left: 4.9375px;
  }

  .radioss_a {
    margin-left: 3.308594px;
  }



  .showses {
    z-index: 999;
    position: absolute;
    background-color: #fff;
    /* width: 700px; */
    border: 1px solid #dddddd;
    /* margin-left: 23%;
    margin-top: -25px; */
    font-size: 14px;
  }

  .Governanscs {
    position: absolute;
    z-index: 999;
    margin-top: 3px;
    background-color: #fff;
    border: 1px solid #dddddd;
    font-size: 14px;
  }

  .bordes {
    border: 1px solid #dddddd;
    padding: 10px;
  }

  .fontsize {
    color: #232323;
    font-size: 16px;
    text-align: center;
  }

  .fontsizes {
    color: #232323;
    font-size: 16px;
    text-align: center;
    padding: 0px 15px;
  }

  .fonsLef {
    display: flex;
    justify-content: space-between;
  }
</style>
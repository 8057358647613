<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>系统首页</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 头部区域 -->
      <el-row :gutter="50" class="p">
        <el-col :span="8">
          <el-card shadow="always" style="padding: 30px 0">
            <div style="display: flex;justify-content: space-around;align-items: center;">
              <div class="p_1">
                <div class='p_2'>{{Info.countDate}}</div>
                <div style="font-size:24px;color:#233333;margin: 10px 0">今日挂号人次</div>
              </div>
              <div>
                <img src="../assets/icon1.png" style="height:108px;width:108px" alt="加载失败">
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="always" style="padding: 30px 0">
            <div style="display: flex;justify-content: space-around;align-items: center;">
              <div class="p_1">
                <div class='p_2'>{{Info.jiuzhenDate}}</div>
                <div style="font-size:24px;color:#233333;margin: 10px 0">今日接诊人次</div>
              </div>
              <div>
                <img src="../assets/icon2.png" style="height:108px;width:108px" alt="加载失败">
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="always" style="padding: 30px 0">
            <div style="display: flex;justify-content: space-around;align-items: center;">
              <div class="p_1">
                <div class='p_2'>{{Info.allCount}}</div>
                <div style="font-size:24px;color:#233333;margin: 10px 0">总挂号人次</div>
              </div>
              <div>
                <img src="../assets/icon3.png" style="height:108px;width:108px" alt="加载失败">
              </div>
            </div>
          </el-card>
        </el-col>

      </el-row>

      <div class="card_main">
        <div class="title">
          <div>今日就诊记录</div>
          <i class="el-icon-refresh-left"></i>
        </div>
        <!-- tabs选项区域 -->
        <el-tabs v-model="activeName" @tab-click="tabsChanges">
          <el-tab-pane label="全部" name="All" class="hello">
            <!-- 列表区域 -->
            <el-table :data='userList' v-loading="loading">
              <el-table-column type="index" width="70" label="序号"></el-table-column>
              <el-table-column label="患者ID" prop="patientId"></el-table-column>
              <el-table-column label="就诊状态" prop="orderStatus">
                <template slot-scope="scope">
                  <span v-if="scope.row.orderStatus==='待就诊'" style="color: #a4a6d4">待就诊</span>
                  <span v-else-if="scope.row.orderStatus==='就诊中'" style="color:#f5de7a">就诊中</span>
                  <span v-else-if="scope.row.orderStatus === '已就诊' " style="color:#80d9c0">已就诊</span>
                  <span v-else style="color: #ec8ea3">取消就诊</span>
                </template>
              </el-table-column>
              <el-table-column label="患者姓名" prop="username"></el-table-column>
              <el-table-column label="性别" prop="gender"></el-table-column>
              <el-table-column label="年龄" prop="age"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
              <el-table-column label="诊别" prop="category"></el-table-column>
              <el-table-column label="预约项目" prop="treatmentName"></el-table-column>
              <el-table-column label="预约医生" prop="doctorName"></el-table-column>
              <el-table-column label="最后更新时间" prop="createTime" width="180"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

          </el-tab-pane>
          <el-tab-pane label="待就诊" name="Waitting">
            <!-- 列表区域 -->
            <el-table :data='userList' v-loading="loading">
              <el-table-column type="index" width="70" label="序号"></el-table-column>
              <el-table-column label="患者ID" prop="patientId"></el-table-column>
              <el-table-column label="就诊状态" prop="orderStatus"></el-table-column>
              <el-table-column label="患者姓名" prop="username"></el-table-column>
              <el-table-column label="性别" prop="gender"></el-table-column>
              <el-table-column label="年龄" prop="age"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
              <el-table-column label="诊别" prop="category"></el-table-column>
              <el-table-column label="预约项目" prop="treatmentName"></el-table-column>
              <el-table-column label="预约医生" prop="doctorName"></el-table-column>
              <el-table-column label="最后更新时间" prop="createTime" width="180"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="已就诊" name="Registered">
            <!-- 列表区域 -->
            <el-table :data='userList' v-loading="loading">
              <el-table-column type="index" width="70" label="序号"></el-table-column>
              <el-table-column label="患者ID" prop="patientId"></el-table-column>
              <el-table-column label="就诊状态" prop="orderStatus"></el-table-column>
              <el-table-column label="患者姓名" prop="username"></el-table-column>
              <el-table-column label="性别" prop="gender"></el-table-column>
              <el-table-column label="年龄" prop="age"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
              <el-table-column label="诊别" prop="category"></el-table-column>
              <el-table-column label="预约项目" prop="treatmentName"></el-table-column>
              <el-table-column label="预约医生" prop="doctorName"></el-table-column>
              <el-table-column label="最后更新时间" prop="createTime" width="180"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="就诊中" name="Registering">
            <!-- 列表区域 -->
            <el-table :data='userList' v-loading="loading">
              <el-table-column type="index" width="70" label="序号"></el-table-column>
              <el-table-column label="患者ID" prop="patientId"></el-table-column>
              <el-table-column label="就诊状态" prop="orderStatus"></el-table-column>
              <el-table-column label="患者姓名" prop="username"></el-table-column>
              <el-table-column label="性别" prop="gender"></el-table-column>
              <el-table-column label="年龄" prop="age"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
              <el-table-column label="诊别" prop="category"></el-table-column>
              <el-table-column label="预约项目" prop="treatmentName"></el-table-column>
              <el-table-column label="预约医生" prop="doctorName"></el-table-column>
              <el-table-column label="最后更新时间" prop="createTime" width="180"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="退号" name="Cancel">
            <!-- 列表区域 -->
            <el-table :data='userList' v-loading="loading">
              <el-table-column type="index" width="70" label="序号"></el-table-column>
              <el-table-column label="患者ID" prop="patientId"></el-table-column>
              <el-table-column label="就诊状态" prop="orderStatus"></el-table-column>
              <el-table-column label="患者姓名" prop="username"></el-table-column>
              <el-table-column label="性别" prop="gender"></el-table-column>
              <el-table-column label="年龄" prop="age"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
              <el-table-column label="诊别" prop="category"></el-table-column>
              <el-table-column label="预约项目" prop="treatmentName"></el-table-column>
              <el-table-column label="预约医生" prop="doctorName"></el-table-column>
              <el-table-column label="最后更新时间" prop="createTime" width="180"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </el-tab-pane>
        </el-tabs>


      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: 'All',
        loading: false,
        userList: [],
        page: 1,
        limit: 10,
        total: 0,
        Info: {},
        activePath: '',
        user: {},
        username: '',
        imgUrl: ""
      }
    },
    created() {
      this.getList()
      this.getRecordAll()
      this.username = this.$route.query.username
      this.imgUrl = this.$route.query.imgUrl
      this.user = JSON.parse(window.localStorage.getItem('userInfo'))
    },
    methods: {
      // 首页获取列表数据
      getList() {
        this.$http.get(`/order/homePageByHospital`, {}).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.Info = res.data.data
            console.log(this.Info)
            // this.user = res.data.data.user
            // window.localStorage.setItem('userInfo', JSON.stringify(this.user))
          } else {
            this.$message.error("请求失败")
          }
        })
      },
      tabsChanges(tab) {
        // console.log(tab.name);
        if (tab.name === 'Waitting') {
          let orderStatus = 1
          this.getRecordAll(orderStatus)
        } else if (tab.name === 'Registered') {
          let orderStatus = 3
          this.getRecordAll(orderStatus)
        } else if (tab.name === 'Registering') {
          let orderStatus = 2
          this.getRecordAll(orderStatus)
          console.log(3)
        } else if (tab.name === 'Cancel') {
          let orderStatus = 4
          this.getRecordAll(orderStatus)
        } else {
          this.getRecordAll()
        }

      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize
        console.log(this.limit)
        this.getRecordAll()
      },
      handleCurrentChange(newPage) {
        this.page = newPage
        console.log(this.page)
        this.getRecordAll()
      },
      getRecordAll(orderStatus) {
        this.loading = true
        this.$http.post(`/order/pageList`, {
          page: this.page,
          limit: this.limit,
          orderStatus: orderStatus ? orderStatus : ''
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.userList = res.data.data
            for (let i = 0; i < this.userList.length; i++) {
              switch (this.userList[i].category) {
                case "0":
                  this.userList[i].category = '快速挂号'
                  break;
                case '1':
                  this.userList[i].category = '初诊'
                  break;
                default:
                  this.userList[i].category = '复诊'
                  break;
              }
              switch (this.userList[i].gender) {
                case "0":
                  this.userList[i].gender = '未知'
                  break;
                case '1':
                  this.userList[i].gender = '男'
                  break;
                default:
                  this.userList[i].gender = '女'
                  break;
              }
              switch (this.userList[i].orderStatus) {
                case "1":
                  this.userList[i].orderStatus = '待就诊'
                  break;
                case '2':
                  this.userList[i].orderStatus = '就诊中'
                  break;
                case '3':
                  this.userList[i].orderStatus = '已就诊'
                  break;
                default:
                  this.userList[i].orderStatus = '取消订单'
                  break;
              }
            }
            this.total = res.data.count

            this.loading = false
          } else {
            this.$message.error("请求失败")
          }
        })
      }
    }
  }
</script>

<style lang='less' scoped>
  .p {
    padding: 0 50px;
    margin: 20px 0 30px 0
  }

  .p_1 {
    display: flex;
    flex-direction: column;
  }

  .p_2 {
    font-size: 50px;
    font-weight: bold;
    color: #233333;
  }

  .card_head {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;

    .card_head_container {
      margin-top: 25px;
      height: 220px;
      width: 440rpx;
      // height: 120px;
      // width: 360px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 0px 18px 0px rgba(3, 3, 3, 0.08);
      border-radius: 6px;
      padding: 0 50px;

      // padding: 30px 40px;
      img {
        height: 108px;
        width: 108px;
        margin-left: 80px;
      }
    }
  }

  .card_main {
    box-shadow: 0px 0px 18px 0px rgba(3, 3, 3, 0.08);
    border-radius: 6px;
    padding: 20px 0 0 0;

    .title {
      font-size: 20px;
      color: #233333;
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px 0;
      padding: 0 50px 0 20px;

    }

  }

  .card_main .el-tabs {
    padding: 0 0 0 15px;

  }

  .card_main .el-tabs__item {
    padding: 0 55px;
    font-size: 18px;
    height: 60px;
  }

  .card_main .el-table {

    .el-table thead {
      color: #B3B3B3;
    }

    .el-table__row {
      color: #233333;
      font-size: 15px;

    }

  }

  .el-pagination {
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>患者叫号</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="display: flex;justify-content: space-between;">
        <el-tabs @tab-click="handleClick" v-if="currentType" v-model="type" type="card" class="items">
          <el-tab-pane v-for="item in options" :key="item.id" :label="item.doctorName" :value="item.name">
            <div style="width:100%;height:100%" slot="label">
              <i> {{item.name}}</i>
              <i class="item">{{item.num}}</i>
            </div style>
          </el-tab-pane>
        </el-tabs>
        <div>
          <el-button class="" type="" @click="repetition()">重复播报</el-button>
          <el-button class="daochu_btn" type="success" @click="removeOut()">叫号</el-button>
        </div>
      </div>
      <!-- <el-divider></el-divider> -->
      <!-- <div style="height:30px">
        <el-button @click="Refresh" class="btn">刷新</el-button>
      </div> -->
      <!-- 列表区域 -->
      <el-table :data="nameList">
        <el-table-column label="患者姓名" prop="patientName"></el-table-column>
        <el-table-column label="性别" prop="gender"></el-table-column>
        <el-table-column label="年龄" prop="age"></el-table-column>
        <el-table-column label="就诊时间" prop="orderTime">
          <template slot-scope="scope">
            <div>
              <div>{{scope.row.orderTime}}</div>
              <div>{{scope.row.orderHour}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="预约医生" prop="doctorName"></el-table-column>
        <el-table-column label="挂号类型">
          <template slot-scope="scope">
            <div>
              <div>
                <el-tag v-if="scope.row.registrationKind!=1" type="" effect="dark">今日挂号</el-tag>
                <el-tag v-if="scope.row.registrationKind==1" type="warning" effect="dark">预约挂号</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="医疗项目">
          <template slot-scope="scope">
            <div>
              {{scope.row.treatmentName || '/'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="就诊状态">
          <template slot-scope="scope">
            <div>
              <el-tag effect="dark" v-if="scope.row.orderStatus==2" type="success">就诊中</el-tag>
              <el-tag effect="dark" v-if="scope.row.orderStatus==1" type="warning">待就诊</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="content" width="200">
          <template slot-scope="scope">
            <el-button @click="clickClearSeeAdoctor" :disabled="scope.row.orderStatus==2?false:true" size="mini"
              class="daochu_btn" type="warning">
              结束就诊</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
  import { json } from 'body-parser';
  import { parseTime } from '../../utils/index.js'
  export default {
    data() {
      return {
        doctname: "",
        nameList: [],
        options: [],
        value4: "",
        call: '',
        type: 0,
        currentName: '',//当前选中医生
      };
    },
    created() {
      this.fetch();
      // this.changeDoc()
    },
    computed: {
      currentType() {
        if (JSON.parse(window.localStorage.getItem('userInfo')).roleId == 0) {
          return false
        } else {
          return true
        }
      }
    },
    mounted() {

    },
    activated() {
      this.fetch();
    },
    methods: {
      // 到店按钮
      isArrived(item) {
        this.$confirm('是否修改患者到店状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        })
      },
      handleClick(item) {
        this.currentName = item ? item.$attrs.value : this.options[0].name;
        this.$http
          .post(`/doctor/getPatinetInfo?doctorName=` + this.currentName, {})
          .then((res) => {
            // console.log(res);
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].gender == "1") {
                res.data.data[i].gender = "男";
              } else if (res.data.data[i].gender == "2") {
                res.data.data[i].gender = "女";
              } else if (res.data.data[i].gender == "0") {
                res.data.data[i].gender = "未知";
              }
            }
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].content === "0") {
                res.data.data[i].content = "叫号";
              } else {
                res.data.data[i].content = "等待叫号";
              }
            }
            this.nameList = res.data.data;
          });
      },
      changeDoc(e) {
        // console.log(e);
        this.$http
          .post(`/doctor/getPatinetInfo?doctorName=` + e, {})
          .then((res) => {
            // console.log(res);
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].gender === "1") {
                res.data.data[i].gender = "男";
              } else if (res.data.data[i].gender === "2") {
                res.data.data[i].gender = "女";
              }
            }
            this.nameList = res.data.data;
          });
      },
      //刷新
      fetch() {
        this.$http.get(`/doctor/getDoctor`).then((res) => {
          if (res.data.code == 200) {
            this.options = res.data.data;
            this.Refresh();
          }
        });
      },
      // 就诊中
      seeAdoctor(type, callback, repetition = 2, curentType = false) {
        let obj = {
          id: type ? this.nameList[1].id : this.nameList[0].id,
          orderHour: type ? this.nameList[1].orderHour : this.nameList[0].orderHour,// 就诊时间段
          orderTime: type ? this.nameList[1].orderTime : this.nameList[0].orderTime,// 就诊日期 
          orderStatus: repetition,// 修改患者状态
          doctorId: type ? this.nameList[1].doctorId : this.nameList[0].doctorId,
          doctorName: type ? this.nameList[1].doctorName : this.nameList[0].doctorName,
          patientName: type ? this.nameList[1].patientName : this.nameList[0].patientName,
          memberPhone: type ? this.nameList[1].memberPhone : this.nameList[0].memberPhone,
          companyId: type ? this.nameList[1].companyId : this.nameList[0].companyId,
          patientId: type ? this.nameList[1].patientId : this.nameList[0].patientId,
          orderTime: parseTime(new Date().getTime(), "{y}-{m}-{d}"),// 当前时间
          category: 5,// 后端需要
        }
        if (curentType) {
          obj.category = '8'
        }
        this.$http
          .post("/order/callNumber", obj)
          .then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              let msg = new window.SpeechSynthesisUtterance()
              msg.text = res.data.data
              msg.lang = "zh-cn"
              window.speechSynthesis.speak(msg)
              if (callback) {
                callback()
              }
            }
          });
      },
      // 结束就诊
      clearSeeAdoctor(callback) {
        this.$http
          .post("/order/finishOrdeer", null, {
            params: {
              id: this.nameList[0].id,
              orderStatus: 3,
            }
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg)
              if (callback) {
                callback()
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      clickClearSeeAdoctor() {
        this.clearSeeAdoctor(this.fetch)
      },
      Refresh() {
        if (this.options.length <= 0) {
          this.nameList = []
          return
        }
        let name = this.currentName ? this.currentName : this.options[0].name
        if (this.options.length <= 1) {
          name = this.options[0].name
          this.type = '0'
        }
        this.$http
          .post(`/doctor/getPatinetInfo?doctorName=` + name, {})
          .then((res) => {
            // console.log(res);
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].gender === "1") {
                res.data.data[i].gender = "男";
              } else if (res.data.data[i].gender === "2") {
                res.data.data[i].gender = "女";
              } else {
                res.data.data[i].gender = "未知";
              }
            }
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].content === "0") {
                res.data.data[i].content = "叫号";
              } else {
                res.data.data[i].content = "等待叫号";
              }
            }
            this.nameList = res.data.data;
          });
      },
      removeOut() {
        if (this.nameList[0].orderStatus == 2) {
          this.$confirm('是否结束当前就诊人，叫号下一位待就诊患者?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.clearSeeAdoctor(() => this.seeAdoctor(true, this.fetch()))
            this.fetch();
          })
        } else {
          this.seeAdoctor(null, this.fetch)
        }
      },
      // 重复播报
      repetition() {
        if (this.nameList.length > 0 && this.nameList[0].orderStatus == 2) {
          this.seeAdoctor(null, null, 2, true)
        }
      },
    },
  };
</script>.

<style scoped lang='less'>
  .daochu_btn {
    color: #ffff;
  }

  .el-row {
    left: 290px;
    top: -40px;
  }

  .el-card {
    position: relative;
  }

  .btn {
    margin-top: 20px;
    position: absolute;
    top: 80px;
    right: 50px;
    display: inline-block;
  }

  .item {
    position: absolute;
    right: 0px;
    top: 0px;
    color: beige;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: red;
    text-align: center;
    line-height: 20px;
  }

  .items {
    position: relative;
  }
</style>
<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医生管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加医生</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding:20px">
        <div class="hospital_name">
          <div style="margin-right:20px"><i style="color:red">*</i>医生名称:</div>
          <el-input style="width:220px" v-model="hospital_name" placeholder="输入医生姓名"></el-input>
        </div>
        <!-- 添加号码 -->
        <div class="edit_hospital_introduce">
          <div style='margin-right:20px'>添加号码:</div>
          <el-col :span="8">
            <el-input placeholder="请输入手机号码" v-model="phone">
            </el-input>
            <div style="color:red;font-size:13px;margin-top: 3px;">*填写手机号后将以短信方式把账号密码发送给医生</div>
          </el-col>
        </div>
        <!-- 所属医院 -->
        <div class="hospital_type_part">
          <div style='margin-right:20px'><i style="color:red">*</i>所属医院:</div>
          <el-col :span="8">
            <el-select v-model="id" placeholder="请选择" @change="chooseHospital(id)">
              <el-option v-for="item in hospital_option" :key="item.id" :label="item.companyName" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </div>
        <!-- 医院头像 -->
        <div class="uploadImg">
          <div><i style="color:red">*</i>医生头像:</div>
          <img v-if="imageUrl" :src="imageUrl">
          <div>
            <el-upload style="border: 1px dashed #d9d9d9;margin-left:20px" class="avatar-uploader"
              action="https://www.idemer.com/web/upload/file" :show-file-list="false" :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload" :on-error="handErr">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <!-- 医生职称 -->
        <div class="edit_hospital_introduce">
          <div style="margin-right:20px">医生职称:</div>
          <el-input style="width:220px" v-model="jobTitle" placeholder="输入医生职称"></el-input>
        </div>
        <!-- 医生权限 -->
        <div class="hospital_type_part">
          <div style='margin-right:20px'>医生权限:</div>
          <el-select v-model="roleId" placeholder="请选择">
            <el-option v-for="item in hospital_type" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- 擅长领域 -->
        <div class="edit_hospital_introduce">
          <div style='margin-right:20px'>擅长领域:</div>
          <!-- <el-col :span="8">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容"
                            v-model="beGoodat">
                        </el-input>
                    </el-col> -->
          <el-checkbox-group v-model="chooseType" @change="change">
            <el-checkbox-button v-for="(item,index) in Types" :label="item" :key="index">{{item.title}}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
        <!-- 医生简介 -->
        <!-- <div class="edit_hospital_introduce">
                    <div style='margin-right:20px'>医生简介:</div>
                    <el-col :span="8">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容"
                            v-model="doctor_introduce">
                        </el-input>
                    </el-col>
                </div> -->
        <!-- 按钮区域 -->
        <div class='btns_1'>
          <el-button @click="confirm" type="primary"
            style="background: #646ee8;border:none;height:35px;width:100px;line-height:0">确定</el-button>
          <el-button type="primary" @click="cancel"
            style="background: none;border: 1px solid #646ee8;color:#646ee8;height:35px;width:100px;line-height:0">
            取消</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  // const cityOptions = ['上海', '北京', '广州', '深圳'];
  export default {
    data() {
      return {
        // checkboxGroup1: ['上海'],
        chooseType: [],
        Types: [],
        imageUrl: '',
        roleId: '',// 医生权限
        id: '',
        jobTitle: '',// 医生职称
        doctor_introduce: '',
        hospital_name: '',
        phone: '',
        // form表单
        Info_1: {
          hospital_name: ''
        },
        hospital_type: [
          {
            value: '0',
            label: '普通医生',
          },
          {
            value: '8',
            label: '子管理员',
          },
        ],
        hospital_option: [],
        beGoodat: '',
        introduce: ''
      }
    },
    created() {
      this.getHosList()
      //获取擅长类型接口
      this.getType()
    },
    methods: {
      change(e) {
        // console.log(e)
        this.chooseType = e
        console.log(this.chooseType)
      },
      getHosList() {
        this.$http.get('/company/getCompany').then(res => {
          if (res.data.code == 200) {
            this.hospital_option = res.data.data
          } else {
            this.$message.error("信息加载失败")
          }

        })
      },
      chooseHospital(id) {
        console.log(id)
      },
      getType() {
        this.$http.get(`/doctor/getProject`).then(res => {
          console.log(res)
          this.Types = res.data.data
        })
      },
      // 添加医生
      confirm() {
        if (this.hospital_name && this.imageUrl && this.id) {
          let str = ''
          for (let i = 0; i < this.chooseType.length; i++) {
            str += this.chooseType[i].title + ','
          }
          let obj = {
            jobTitle: this.jobTitle,// 医生职称
            name: this.hospital_name,// 医生名称
            headPicture: this.imageUrl,//医生头像
            companyId: this.id,// 医院ID
            roleId: this.roleId || '0',//医生权限
            strongpoint: str,// 擅长领域
            // shortintro: this.doctor_introduce,// 医生简介
            username: this.phone ? this.phone : this.hospital_name,
          }
          if (this.phone) {
            obj.phone = this.phone//号码
          }
          this.$http.post('/doctor/insert', obj).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.$router.back(-1)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.warning("请查看信息是否完整")
        }
      },
      cancel() {
        this.$router.back(-1)
      },
      witch_Hospital() {
        console.log(1)
      },
      handleAvatarSuccess(res, file) {
        // console.log(file)
        this.imageUrl = file.response;
        console.log(this.imageUrl)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      handErr(err) {
        this.$message.error("上传图片失败请重试")
      }

    }
  }
</script>

<style lang="less" scoped>
  .hospital_name {
    display: flex;
    align-items: center;
  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin: 0 20px;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    // .inp_address {
    //     // margin-left: 30px;
    // }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    .inp_address {
      margin-left: 30px;
    }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;

  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;

  }

  .btns_1 {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 50px;
    }
  }
</style>
/**
 * page title get from $router {meta: {title: '登录'},}
 * @param pageTitle
 * @return {string}
 */
export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split("?")[1]).replace(/\+/g, " ");
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split("&");
  searchArr.forEach((v) => {
    const index = v.indexOf("=");
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * 删除值为空字符串的属性
 * @param data
 */
export function deleteEmptyString(data) {
  for (let i in data)
    if (typeof data[i] == "string" && data[i].trim() == "") data[i] = undefined;
}
/**
 * 用户id转换
 * @param user
 * @returns {*}
 */
export function userIdFilter(user) {
  return user == null ? "未知" : user.id;
}
/**
 * 用户名转换
 * @param user
 * @returns username
 */
export function usernameFilter(user) {
  return user == null ? "0" : user.username;
}
/**
 * App用户账号转换
 * @param user
 * @returns loginAccount
 */
export function loginAccountFilter(user) {
  return user == null ? "0" : user.loginAccount;
}
/**
 * 用户姓名转换
 * @param user
 * @returns fullName
 */
export function fullNameFilter(user) {
  return user == null ? "未知" : user.fullName;
}

/**
 * 用户昵称转换
 * @param user
 * @returns fullName
 */
export function nikeNameFilter(user) {
  return user == null ? "未知" : user.nikeName;
}

export function getImg(compFile) {
  return compFile == null ? "" : compFile.url;
}

export function convertCityTree(data) {
  let provinces = [],
    map = {},
    cites = [];
  for (let area of data) {
    if (area.pid == 0) {
      area.children = [];
      provinces.push(area);
      map[area.id] = area;
    } else {
      cites.push(area);
    }
  }
  for (let city of cites) {
    let province = map[city.pid];
    if (province) province.children.push(city);
  }
  return provinces;
}

export function convertRegionTree(data) {
  let provinces = [],
    map = {},
    cites = [],
    regions = [];
  for (let area of data) {
    if (area.level == 1) {
      // 省
      area.children = [];
      provinces.push(area);
      map[area.id] = area;
    } else if (area.level == 2) {
      // 市
      area.children = [];
      cites.push(area);
      map[area.id] = area;
    } else {
      regions.push(area);
    }
  }
  for (let city of cites) {
    let province = map[city.pid];
    if (province) province.children.push(city);
  }
  for (let region of regions) {
    let city = map[region.pid];
    if (city) city.children.push(region);
  }
  return provinces;
}

export function excelData(list, filter) {
  return list.map((v) =>
    filter.map((j) => {
      if (typeof j === "string") {
        return v[j];
      } else if (typeof j === "function") {
        return j(v);
      } else {
        return "配置错误，filter只能是字符串or函数";
      }
    })
  );
}

// 工具类
export class Debounce {
  constructor(delay) {
    this.delay = delay ? delay : 500;
    this.timeOut = null;
  }
  debounceEnd() {
    return new Promise((resolve, reject) => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        resolve("success");
      }, this.delay);
    });
  }
}

<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>医院管理</el-breadcrumb-item>
      <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="padding: 20px">
        <div class="hospital_name">
          <div style="margin-right: 20px">医院名称:</div>
          <el-input style="width: 220px" v-model.trim="hospital_name" placeholder="输入医院名称"></el-input>
        </div>
        <!-- 医院头像 -->
        <div class="uploadImg">
          <div>医院头像:</div>
          <img v-if="imageUrl" :src="imageUrl" />
          <div>
            <el-upload style="border: 1px dashed #d9d9d9; margin-left: 20px" class="avatar-uploader" :action="imgurl"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <!-- 医院地址 -->
        <div class="edit_address">
          <div class="edit_address_title">医院地址:</div>
          <!-- <el-cascader
                        placeholder="选择地址"
                        :options="options_1"
                        :v-model="options_1"
                        clearable
                        @change="chooseAddress"
                        >
                    </el-cascader> -->
          <el-col :span="8">
            <div style="display:flex">
              <el-input style="margin-right:20px" class="inp_address" v-model="detailAddress" placeholder="请输入详细地址">
              </el-input>
              <el-button size="mini" type="primary" @click="centerDialogVisible = true">手动定位
              </el-button>
            </div>
          </el-col>
        </div>
        <!-- 医院类型 -->
        <div class="hospital_type_part">
          <div style="margin-right: 20px">医院类型:</div>
          <el-select v-model="value" placeholder="请选择" @change="chooseHospital_type(value)">
            <el-option v-for="item in hospital_type" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- 营业时间 -->
        <div class="hospital_type_part">
          <div style="margin-right: 20px">营业时间:</div>
          <el-time-select placeholder="上班时间" v-model="startTime" :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '24:00'
          }">
          </el-time-select>
          <el-time-select style="margin-left:10px" placeholder="下班时间" v-model="endTime" :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '24:00',
            minTime: startTime
          }">
          </el-time-select>
        </div>
        <!-- 医院简介 -->
        <div class="edit_hospital_introduce">
          <div style="margin-right: 20px">医院简介:</div>
          <el-col :span="8">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容" v-model="introduce">
            </el-input>
          </el-col>
        </div>
        <!-- 医院背景图片 -->
        <div class="uploadImg">
          <div>医院背景图片:</div>
          <div class="headPics" style="position:relative;margin-right: 20px;" v-for="(item,index) in HosInfo.headPics"
            v-if="HosInfo.headPics">
            <img :src="item" style="border-radius: 50%;margin: 0 1.25rem;" />
            <div class="item">
              <i @click="deleteHeadPics(index)" style="color:#fff;font-size:40px;"
                class="el-icon-plus el-icon-delete"></i>
            </div>
          </div>
          <div>
            <el-upload style="border: 1px dashed #d9d9d9; margin-left: 20px" class="avatar-uploader" :action="imgurl"
              :show-file-list="false" :on-success="headPicsSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <!-- 视频展示 -->
        <div style="display: flex; margin-top: 50px">
          <div>视频展示:</div>
          <div style="margin-left: 20px; flex: 0.7" class="Videodisplay">
            <div class="show-imgs-container" @mouseover="isIn = true" @mouseout="isIn = false">
              <!-- <el-carousel
            :interval="cutTime"
            arrow="always"
            :autoplay="autoplay"
            trigger="click"
            :indicator-position="autoplay?'':'none'"
            @change="getIndex">
            <el-carousel-item> -->
              <!--视频播放器 -->
              <!-- <video :ref="'videoPlayer'" :controls="isIn" class="mps">
                    <source :src="videosrc" type="video/mp4">
                    您的浏览器不支持Video标签。
                </video> -->
              <div class="mps">
                <video v-if="videoPath !=''" :src="videoPath" class="avatar video-avatar"
                  controls="controls">您的浏览器不支持视频播放</video>

                <!-- <i v-show="!isPlay" class="play-btn el-icon-video-play bight" @click="playVideo"></i>
                <i v-show="isPlay && isIn" class="play-btn el-icon-video-pause" @click="pauseVideo"></i> -->
              </div>
              <!-- </el-carousel-item> -->
              <!-- </el-carousel> -->
            </div>
            <!-- 上传视频 -->
            <div class="uploadvio">
              <el-form>
                <el-form-item label="" prop="videoUr" v-loading="loading" element-loading-text="视频上传中">
                  <el-upload style="border: 1px dashed #d9d9d9; margin-left: 60px; width: 200px; height: 200px;"
                    class="avatar-uploader" :action="url" :show-file-list="false" :on-progress="handlePreview"
                    :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <!-- <span>上传视频</span> -->
                  </el-upload>
                </el-form-item>
                <el-progress v-if="showPercentage" style="margin-left: 60px;
                margin-top:20px" :percentage="loadpercentage"></el-progress>
              </el-form>
            </div>
          </div>
        </div>
        <!-- 营业执照照片 -->
        <div class="uploadImg">
          <div>营业执照照片:</div>
          <img v-if="HosInfo.businessLicense" :src="HosInfo.businessLicense" />
          <div>
            <el-upload style="border: 1px dashed #d9d9d9; margin-left: 20px" class="avatar-uploader" :action="imgurl"
              :show-file-list="false" :on-success="businessLicenseSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <!-- 医疗机构执业许可证照片 -->
        <div class="uploadImg">
          <div>医疗机构执业许可证照片:</div>
          <img v-if="HosInfo.medicalInstitution" :src="HosInfo.medicalInstitution" />
          <div>
            <el-upload style="border: 1px dashed #d9d9d9; margin-left: 20px" class="avatar-uploader" :action="imgurl"
              :show-file-list="false" :on-success="medicalInstitutionSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <!-- 法人身份证照片 -->
        <div class="uploadImg">
          <div>法人身份证照片:</div>
          <div style="display:flex;">
            <div style="margin-right:100px;display: flex;align-items: center;">
              <img v-if="HosInfo.frontPicture" :src="HosInfo.frontPicture" />
              <div>
                <el-upload style="border: 1px dashed #d9d9d9; margin-left: 20px" class="avatar-uploader"
                  :action="imgurl" :show-file-list="false" :on-success="frontPictureSuccess"
                  :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
            <div style="margin-left:100px;display: flex;align-items: center;">
              <img v-if="HosInfo.reversePicture" :src="HosInfo.reversePicture" />
              <div>
                <el-upload style="border: 1px dashed #d9d9d9; margin-left: 20px" class="avatar-uploader"
                  :action="imgurl" :show-file-list="false" :on-success="reversePictureSuccess"
                  :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
          </div>

        </div>
        <div class="btns">
          <el-button @click="confirm" style="background-color: #686ce8; color: #ffffff">确定</el-button>
          <el-button @click="getback" style="border: 1px solid #686ce8; color: #686ce8">返回</el-button>
        </div>
      </div>
    </el-card>
    <!-- 弹出层 -->
    <div>
      <el-dialog @open="open" title="手动定位" :visible.sync="centerDialogVisible" width="70%" center>
        <div>
          <!-- 输入框 -->
          <div style="display:flex;align-items:center">
            <div style="width:100px"> 地区名称：</div>
            <el-input id="keyword" @input="input" placeholder="请输入地区名称" class="input-with-select" v-model="regionData"
              clearable>
            </el-input>
          </div>
          <div style="width:100%;height:25rem;margin: 20px 0px;">
            <div id="container"></div>
          </div>
          <div style="display:flex;justify-content:space-around">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitMap">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import AMapLoader from '@amap/amap-jsapi-loader';
  import { Debounce } from '../../../utils/index.js'
  export default {
    data() {
      return {
        startTime: '',
        endTime: '',
        showPercentage: false,// 显示进度条
        loadpercentage: 0,// 进度条
        imgurl: 'https://www.idemer.com/web/upload/file',
        // imgurl: 'http://192.168.3.165:8069/upload/file',
        AMap: '',
        de: new Debounce(),
        getLng: '',// 坐标
        getLat: '',
        mapShow: false,// 地图是否渲染
        map: null,// 地图实列
        marker: '',// 标记
        regionData: '',//地区输入框
        centerDialogVisible: false,// 弹出层
        imageUrl: "",// 医院头像网络地址
        detailAddress: "",
        introduce: "",
        hospital_name: "",
        url: 'https://www.idemer.com/web/upload/uploadVideo',
        value: "", //医院类型默认值
        hospital_type: [
          {
            value: "0",
            label: "公立医院",
          },
          {
            value: "1",
            label: "私立医院",
          },
          {
            value: "2",
            label: "综合医院",
          },
        ],
        HosId: 0,
        HosInfo: {},
        preventSumit: false,
        progressFactor: 0.96,
        uploadProgress: 0,
        headers: {},
        fileList: [],
        videoUr: '',
        autoplay: true,//是否自动切换
        isPlay: false,//播放状态
        isIn: false,//鼠标是否位于播放器内
        cutTime: 5000,//轮播时间，单位毫秒
        nowIndex: 0,
        videoPath: "",
        loading: false
      };
    },
    created() {
      this.HosId = this.$route.query.id;
      this.HosInfo = this.$route.query.HosInfo;
      this.videoPath = this.$route.query.videosrc;
      this.getLat = this.$route.query.HosInfo.latitude
      this.getLng = this.$route.query.HosInfo.longitude
      this.startTime = this.HosInfo.operatingStartTime
      this.endTime = this.HosInfo.operatingEndTime
      console.log(this.HosInfo);
      switch (this.HosInfo.types) {
        case "综合医院":
          this.HosInfo.types = "2";
          break;
        case "私立医院":
          this.HosInfo.types = "1";
          break;
        default:
          "公立医院";
          break;
      }
      console.log(this.HosInfo);
      this.hospital_name = this.HosInfo.companyName;
      this.imageUrl = this.HosInfo.headPicture;
      this.detailAddress = this.HosInfo.address;
      this.introduce = this.HosInfo.companyIntroduction;
      this.value = this.HosInfo.types;
    },
    mounted() {
      if (!this.getLat || !this.getLng) {
        this.getLocal(this.detailAddress)
      }
    },
    methods: {
      // 弹框确认
      submitMap() {
        if (!this.regionData) {
          this.$message.error('地区不能为空')
          return
        }
        this.detailAddress = this.regionData
        this.centerDialogVisible = false
      },
      input() {
        // this.de.debounceEnd().then(() => {
        //   this.getLocal(this.regionData)
        //   this.$nextTick(() => {
        //     this.map.setZoomAndCenter(16, [this.getLng, this.getLat])
        //     this.marker.setPosition([this.getLng, this.getLat])
        //   })
        // })
        let that = this
        if (!that.regionData) return
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder = new AMap.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            city: '全国'
          })
          geocoder.getLocation(that.regionData, function (status, result) {
            let { lat, lng } = result.geocodes[0].location
            console.log(lat, lng)
            if (status === 'complete' && result.info === 'OK') {
              // result中对应详细地理坐标信息
              that.getLng = lng
              that.getLat = lat
              that.map.setZoomAndCenter(16, [that.getLng, that.getLat])
              that.marker.setPosition([that.getLng, that.getLat])
            }
          })
        })
      },
      // 查询位置
      getLocal(str) {
        let h = new this.$http.create({
          baseURL: 'https://restapi.amap.com/',
        })
        h.get(`/v3/geocode/geo?key=9e98c67ede6cb4d43fd6b0f4506c9f36&address=${str}`)
          .then(res => {
            this.getLng = res.data.geocodes[0].location.split(',')[0] || ''
            this.getLat = res.data.geocodes[0].location.split(',')[1] || ''
            console.log(this.getLng, this.getLat)
          }).catch(err => {
            console.log(err)
          })
      },
      // 打开弹框
      open() {
        this.regionData = this.detailAddress
        // this.getLocal(this.regionData)
        // return
        //DOM初始化完成进行地图初始化
        if (this.mapShow) return
        this.$nextTick(() => {
          this.initMap();
        })
      },
      // 初始化地图
      initMap() {
        const that = this
        // 加载地图
        AMapLoader.load({
          key: "78f3e62f5e8fb5cf652fbb47bc1fa4fe",             // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          resizeEnable: true,
          plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          AMapUI: {             // 是否加载 AMapUI，缺省不加载
            version: '1.1',   // AMapUI 版本
            plugins: ['overlay/SimpleMarker'],       // 需要加载的 AMapUI ui插件
          },
          Loca: {                // 是否加载 Loca， 缺省不加载
            "version": '2.0'  // Loca 版本
          },
        }).then((AMap) => {
          console.log(AMap)
          this.AMap = AMap
          let local = ''
          if (this.getLat && this.getLng) {
            local = [this.getLng, this.getLat]
          } else {
            local = ''
          }
          // 获取实列
          that.map = new AMap.Map("container", {  //设置地图容器id
            // viewMode: "3D",    //是否为3D地图模式
            zoom: 16,           //初始化地图级别
            center: local, //初始化地图中心点位置
          });
          // 设置标记
          that.marker = new AMap.Marker({
            position: local,
            icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
            offset: new AMap.Pixel(-13, -30)
          });
          that.marker.setMap(that.map);
          // // 搜索
          // var autoOptions = {
          //   city: "北京", //城市，默认全国
          //   input: "keyword"//input的id
          // };
          // let autocomplete = new AMap.Autocomplete(autoOptions);
          // var placeSearch = new AMap.PlaceSearch({
          //   city: '北京',
          //   map: that.map
          // })
          // AMap.event.addListener(autocomplete, "select", function (e) {
          //   //TODO 针对选中的poi实现自己的功能
          //   placeSearch.setCity(e.poi.adcode);
          //   placeSearch.search(e.poi.name);
          // });
          //TODO:事件绑定、结果处理等
          this.event()
          that.mapShow = true
        }).catch(e => {
          console.log(e);
          // console.log(this.map)
          if (!this.map) {
            this.initMap()
          }
        })
      },
      // 绑定地图事件
      event() {
        this.map.on('click', this.showInfoClick);
        this.map.on('dblclick', this.showInfoDbClick);
        this.map.on('mousemove', this.showInfoMove);
      },
      // 解绑地图事件
      eventOff() {
        this.map.off('click', this.showInfoClick);
        this.map.off('dblclick', this.showInfoDbClick);
        this.map.off('mousemove', this.showInfoMove);
      },
      // 点击地图
      showInfoClick(e) {
        // this.$message.success('您在 [ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ] 的位置单击了地图！')
        this.getLng = e.lnglat.getLng()
        this.getLat = e.lnglat.getLat()
        this.marker.setPosition([e.lnglat.getLng(), e.lnglat.getLat()]);
      },
      // 双击地图
      showInfoDbClick(e) {
        // this.$message.success('您在 [ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ] 的位置双击了地图！')
      },
      // 移动鼠标
      showInfoMove(e) {
        // console.log('您移动了您的鼠标')
      },
      //获取走马灯的索引
      getIndex(nowIndex, oldIndex) {
        //用户可能在上个视频未播放完毕就切换
        //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
        let myVideo = this.$refs['videoPlayer' + oldIndex];
        myVideo[0].pause();
        myVideo[0].currentTime = 0;
        this.nowIndex = nowIndex;
        this.listenVideo();
      },
      //监听视频的播放、暂停、播放完毕等事件
      listenVideo() {
        //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
        let myVideo = this.$refs['videoPlayer'];
        console.log(111, myVideo);
        //监听播放
        myVideo[0].addEventListener("play", () => {
          this.autoplay = false;
          this.isPlay = true;
        });
        //监听暂停
        myVideo[0].addEventListener("pause", () => {
          this.autoplay = true;
          this.isPlay = false;
        });
        //监听播放完毕
        myVideo[0].addEventListener("ended", () => {
          //时间归零
          myVideo[0].currentTime = 0;
          this.autoplay = true;
          this.isPlay = false;
        });
      },
      //播放视频
      playVideo() {
        let myVideo = this.$refs['videoPlayer'];
        console.log(myVideo);
        myVideo.play();
      },
      //暂停视频
      pauseVideo() {
        let myVideo = this.$refs['videoPlayer'];
        myVideo.pause();
      },
      //上传视频
      handlePreview(event, file, fileList) {
        this.showPercentage = true
        this.loadpercentage = parseInt(event.percent)
        console.log(event.percent)
      },
      //上传前限制大小
      beforeUploadVideo(file) {
        console.log(file);
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (["video/mp4"].indexOf(file.type) == -1) {
          "video/ogg", "video/flv", "video/avi", "video/wmv", "video/rmvb";
          this.$message.error("请上传正确的视频格式");
          return false;
        }
        if (!isLt20M) {
          this.$message.error("上传视频大小不能超过20MB哦!");
          return false;
        }
        this.isShowUploadVideo = false;
        this.load = true;
        this.loading = true
      },
      //上传成功回调
      handleVideoSuccess(res, file) {
        this.videoUrl = res
        this.videoPath = this.videoUrl
        this.loading = false
        this.showPercentage = false
        this.$message.success('上传视频成功')
        console.log(this.videoPath);
      },
      chooseAddress(e) {
        console.log(e, this.detailAddress);
      },
      chooseHospital_type(value) {
        switch (value) {
          case "0":
            this.value = "0";
            break;
          case "1":
            this.value = "1";
            break;
          default:
            this.value = "2";
            break;
        }
        // console.log(this.value);
      },
      // 医院头像图片上传
      handleAvatarSuccess(res, file) {
        console.log(file);
        this.imageUrl = file.response;
      },
      // 营业执照照片上传
      businessLicenseSuccess(res, file) {
        console.log(file);
        this.HosInfo.businessLicense = file.response;
      },
      // 医疗机构执业许可证照片上传
      medicalInstitutionSuccess(res, file) {
        console.log(file);
        this.HosInfo.medicalInstitution = file.response;
      },      // 医疗机构执业许可证照片上传
      // 法人身份证照片上传正面
      frontPictureSuccess(res, file) {
        console.log(file);
        this.HosInfo.frontPicture = file.response;
      },
      // 法人身份证照片上传反面
      reversePictureSuccess(res, file) {
        console.log(file);
        this.HosInfo.reversePicture = file.response;
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error("上传头像图片只能是 JPG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
      },
      // 医院背景图片上传
      headPicsSuccess(res, file) {
        if (this.HosInfo.headPics.length >= 5) {
          this.$message('医院背景图片最大数量不能超过5张')
          return
        }
        this.HosInfo.headPics.push(file.response)
        console.log(this.HosInfo.headPics.length)
      },
      // 删除医院背景图片
      deleteHeadPics(index) {
        this.HosInfo.headPics.splice(index, 1)
      },
      confirm() {
        let Info = {
          id: this.HosId,
          operatingStartTime: this.startTime,
          operatingEndTime: this.endTime,
          headPicture: this.imageUrl,
          address: this.detailAddress,
          companyIntroduction: this.introduce,
          companyName: this.hospital_name,
          types: this.value,
          hospitalVideo: this.videoPath,
          longitude: this.getLng,// 坐标
          latitude: this.getLat,// 坐标
          businessLicense: this.HosInfo.businessLicense,// 营业执照
          medicalInstitution: this.HosInfo.medicalInstitution,// 医疗机构
          frontPicture: this.HosInfo.frontPicture,// 身份证正面
          reversePicture: this.HosInfo.reversePicture,// 身份证反面
          headPics: this.HosInfo.headPics.join(','),// 医院背景图片
        };
        console.log(Info);
        this.$http.post("/company/updateCompany", Info).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.$router.back();
          } else {
            this.$message.error("编辑失败");
          }
        });
      },
      getback() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style lang="less" scoped>
  .el-select .el-input {
    width: 130px;
  }

  #container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
  }

  /deep/ .amap-icon img {
    width: 35px;
    height: 44px;
  }

  .amap-marker-label {
    border: 0;
    background-color: transparent;
  }


  .headPics .item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #333;
    opacity: 0;
    transition: 0.3s;
  }

  .headPics:hover .item {
    opacity: .7;
  }

  .info {
    position: relative;
    top: 0;
    right: 0;
    min-width: 0;
  }

  .hospital_name {
    display: flex;
    align-items: center;
  }

  .uploadvio {
    display: flex;
    align-items: center;
    margin-top: 50px;

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }

  .uploadImg {
    display: flex;
    align-items: center;
    margin-top: 50px;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin: 0 20px;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }

  .edit_address {
    display: flex;
    margin-top: 50px;
    align-items: center;

    .edit_address_title {
      margin-right: 20px;
    }

    // .inp_address {
    //     // margin-left: 30px;
    // }
  }

  .hospital_type_part {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .edit_hospital_introduce {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;

    >button {
      margin: 0 50px;
    }
  }

  .add-picture {
    display: flex;
    justify-content: center;
    margin-top: -50px;
  }

  .show-imgs-container {
    position: relative;
    width: 400px;
    height: 400px;

    .el-carousel__item {
      border-radius: 10px;

      video {
        position: fixed;
        display: inline-block;
        vertical-align: baseline;
        object-fit: fill; //消除留白
      }

      .play-btn {
        font-size: 60px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mps {
    width: 400px;
    height: 400px;

  }

  .Videodisplay {
    display: flex;
  }

  .video-avatar {
    width: 400px;
    height: 400px;

    .bight {
      width: 100px;
      height: 50px;
      position: absolute;
      top: 80%;
      left: 50%;
    }
  }
</style>
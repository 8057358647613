<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>今日患者</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col :span="7">
          <el-input placeholder="患者姓名,手机号码" v-model="keywords" @click="Search" @keyup.enter.native="handleSearchMember">
            <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="17" class="right">
          <div class="rights">
            <el-button class="daochu_btn" @click="removeOut"><i class="el-icon-upload2">导出</i></el-button>
          </div>
          <!-- <div class="left">
            <el-button @click="Addpatient">新增患者</el-button>
          </div> -->
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <!-- 新增患者 -->
      <el-dialog @close="cancel" v-if="showDialog" :title="dialogTitle" :visible.sync="showDialog" width="600px">
        <div>
          <div class="name_content">
            <div class="name_content_tips"><i style="color:red;">*</i>{{phoneType?'手机号码:':'座机号码:'}}</div>
            <div style="position:relative">
              <input @input="phoneInputChange" style="width:200px" @blur="blur" @focus="focus" class="inss"
                :placeholder="phoneType?'请输入手机号码':'请输入座机号码'" v-model="editPhoneNumber" />
              <!-- 列表弹出层 -->
              <div class="inssList" v-loading="loading2">
                <el-collapse-transition>
                  <div v-show="list">
                    <div @click="currentItem(item)" class="item" v-for="(item,index) in options" :key="index"
                      style="width:100%">
                      <el-tooltip placement="top">
                        <div slot="content">创建时间：{{item.createTime}}<br />姓名：{{item.username}}<br />手机号：{{item.phone}}
                        </div>
                        <div style="display:flex;width:100%">{{item.username}}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
            <el-tooltip placement="top">
              <div slot="content">点击可切换{{phoneType?'座机号码':'手机号码'}}</div>
              <el-button @click="phoneType=!phoneType" style="margin-left:20px" size="mini" circle
                icon="el-icon-refresh" type="info" round>
              </el-button>
            </el-tooltip>
            <div v-show="!phoneType" style="margin-left:20px;color:#ccc">座机格式：0563-13125321</div>
          </div>
          <div class="name_content">
            <div class="name_content_tips">性别:</div>
            <el-radio-group v-model="radio">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </div>
          <div class="name_content">
            <div class="name_content_tips"><i style="color:red;">*</i>姓名:</div>
            <input class="inss" v-model="name" type="text" placeholder="请输入真实姓名" />
          </div>
          <div class="name_content">
            <div class="name_content_tips">{{age?'生日:':'年龄:'}}</div>
            <!-- 生日 -->
            <el-date-picker value-format="yyyy-MM-dd" v-show="age" v-model="Select" type="date" placeholder="选择日期">
            </el-date-picker>
            <!-- 年龄 -->
            <input type="number" oninput="if(value>100)value=100" v-show="!age" style="width:200px" class="inss"
              placeholder="请输入年龄" v-model="ageForm" />
            <el-tooltip placement="top">
              <div slot="content">点击可切换{{age?'年龄':'生日'}}</div>
              <el-button @click="age=!age" style="margin-left:20px" size="mini" circle icon="el-icon-refresh"
                type="info" round>
              </el-button>
            </el-tooltip>
          </div>
          <div class="btn_content">
            <div class="btn_content_cancel" @click="cancel">取消</div>
            <div class="btn_content_confirm" @click="confirm">确定</div>
          </div>
        </div>
      </el-dialog>
      <!-- 列表区域 -->
      <el-table :height="'25rem'" :data="userList" v-loading="loading" @row-click.self="bss">
        <el-table-column type="index" label="序号" width="70"></el-table-column>
        <el-table-column label="患者姓名" prop="username"></el-table-column>
        <el-table-column label="性别" prop="gender"></el-table-column>
        <el-table-column label="年龄" prop="age"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="地址" prop="memberAddres"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <!-- 添加病例 -->
            <el-tooltip content="添加病例" placement="top" :enterable="false">
              <el-button type="warning" size="mini" icon="el-icon-circle-plus" @click.stop="addcase(scope.row)">
              </el-button>
            </el-tooltip>
            <!-- 添加收费单 -->
            <el-tooltip content="添加收费单" placement="top" :enterable="false">
              <el-button style="font-size:15px" type="info" size="mini" @click.stop="addcase2(scope.row)">
                ￥</el-button>
            </el-tooltip>
            <!-- 挂号 -->
            <el-tooltip content="挂号" placement="top" :enterable="false">
              <el-button type="success" size="mini" icon="el-icon-phone" @click.stop="register(scope.row)"></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip content="删除" placement="top" :enterable="false">
              <el-button type="danger" size="mini" icon="el-icon-delete" @click.stop="open(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin: 30px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10,20,100]" :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  import { Debounce } from '../../utils/index'
  import { parseTime } from '../../utils/index.js'
  export default {
    data() {
      return {
        de: new Debounce(500),
        loading2: false,//列表加载状态
        list: '',// 列表开关
        options: [],
        age: true,// 年龄切换开关
        ageForm: '',// 年龄
        phoneType: true,// 手机号码或座机号码
        keywords: "",
        loading: false,
        page: 1,
        limit: 10,
        total: 0,
        userList: [],
        showDialog: false,
        radio: "",
        dialogTitle: "添加患者",
        name: "",
        Select: "",
        timeSpace: "",
        editPhoneNumber: "",
        dianziRecord: [
          {
            timestamp: "2020-01-02",
            docterName: "阿卜杜拉",
            type: "拔牙",
            Info: "注意事项，注意什么事项，注意事项",
            size: "large",
            icon: "el-icon-more",
            color: "#636dec",
          },
          {
            timestamp: "2020-01-02",
            docterName: "阿卜杜拉",
            type: "拔牙",
            Info: "注意事项，注意什么事项，注意事项",
          },
          {
            timestamp: "2020-01-02",
            docterName: "阿卜杜拉",
            type: "拔牙",
            Info: "注意事项，注意什么事项，注意事项",
          },
        ],
        currentPage: 1,
        editVisible: false, //编辑用户对话框
        watchRecord: false, //查看点子病历
        userInfo: {
          name: "",
          gender: "",
          phoneNum: "",
          id: "",
          age: "",
        },
        // 编辑用户表单验证规则
        editRules: {
          name: [
            { required: true, message: "请输入患者姓名", trigger: "blur" },
            { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
          ],
          gender: [
            { required: true, message: "请输入性别", trigger: "blur" },
            { min: 1, max: 1, message: "请输入正确性别", trigger: "blur" },
          ],
          phoneNum: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确手机号码", trigger: "blur" },
          ],
          id: [
            { required: true, message: "请输入患者ID", trigger: "blur" },
            { min: 11, max: 11, message: "长度位11位", trigger: "blur" },
          ],
          age: [
            { required: true, message: "请输入年龄", trigger: "blur" },
            { min: 1, max: 3, message: "长度在 1 到 3 个字符", trigger: "blur" },
          ],
        },
        hospitalId: "",
      };
    },
    created() {
      // this.fetch();
      this.InfoList();
    },
    computed: {
      userAge() {
        if (this.age) {
          return this.Select
        } else {
          let date = parseTime(new Date().getTime(), "{y}")
          date = Number(date) - Number(this.ageForm)
          let str = `${date}-01-01 00:00:00`
          return str
        }
      }
    },
    methods: {
      // 弹框提示
      open(item) {
        this.$confirm('此操作将永久删除该患者, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          this.del(item)
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
      // 点击选中
      currentItem(item) {
        this.editPhoneNumber = item.phone
      },
      // 模糊搜索
      phoneInputChange() {
        if (this.editPhoneNumber && this.editPhoneNumber.length > 2) {
          this.loading2 = true
          this.de.debounceEnd().then(res => {
            this.$http.post(`user/selectByPhone`, {}, {
              params: {
                phone: this.editPhoneNumber// 手机号
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 200) {
                this.options = res.data.data
                this.loading2 = false
              } else {
                if (res.data.msg) {
                  this.$message.error(res.data.msg)
                }
              }
            })
          })
        }
      },
      // 聚焦
      focus() {
        this.list = true
      },
      // 失焦
      blur() {
        this.list = false
      },
      handleSearchMember() {
        this.$http
          .post(`/member/selectAll`, {
            username: this.keywords,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              if (res.data.data.length === 0) {
                this.$message.warning("暂无匹配结果");
              } else {
                this.total = res.data.count;
                this.userList = res.data.data;
              }
            }
          });
      },
      bss(e) {
        console.log(e);
        this.$router.push({
          path: "/guanli/usersMangeEdit",
          query: {
            Info: e,
          },
        });
      },
      cancel() {
        this.showDialog = false;
        this.editPhoneNumber = ''
        this.Select = ''
        this.name = ''
        this.radio = ''
        this.phoneType = true
        this.ageType = true
        this.ageForm = ''
        this.options = []
      },
      //新增患者
      confirm() {
        if (
          this.editPhoneNumber &&
          this.name !== ""
        ) {
          let rule = this.phoneType ?
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/ : /^((\d{3,4}\-)|)\d{7,8}(|([-\u8f6c]{1}\d{1,5}))$/
          console.log(rule.test(this.editPhoneNumber))
          if (rule.test(this.editPhoneNumber)) {
            let obj = {
              phone: this.editPhoneNumber,
              username: this.name,
            }
            if (this.userAge) {
              obj.birth = this.userAge
            }
            if (this.radio) {
              obj.gender = this.radio
            }
            this.$http
              .post("/member/addPatient", obj)
              .then((res) => {
                if (res.data.code === 200) {
                  this.$message.success("添加患者成功");
                  this.InfoList();
                  this.showDialog = false;
                  this.ageForm = ''
                  this.radio = ''
                  this.editPhoneNumber = ''
                  this.name = ''
                } else {
                  this.$message.error(res.data.msg)
                }
              });
          } else {
            this.$message.error(this.phoneType ? "手机号码格式不正确" : "座机号码格式不正确");
          }
        } else {
          this.$message.error("提交信息不完善");
        }
      },
      //添加患者
      Addpatient() {
        this.showDialog = true;
      },
      // 列表数据
      InfoList() {
        this.loading = true;
        this.$http
          .post("/member/todayPatient", null, {
            params: {
              current: this.page,
              size: this.limit,
            }
          })
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res);
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].gender === "1") {
                  res.data.data[i].gender = "男";
                } else if (res.data.data[i].gender === "2") {
                  res.data.data[i].gender = "女";
                } else {
                  res.data.data[i].gender = "未知";
                }
              }
              this.total = res.data.count;
              this.userList = res.data.data;
              this.loading = false;
            }
          });
      },
      Search() {
        this.$http
          .post(`/member/selectAll`, {
            username: this.keywords,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              if (res.data.data.length === 0) {
                this.$message.warning("暂无匹配结果");
              } else {
                this.total = res.data.count;
                this.userList = res.data.data;
              }
            }
          });
        // if (this.keywords.length == 11) {
        //   this.$http
        //     .post(`/member/selectAll`, {
        //       phone: this.keywords,
        //     })
        //     .then((res) => {
        //       console.log(res);
        //       if (res.data.code == 200) {
        //         if (res.data.data.length == 0) {
        //           this.$message.warning("暂无匹配结果");
        //         } else {
        //           this.total = res.data.count;
        //           this.userList = res.data.data;
        //         }
        //       }
        //     });
        // } else {
        //   this.$http
        //     .post(`/member/selectAll`, {
        //       username: this.keywords,
        //     })
        //     .then((res) => {
        //       console.log(res);
        //       if (res.data.code == 200) {
        //         if (res.data.data.length === 0) {
        //           this.$message.warning("暂无匹配结果");
        //         } else {
        //           this.total = res.data.count;
        //           this.userList = res.data.data;
        //         }
        //       }
        //     });
        // }
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize;
        console.log(this.limit);
        this.$nextTick(() => {
          this.InfoList();
        })
      },
      handleCurrentChange(newPage) {
        this.page = newPage;
        console.log(this.page);
        this.InfoList();
      },
      edit(e) {
        console.log(e)
        this.$router.push({
          path: "/guanli/usersMangeEdit",
          query: {
            Info: e,
          },
        });
      },
      addcase(e) {
        this.$router.push({
          path: "/guanli/Addcase",
          query: {
            Info: e
          },
        });
      },
      addcase2(e) {
        this.$router.push({
          path: "/guanli/Addcase",
          query: {
            Info: e, type: 'telephone'
          },
        });
      },
      register(e) {
        this.$router.push({
          path: "/guanli/UserManageRegister",
          query: {
            Info: e,
          },
        });
      },
      removeOut() {
        this.$http
          .post(`/member/exportExcel`, { page: this.page, limit: this.limit, }, { responseType: "blob", })
          .then((res) => {
            console.log(res);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.data]));
            link.target = "_blank";
            //文件名和格式
            link.download = "列表.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      },
      // 删除所选列表
      delList(id) {
        console.log(id);
        this.$http.delete(`/member/branchDelete/${id}`).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.InfoList();
          } else {
            this.$message.error("删除失败");
          }
        });
      },
      // 删除接口
      del(info) {
        console.log(info);
        this.delList(info.id);
      },
      // // 编辑牙齿内容
      // Oralexconfirm() {
      //   this.$http
      //     .post("/medical/addMedicalTeeth/", {
      //       choose: this.choose,
      //       localTeeth: this.toot,
      //       orderId: this.caseid,
      //       oralCavity: this.Oralexaminationeditor,
      //     })
      //     .then((res) => {
      //       console.log(res);
      //     });
      //   this.OraleDialog = false;
      // },
      // // 下半口
      // UpperLower(item) {
      //   console.log(item.text, 123);
      //   this.choose = 1;
      //   this.toot = item.text;
      //   this.isActived = item;
      //   this.OraleDialog = true;
      // },
      // // 上半口
      // Upperlip(item) {
      //   this.choose = 0;
      //   this.isActived = item;
      //   this.toot = item.text;
      //   this.OraleDialog = true;
      // },
      // // 上口腔
      // topchange(item) {
      //   this.choose = 3;
      //   this.isActived = item;
      //   this.toot = item.text;
      //   this.OraleDialog = true;
      // },
      // // 下口腔
      // bottomchange(item) {
      //   console.log(item.text, 123);
      //   this.isActived = item;
      //   this.choose = 4;
      //   this.toot = item.text;
      //   this.OraleDialog = true;
      // },
      // // 编辑病例确定
      // confirm() {
      //   let patientId = window.sessionStorage.getItem("patientId");
      //   let doctorId = window.localStorage.getItem("doctorId");
      //   console.log(patientId, doctorId);
      //   this.$http
      //     .post("/medical/addMedical/", {
      //       orderId: this.caseid,
      //       patientId: patientId,
      //       doctorId: doctorId,
      //       tell: this.Chiefcomplaint /* 主诉 */,
      //       nowDisease: this.Presentdisease /* 现病史 */,
      //       record: this.Major /*  疾病*/,
      //       isHighBleed: this.hypertension /*  血压*/,
      //       isHeat: this.heart /*  心脏*/,
      //       isBleed: this.blood /*  心液*/,
      //       diagnosis: this.Diagnosticresults /*  诊断*/,
      //       treatmentOptions: this.Governancescheme /*  方案*/,
      //       doctorAdvice: this.Medicaladvice /*  医嘱*/,
      //     })
      //     .then((res) => {
      //       console.log(res);
      //       this.$message.success("添加病例成功");
      //     });
      //   this.$router.go(0);
      //   this.CaseDialog = false;
      // },
      // // 编辑病例取消
      // cancel() {
      //   this.$http
      //     .post("/medical/deleteMedicalTeeth/", {
      //       orderId: this.caseid,
      //     })
      //     .then((res) => {
      //       console.log(res);
      //     });
      //   this.CaseDialog = false;
      // },
      // // 添加病例
      // Addcase() {
      //   if (this.caseid == "") {
      //     this.$message.success("已经有电子病例");
      //   } else {
      //     this.$http
      //       .get(`/medical/getMedicalByOrderId/${this.caseid}`)
      //       .then((res) => {
      //         console.log(res.data.data);
      //         if (res.data.data.length == 0) {
      //           this.CaseDialog = true;
      //         } else {
      //           this.$message.success("已经添加电子病例");
      //         }
      //       });
      //   }
      // },
      // // 关闭弹框
      // closeDialog(flag) {
      //   this.showDialog = false;
      // },
      // // 编辑确定
      // buttn() {
      //   // console.log(this.prin,123);
      //   if (this.prin == "") {
      //     this.$message.success("请编辑内容");
      //   } else {
      //     this.$http
      //       .post("/medical/insertMdeicalNote/", {
      //         id: this.medicalId,
      //         note: this.prin,
      //       })
      //       .then((res) => {
      //         this.$router.go(0);
      //       });
      //   }

      //   this.showDialog = false;
      // },
      // print(event) {
      //   this.textarea = event.data;
      //   console.log(this.textarea);
      // },
      // // 编辑内容展示
      // edit(index) {
      //   this.medicalId = this.Electronics[index].id;
      //   console.log(this.medicalId);
      //   this.showDialog = true;
      //   this.SingleElectronics = this.Electronics[index];
      //   (this.createTime = this.SingleElectronics.createTime),
      //     (this.category = this.SingleElectronics.category);
      //   this.doctorName = this.SingleElectronics.doctorName;
      //   this.tell = this.SingleElectronics.tell;
      //   this.nowDisease = this.SingleElectronics.nowDisease;
      //   this.isBleed = this.SingleElectronics.isBleed;
      //   this.isHeat = this.SingleElectronics.isHeat;
      //   this.isHighBleed = this.SingleElectronics.isHighBleed;
      //   this.record = this.SingleElectronics.record;
      //   this.diagnosis = this.SingleElectronics.diagnosis;
      //   this.treatmentOptions = this.SingleElectronics.treatmentOptions;
      //   this.doctorAdvice = this.SingleElectronics.doctorAdvice;
      //   this.doctorAdvice = this.SingleElectronics.doctorAdvice;
      //   this.notes = this.SingleElectronics.note;
      // },
      // // 详情
      // Putaway(index) {
      //   this.whatMeet = "2";
      // },
      // // 删除
      // dele(index) {
      //   this.medicalId = this.Electronics[index].id;
      //   this.$http
      //     .post("/medical/deleteMedicalById", {
      //       id: this.medicalId,
      //     })
      //     .then((res) => {
      //       this.$router.go(0);
      //     });
      // },
      // Electronicrequest() {
      //   let patientId = window.sessionStorage.getItem("patientId");
      //   this.$http
      //     .post("/order/getOrderList/", {
      //       patientId: patientId,
      //     })
      //     .then((res) => {
      //       console.log(res.data.data);
      //       this.dianziRecord = res.data.data;
      //       for (let i = 0; i < res.data.data.length; i++) {
      //         if (res.data.data[i].orderStatus === "1") {
      //           res.data.data[i].orderStatus = "待就诊";
      //         }
      //         if (res.data.data[i].orderStatus === "2") {
      //           res.data.data[i].orderStatus = "就诊中";
      //         }
      //         if (res.data.data[i].orderStatus === "3") {
      //           res.data.data[i].orderStatus = "已就诊";
      //         }
      //         if (res.data.data[i].orderStatus === "4") {
      //           res.data.data[i].orderStatus = "取消订单";
      //         }
      //       }
      //     });
      // },
      // Electronivisit() {
      //   let patientId = window.sessionStorage.getItem("patientId");
      //   console.log(patientId);
      //   this.$http
      //     .post("/medical/selectPatientMedical/", {
      //       patientId: patientId,
      //     })
      //     .then((res) => {
      //       this.Electronics = res.data.data;
      //       if (res.data.data == "") {
      //         this.$message.success("暂时没有电子病例");
      //       }
      //       for (let i = 0; i < res.data.data.length; i++) {
      //         for (let i = 0; i < res.data.data.length; i++) { }
      //         if (res.data.data[i].category === "2") {
      //           res.data.data[i].category = "复诊";
      //         } else {
      //           res.data.data[i].category = "初诊";
      //         }
      //       }
      //     });
      // },
      // changeTabs(tab) {
      //   // console.log(tab)
      //   if (tab.name === "second") {
      //     console.log("挂号记录");
      //     this.showDtail = false;
      //   } else if (tab.name === "third") {
      //     console.log("就诊记录");
      //     this.showDtail = false;
      //     this.cc2 = true;
      //   } else if (tab.name === "Electronic") {
      //     console.log("电子病例");
      //     this.showDtail = false;
      //   } else {
      //     this.showDtail = false;
      //     this.cc2 = true;
      //   }
      // },
      // onChange(val) {
      //   console.log(val);
      //   window.localStorage.setItem("doctorId", val);
      //   let doctorId = window.localStorage.getItem("doctorId");
      //   console.log(doctorId);
      //   this.$http
      //     .post("/doctor/getPatinetInfoByDoctorId", {
      //       doctorId: doctorId,
      //     })
      //     .then((res) => {
      //       this.Patientlists = res.data;
      //       console.log(this.Patientlists);
      //     });
      // },
      // onChange1(val, e) {
      //   let patientId = val;
      //   this.caseid = e;
      //   console.log(this.caseid, 123);
      //   window.sessionStorage.setItem("patientId", patientId);
      //   this.$http.get(`/member/selectMemberById/${patientId}`).then((res) => {
      //     console.log(res.data.data);
      //     if (res.data.data.gender == "1") {
      //       this.gender = "男";
      //     } else if (res.data.data.gender == "0") {
      //       this.gender = "未知";
      //     } else {
      //       this.gender = "女";
      //     }
      //     (this.username = res.data.data.username),
      //       (this.ID = res.data.data.memberId),
      //       (this.age = res.data.data.age),
      //       (this.telPhone = res.data.data.phone);
      //   });
      //   this.Electronicrequest();
      //   this.Electronivisit();
      // },
      // maslisg() {
      //   console.log();
      // },
      // fetch() {
      //   this.$http.get(`/doctor/getDoctor`).then((res) => {
      //     console.log(res);
      //     if (res.data.code == 200) {
      //       this.lists = res.data.data;
      //     }
      //   });
      // },
    },
  };
</script>.

<style scoped>
  .inssList {
    width: 100%;
    max-height: 200px;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    color: #666;
    font-size: 16px;
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .inssList::-webkit-scrollbar {
    width: 0 !important
  }

  .item {
    padding-left: 10px;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
  }

  .item:hover {
    background-color: #f5f7fa;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .daochu_btn {
    border: 1px solid #666ee8;
    color: #666ee8;
    margin-right: 40px;
  }

  .name_content {
    /* padding: 0 0 0 30px; */
    display: flex;
    align-items: center;
    padding-top: 20px;
    /* margin-top: 35px; */
  }

  .name_content_tips {
    /* // color: #232323; */
    /* // font-size: 26px; */
    /* // margin-right: 50px; */
    /* // font-weight: bold; */
    margin-right: 20px;
  }

  .inss {
    border-bottom: 1px solid #dddddd;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    outline: none;
  }

  .btn_content {
    display: flex;
    margin-top: 25px;
  }

  .btn_content_confirm {
    margin-left: 80px;
    width: 60px;
    height: 40px;
    background-color: #666ee8;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 20%;
  }

  .btn_content_cancel {
    margin-left: 150px;
    background-color: #666ee8;
    width: 60px;
    height: 40px;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 20%;
  }

  /**/
  /* .daochu_btn {
    color: #ffff;
  }

  .el-row {
    left: 290px;
    top: -40px;
  }

  .el-card {
    position: relative;
  }

  .btn {
    margin-top: 20px;
    position: absolute;
    top: 80px;
    right: 50px;
    display: inline-block;
  }

  .selc {
    padding-left: 50px;
  }

  .colss {
    margin-left: 250px;
  }

  .record {
    display: flex;
    align-items: center;
  }

  .case {
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 1200px;
    width: 80px;
    height: 40px;
    background-color: #666ee8;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
  }

  .operation {
    display: flex;
    margin-left: 500px;
    justify-content: space-between;
  }

  .operation>div {
    margin-left: 50px;
  }

  .cradlist {
    height: 200px;
  }

  .dialogcomponent {
    height: 450px;
  }

  .dialogcomponent>li {
    list-style: none;
  }

  .forminput {
    height: 50px;
    width: 100%;
  }

  .btnss {
    margin-top: 20px;
    position: absolute;
    left: 50%;
  }

  .name_content {
    display: flex;
    align-items: center;
    padding: 5px 15px;
  }

  .name_content_tip {
    padding-left: 77px;
    padding-right: 46px;
  }

  .name_content_tips {
    margin-right: 20px;
  }

  .name_content_ti {
    padding-left: 77px;
    padding-right: 33px;
  }

  .name_content_tp {
    padding-left: 77px;
    padding-right: 21px;
  }

  .inputss {
    border: none;
    outline: medium;
  }

  .name_content_tipes {
    margin-left: 30.15px;
    margin-right: 20px;
  } */

  /* .inss {
    width: 200px;
  }

  .inss>>>.el-input__inner {
    height: 40px;
  }

  .insss>>>.el-input__inner {
    height: 40px;
  }

  .insss {
    width: 200px;
    height: 40px;
    margin-left: -14px;
  }

  .inses {
    width: 200px;
    height: 40px;
    margin-left: -14px;
    border-radius: 5px;
    border: 1px solid#DCDFE6;
  }

  .btn_content {
    display: flex;
    margin-top: 25px;
  }

  .btn_content_confirm {
    margin-left: 80px;
    width: 60px;
    height: 40px;
    background-color: #4b8dfd;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 10%;
  }

  .btn_content_cancel {
    margin-left: 150px;
    background-color: #4b8dfd;
    width: 60px;
    height: 40px;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 10%;
  }

  .Orale {
    top: 10%;
  }

  .upper-toos {
    width: 300px;
  }

  .upper {
    margin-top: 10px;
    margin-left: 135px;
    color: #b3b0b7;
  }

  .Lefts {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: #b3b0b7;
    margin-left: 19px;
  }

  .Rights {
    margin-top: 10px;
  }

  .tooth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .tooths {
    margin-top: 10px;
    width: 25px;
    background-color: #ffffff;
    margin-left: 12px;
    color: #232323;
    text-align: center;
    border: 1px solid #dddddd;
  }

  .toothif {
    margin-top: 10px;
    width: 25px;
    background-color: #4b8dfd;
    margin-left: 12px;
    color: #232323;
    text-align: center;
    border: 1px solid #dddddd;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  } */
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/Login.vue";
import Home from "@/components/Home.vue";
import Welcome from "@/components/Welcome.vue";
// import Frist from '@/components/Frist/Frist.vue'
import UsersMange from "@/components/Frist/UsersMange.vue";
import UserManageEdit from "@/components/Frist/UserManageEdit/UserManageEdit.vue";
import UserManageRegister from "@/components/Frist/UserManageRegister/UserManageRegister.vue";
import DataMange from "@/components/Frist/DataMange.vue";
import DataMangeEdit from "@/components/Frist/DataMangeEdit/DataMangeEdit.vue";
import RegisterTongJ from "@/components/Frist/RegisterTongJ";
import TextMange from "@/components/Frist/TextMange.vue";
import gongwenEdit from "@/components/Frist/gongwenEdit/gongwenEdit.vue";
import gongwenDetailEdit from "@/components/Frist/gongwenEdit/gongwenDetailEdit.vue";
import Huanzhejiaohoa from "@/components/Frist/Huanzhejiaohoa.vue";
import jinrihuangzhe from "@/components/Frist/jinrihuangzhe.vue";
import Addcase from "@/components/Frist/Addcase/Addcase.vue";
import gongwenAdd from "@/components/Frist/gongwenEdit/gongwenadd.vue";
import SystemMange from "@/components/Frist/SystemMange.vue";
import SystemAddUser from "@/components/Frist/SystemAddUser/SystemAddUser.vue";
import DoctorManage from "@/components/Frist/DoctorManage.vue";
import Chargemanagement from "@/components/Frist/Chargemanagement/Chargemanagement.vue";
import phrase from "@/components/Frist/phrase/phrase.vue";
import phraseAdd from "@/components/Frist/phrase/phraseAdd.vue";
import doctorManageDetail from "@/components/Frist/doctorManageDetail/doctorManageDetail.vue";
import doctorManageAdduser from "@/components/Frist/doctorManageDetail/doctorManageAdduser.vue";
import doctorManageEdit from "@/components/Frist/doctorManageDetail/doctorManageEdit.vue";
import yuyueSet from "@/components/Frist/yuyue/yuyueSet.vue";
import yuyueAdd from "@/components/Frist/yuyue/yuyueAdd.vue";
import Rizhi from "@/components/Frist/Rizhi.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: "history",
  routes: [
    { path: "/", redirect: "/login" },
    {
      path: "/login",
      component: Login,
      meta: {
        title: "牙如意",
      },
    },
    {
      path: "/home",
      component: () => import("../components/Home.vue"),
      redirect: "/welcome",
      // 首页子路由区域
      children: [
        {
          path: "/welcome",
          component: Welcome,
          meta: {
            title: "首页",
            keepAlive: false,
          },
        },
        // {
        //   path: '/shouye',
        //   component: Frist
        // },
        {
          path: "/guanli",
          component: UsersMange,
          meta: {
            title: "患者管理",
          },
        },
        {
          path: "/guanli/usersMangeEdit",
          component: UserManageEdit,
          meta: {
            title: "编辑",
          },
        },
        {
          path: "/guanli/UserManageRegister",
          component: UserManageRegister,
          meta: {
            title: "挂号",
          },
        },
        {
          path: "/guanli/Addcase",
          component: Addcase,
          meta: {
            title: "添加病例",
          },
        },
        {
          path: "/Huanzhejiaohoa",
          component: Huanzhejiaohoa,
          meta: {
            title: "患者叫号",
            keepAlive: true,
          },
        },
        {
          path: "/jinrihuangzhe",
          component: jinrihuangzhe,
          meta: {
            title: "今日患者",
          },
        },
        {
          path: "/yuYueLieBiao",
          component: () => import("../components/Frist/yuYueLieBiao/index.vue"),
          meta: {
            title: "预约列表",
          },
        },
        {
          path: "/shuju",
          component: DataMange,
          meta: {
            title: "患者统计",
          },
        },
        {
          path: "/shuju/edit",
          component: DataMangeEdit,
        },
        {
          path: "/guahaotongji",
          component: RegisterTongJ,
          meta: {
            title: "挂号统计",
          },
        },
        {
          path: "/gongwen",
          component: TextMange,
          meta: {
            title: "医院详情",
          },
        },
        {
          path: "/gongwen/edit",
          component: gongwenEdit,
          meta: {
            title: "详情",
          },
        },
        {
          path: "/gongwen/detail/edit",
          component: gongwenDetailEdit,
          meta: {
            title: "编辑",
          },
        },
        {
          path: "/gongwen/add",
          component: gongwenAdd,
        },
        {
          path: "/doctorManage",
          component: DoctorManage,
          meta: {
            title: "医生管理",
          },
        },
        {
          path: "/Chargemanagement",
          component: Chargemanagement,
          meta: {
            title: "收费管理",
          },
        },
        {
          path: "/proOrder",
          component: () => import("../components/Frist/proOrder/index.vue"),
          meta: {
            title: "商品订单",
          },
        },
        {
          path: "/phrase",
          component: phrase,
          meta: {
            title: "快捷短语",
          },
        },
        {
          path: "/finance",
          component: () => import("../components/Frist/finance/index.vue"),
          meta: {
            title: "财务管理",
          },
        },
        {
          path: "/phraseAdd",
          component: phraseAdd,
          meta: {
            title: "添加快捷短语",
          },
        },
        {
          path: "/yuyueset",
          component: yuyueSet,
          meta: {
            title: "预约设置",
          },
        },
        {
          path: "/yuyueadd",
          component: yuyueAdd,
          meta: {
            title: "添加预约",
          },
        },
        {
          path: "/doctorManage/detail",
          component: doctorManageDetail,
          meta: {
            title: "详情",
          },
        },
        {
          path: "/commodity",
          component: () => import("../components/Frist/commoditySet"),
          meta: {
            title: "商品设置",
          },
        },
        {
          path: "/huodonglist",
          component: () => import("../components/Frist/activityList/index.vue"),
          meta: {
            title: "活动列表",
          },
        },
        {
          path: "/doctorManage/detail/edit",
          component: doctorManageEdit,
          meta: {
            title: "详情",
          },
        },
        {
          path: "/doctorManage/addUser",
          component: doctorManageAdduser,
          meta: {
            title: "添加",
          },
        },
        {
          path: "/xitong",
          component: SystemMange,
          meta: {
            title: "账号管理",
          },
        },
        {
          path: "/xitong/add",
          component: SystemAddUser,
          meta: {
            title: "添加",
          },
        },
        {
          path: "/rizhi",
          component: Rizhi,
          meta: {
            title: "日志",
          },
        },
        {
          path: "/chongzhi",
          component: () => import("../components/Frist/recharge/index.vue"),
          meta: {
            title: "充值",
          },
        },
      ],
    },
    {
      path: "/chongzhi2",
      component: () => import("../components/Home.vue"),
      meta: {
        title: "充值",
      },
    },
  ],
});

//添加路由守卫进行Token验证
router.beforeEach((to, from, next) => {
  // to表示将要访问那个路径
  // from表示从那个路由开始
  // next表示将要跳转到哪个路径
  if (to.path === "/login") return next();
  // 获取token
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr) return next("/login");
  next();
});

export default router;

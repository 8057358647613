<template>
    <div>
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据统计</el-breadcrumb-item>
            <el-breadcrumb-item>挂号统计</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!-- 分割线 -->
            <!-- <el-divider></el-divider> -->
            <div class="top">
                <div class="cascader_part">
                    <div class="time" style="width: 50px">时间</div>
                    <el-select v-model="value" placeholder="请选择" @change="chooseTime">
                        <el-option
                            v-for="item in optionsT"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div  style="margin-left:50px;display:flex;align-items: center;">
                    <div style='width:50px;' >医院</div>
                    <el-select v-model="value_1" placeholder="请选择" @change="chooseDoctor">
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div  style="margin-left:50px;display:flex;align-items: center;">
                    <el-button icon="el-icon-search" style="background-color: #666de8;color:#FFFFFF" @click="search">查询</el-button>
                    
                </div>
                <!-- <div class="daochu">
                    <el-button type="primary" icon="el-icon-upload2">导出</el-button>
                </div> -->
                
            </div>
            <!-- 分割线 -->
            <el-divider></el-divider>
            <!-- echarts图形区域 -->
            <div id="main" style="width: 100%;height:400px;"></div>
        </el-card>
    </div>
</template>

<script> 
// 导入echarts
import echarts from 'echarts'
export default {
    data() {
        return {
            value: [], //级联选择器
            value_1: [],
            optionsT: [
                {
                    value: '0',
                    label: '按月份'
                },
                {
                    value: '1',
                    label: '按季度'
                },
                {
                    value: '2',
                    label: '按年份'
                }
            ],
            options: [
                {
                    value: '安徽维乐口腔医院',
                    label: '安徽维乐口腔医院'
                },
                {
                    value: '安徽老兵伤痕口腔医院',
                    label: '安徽老兵伤痕口腔医院'
                },
                {
                    value: '安徽行窃预兆口腔医院',
                    label: '安徽行窃预兆口腔医院'
                },
                {
                    value: '安徽丛刃口腔医院',
                    label: '安徽丛刃口腔医院'
                }
            ],
            month:"",
            Hos: ""

        }
    },
    created() {
        this.getInfo()
        this.getEchartsInfo()
    },
    methods: {
        chooseTime(e) {
            console.log(e)
            this.month = e
            // if(e) {
            //     this.$http.post(`/order/dateCount`,{
            //         date: e
            //     }).then(res => {
            //         console.log(res)
            //     })
            // }
        },
        chooseDoctor(e) {
            console.log(e)
            this.Hos = e
            // if(e) {
            //     this.$http.post(`/order/dateCount`).then(res => {
            //         console.log(res)
            //     })
            // }
        },
        search() {
            if(this.month && this.Hos != "") {
                this.$http.post(`/order/dateCount`,{
                    date: this.month,
                    companyId: this.Hos
                }).then(res => {
                    console.log(res)
                    if(res.data.code == 200) {
                        this.getEcharts(res.data.data.name,res.data.data.value)
                    }
                })
            }else {
                this.$message.warning("查询条件不完整")
            }
        },
        //初始化图标
        getEchartsInfo() {
            this.$http.post(`/order/dateCount`,{    
            }).then(res => {
                console.log(res)
                if(res.data.code == 200) {
                    this.getEcharts(res.data.data.name,res.data.data.value)
                }else {
                    this.$message.warning("数据加载异常")
                }
            })
        },
        // 初始化加载
        getInfo() {
            this.$http.get(`/company/getCompany`,{

            }).then((res) => {
                console.log(res)
                if(res.data.code == 200) {
                    this.options = res.data.data
                }
            })
        },
        getEcharts(e,v) {
            let month = e
            let num = v
            console.log(month)
            console.log(num)
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('main'));
            // this.getInfo()
            // 指定图表的配置项和数据
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                xAxis: {
                    type: 'category',
                    data: month,
                    axisTick: {
                        alignWithLabel: true
                    }
                    
                },
                yAxis: {
                    // name: '人数',
                    type: 'value',
                    // 配置网格线区域
                    splitLine: {
                        lineStyle: {
                        type: "dashed",  //三种样式模式
                        width: 1,
                        },
                        show: true, //控制网格线是否存在
                    },
                    axisLine:{
                        show:false
                    },
                     axisTick:{
                        show:false
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#a0aec3',
                            fontSize : 15
                            // color:function(data){
                            //     if(data <= 50) {
                            //         return 'red'
                            //     } else {
                            //         return 'blue'
                            //     }
                            // }
                        }
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                series: [{
                    name: '人次',
                    type: 'bar',
                    barWidth: '70',
                    data: num,
                    itemStyle: {
                        color: '#1890ff',
                    }
                    
                }],
            };
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        }
    },
    mounted() {
        // this.getInfo()
        // this.getEcharts() 
        
    }
}
</script>

<style lang="less" scoped>
.top {
  display: flex;  
  align-items: center;
  font-size: 15px;
  color: #233333;
  font-weight: 600;
  .cascader_part {
    display: flex;  
    align-items: center;
    // .time {
    //     // margin-right: 20px;
    // }
  }
  .daochu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
  }
  
}

</style>


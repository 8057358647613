import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    plugins: [createPersistedState()],
    pathName: localStorage.pathName,
    currDbSource: "19",
    currJobData: {},
    loginType: false, // 是否过期
    DbSource: [],
    userInfo: "", // 用户信息
  },
  mutations: {
    getUserInfo(state, params) {
      state.userInfo =
        params || JSON.parse(window.localStorage.getItem("userInfo"));
      console.log("加载userinfo");
    },
    getUserData(state) {
      axios
        .post(`/user/getuser`)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            window.localStorage.setItem(
              "userInfo",
              JSON.stringify(res.data.data)
            );
            state.userInfo = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 保存当前菜单栏的路径
    savePath(state, pathName) {
      state.pathName = pathName;
      localStorage.pathName = pathName;
    },
    // 保存当前点击的数据源
    saveCurrDbSource(state, currDbSource) {
      state.currDbSource = currDbSource;
    },
    // 保存当前点击的元数据
    saveCurrJobData(state, currJobData) {
      state.currJobData = currJobData;
    },
    // 保存所有数据源
    saveDbSource(state, DbSource) {
      state.DbSource = DbSource;
    },
  },
});

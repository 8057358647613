<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns">
        <el-button type="primary" icon="el-icon-plus" class="btn_1" @click="addList">添加</el-button>
      </div>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- table表格区域 -->
      <el-table v-loading="loading" ref="multipleTable" :data="userInfo" tooltip-effect="dark" style="width: 100%">

        <el-table-column label="医院名称" prop="companyName">
        </el-table-column>
        <el-table-column prop="phone" label="电话" width="120">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="primary" icon="el-icon-edit" size="mini">编辑</el-button> -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="delSingel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        userInfo: [

        ],
        multipleSelection: [], //当前选中的项
        page: 1,
        limit: 10,
        total: 0,
        loading: false
      }
    },
    created() {
      this.getList()
    },
    methods: {
      // 列表数据
      getList() {
        this.loading = true
        this.$http.post(`/user/getUser`, {
          page: this.page,
          limit: this.limit
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.userInfo = res.data.data
            this.total = res.data.count
            this.loading = false
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 添加列表信息
      addList() {
        this.$router.push({
          path: '/xitong/add',
        })
      },
      chooseType(val) {
        // console.log(val)
        this.multipleSelection = val
        console.log(this.multipleSelection)
      },
      // 删除选中的当前项目
      delete_info() {
        this.$message.warning("不支持多选删除")
        // if(this.multipleSelection) {
        //     console.log(this.multipleSelection)
        //     let arr = this.multipleSelection.map((v) => {
        //         console.log(v.hospitalId)
        //         let arr = []
        //         arr.push(v.hospitalId)
        //         return arr
        //     })
        //     // console.log(arr)
        //     for(let i = 0; i<arr.length;i++) {
        //         console.log(arr[i])
        //         // 将数组转为字符串
        //         arr[i]=arr[i].join('.')
        //     }
        //     arr=arr.map(Number)
        //     arr=arr.toString()
        //     console.log(arr)
        //     this.delLotList(arr)
        // }else {
        //     this.$message.error('当前无选中选项')
        // }
      },
      // 批量删除
      delLotList(arr) {
        this.$http.get('/api/backstage/account_manage/delete_battle', {
          params: {
            hospitalId: arr
          }
        }).then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            this.$message.success(res.data.message)
            this.getList()
          }
        })
      },
      delSingel(e) {
        console.log(e)
        this.$http.delete(`/user/delete/${e}`).then(res => {
          if (res.data.code == 200) {
            console.log(res)
            this.$message.success(res.data.msg)
            this.getList()
          }
        })
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize
        this.getList()
      },
      handleCurrentChange(newPage) {
        this.page = newPage
        this.getList()
      }
    }
  }
</script>

<style scoped lang='less'>
  .btns {
    display: flex;

    .btn_1 {
      background-color: #666de8;
      border: none;
    }

    .btn_2 {
      background-color: #f14866;
      border: none;
    }
  }
</style>